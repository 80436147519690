import { all } from 'redux-saga/effects'
import authSaga from './auth.saga'
import appSaga from './app.saga'
import layersSaga from './layers.saga'
import waypointsSaga from './waypoints.saga'
import snapshotsSaga from './snapshots.saga'
import userSaga from './user.saga'
import supportSaga from './support.saga'
import depthSaga from './depth.saga'

function* rootSaga() {
  try {
    yield all([
      ...waypointsSaga,
      ...authSaga,
      ...appSaga,
      ...layersSaga,
      ...snapshotsSaga,
      ...userSaga,
      ...supportSaga,
      ...depthSaga,
    ])
  } catch (e) {
    console.error(e)
    throw e
  }
}

export default rootSaga
