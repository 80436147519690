import { call, put, apply, takeLatest, select } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { post } from '../../helpers/fetch'

import * as types from '../types/depth.types'
import * as layersActions from '../actions/layers.actions'
import * as actions from '../actions/depth.actions'
import * as loaderActions from '../actions/loader.actions'

export function* getDepthLayerData({ payload: { indexOfActiveTimestamp } }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Fetching waypoints')), 2000)
  const {
    layersStore: { timestamps, layersList },
    waypointsStore: { waypoints },
    depthStore: { directions }
  } = yield select()
  const layers = layersList.filter(({ depth, active, loading }) => depth && ( active || loading ))

  try {
    if (!layers || !layers.length) { throw new Error('No layers data') }

    for (let i = 0; i < layers.length; i++) {
      const { active, name, loading, selected } = layers[i]
      if (selected && active && !loading) { yield put(layersActions.showLayerLoading(name)) }
    }

    let points = waypoints.map(({ latitude, longitude }) => ({ latitude, longitude }))
    if (points.length === 1 && directions && Object.keys(directions).length) {
      const latitude = points[0].latitude
      const longitude = points[0].longitude
      const { left, right } = directions
      points = [
        { latitude: latitude + left.latDiff, longitude: longitude + left.lonDiff },
        ...points,
        { latitude: latitude + right.latDiff, longitude: longitude + right.lonDiff },
      ]
    }

    const response = yield call(post, '/layers-alt/depth-points', {
      timestamp: timestamps[indexOfActiveTimestamp],
      layers: layers.map(({ name }) => name),
      points,
    })
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)

    if (hasError) {
      throw new Error(json.message.includes('Unauthorized') ? 'Unauthorized' : json.message)
    }

    for (let i = 0; i < layers.length; i++) {
      const { active, name } = layers[i]
      if (!active) {
        yield put(layersActions.switchesLayerActive(name))
      }
    }

    yield put(actions.getDepthLayerDataSuccess(json))
  } catch (e) {
    yield put(actions.getDepthLayerDataError(e.message))
  } finally {
    for (let i = 0; i < layers.length; i++) {
      yield put(layersActions.hideLayerLoading(layers[i].name))
    }
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export default [
  takeLatest(types.GET_DEPTH_LAYER_DATA, getDepthLayerData),
]
