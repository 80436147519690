import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { mapIcons } from '../../constants/images'
import { generateCanvasBlob, uniqueId } from '../../helpers/lib'

import * as snapshotsActions from '../../store/actions/snapshots.actions'
import * as loaderActions from '../../store/actions/loader.actions'
import * as waypointsActions from '../../store/actions/waypoints.actions'

class UserActionsList extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    snapshots: PropTypes.array.isRequired,
    waypoints: PropTypes.array.isRequired,
    uploadSnapshot: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    createWaypoints: PropTypes.func.isRequired,
  }

  handleSaveSnapshot = async () => {
    const { showLoader, uploadSnapshot } = this.props
    showLoader('Saving snapshot')
    const snapshot = await generateCanvasBlob()
    const snapshotName = uniqueId()
    uploadSnapshot(snapshot, snapshotName)
  }

  render() {
    const { createWaypoints, waypoints } = this.props
    return (
      <div className="actions-list">
        <img
          src={mapIcons.saveWaypoints}
          alt="#"
          className="actions-list-item actions-list-item--hide"
          onClick={() => waypoints && waypoints.length ? createWaypoints() : false}
        />
        <img
          src={mapIcons.doNewSnapshot}
          alt="#"
          className="actions-list-item"
          onClick={() => this.handleSaveSnapshot()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  userStore: { user },
  snapshotsStore: { snapshots },
  waypointsStore: { waypoints }
}) => ({ user, snapshots, waypoints: waypoints.filter(({ hide = false }) => !hide) })

const mapDispatchToProps = dispatch => ({
  uploadSnapshot: (image, name) => dispatch(snapshotsActions.uploadSnapshotRequested(image, name)),
  showLoader: message => dispatch(loaderActions.showLoader(message)),
  createWaypoints: () => dispatch(waypointsActions.createWaypoints()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserActionsList)
