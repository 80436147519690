export const deg2rad = (deg) => (deg / 180) * Math.PI
export const rad2deg = (ang) => ang / (Math.PI / 180.0)
export const isMobile = () => (/android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i).test(navigator.userAgent)
export const mercY = (lat) => Math.log(Math.tan(lat / 2 + Math.PI / 4))
export const isValue = (x) => x !== null && x !== undefined

/**
 * http://en.wikipedia.org/wiki/Modulo_operation.
 * @returns {Number} возвращает остаток от этажного деления, то есть этаж (а / п). Полезно для последовательного модуля отрицательных чисел.
 */
export const floorMod = (a, n) => a - n * Math.floor(a / n)
