import React from 'react'
import PropTypes from 'prop-types'
import FormSelect from '../../Forms/FormSelect/FormSelect'
import { profileSettings } from '../../../constants/options'

const ProfileSettings = ({ handlers, settings }) => {
  const { setFieldValue, setFieldTouched } = handlers
  return (
    <div className="profile-settings">
      <div className="sub-title">Settings</div>
      {profileSettings.map(({ setting, label, values }) => {
        const value = settings[setting] ? settings[setting].label : values[0].label
        const selectValues = values.map(({ value, label }) => ({ value, label }))
        return (
          <FormSelect
            key={setting}
            label={label}
            name={setting}
            options={selectValues}
            value={value}
            handleChange={setFieldValue}
            handleBlur={setFieldTouched}
          />
        )
      })}
    </div>
  )
}

ProfileSettings.propTypes = {
  settings: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  handlers: PropTypes.object.isRequired,
}

export default ProfileSettings
