/**
 * Fetching user info
 */

export const GET_USER_INFO_REQUESTED = 'get_user_info_requested'
export const GET_USER_INFO_SUCCEEDED = 'get_user_info_succeeded'
export const GET_USER_INFO_FAILED = 'get_user_info_failed'

/**
 * User info change
 */

export const PROFILE_INFO_CHANGE_REQUESTED = 'profile_info_change_requested'
export const PROFILE_INFO_CHANGE_SUCCEEDED = 'profile_info_change_succeeded'
export const PROFILE_INFO_CHANGE_FAILED = 'profile_info_change_failed'
