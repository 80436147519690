import React from 'react'
import PropTypes from 'prop-types'
import { mapIcons } from '../../constants/images'
import { calculateLeft } from '../../helpers/depthViewUtils'

const DepthViewWaypoints = ({ waypoints }) => {
  const leftList = calculateLeft(waypoints)
  return waypoints && waypoints.length ? waypoints.map(({ latitude, longitude }, index) => (
    <div
      key={latitude + longitude}
      className="depth-view__icon"
      style={{ left: leftList[index] + '%' }}
    >
      <img src={mapIcons.waypoints[index]} />
    </div>
  )) : null
}

DepthViewWaypoints.propTypes = {
  waypoints: PropTypes.array.isRequired
}


export default DepthViewWaypoints
