export const statuses = {
  active: 'active',
  disabled: 'disabled'
}

/**
 * 'default' for fish layer (checked, that we selected nothing)
 */
export const types = {
  default: 'default',
  gradient: 'gradient',
  vector: 'uv-vector',
  fish: 'fish',
  contour: 'contour'
}
