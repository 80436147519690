// Helper function to calculate the hypotenuse
export const hypotenuse = (x, y) => Math.sqrt(x * x + y * y)

export const randomRgba = () => {
  const o = Math.round, r = Math.random, s = 255
  return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + 1 + ')'
}

export function drawBezierCurve(context, points, fill = false, tension = 0.25) {
  context.beginPath()
  const length = points.length

  if (length < 2) {
    return
  }

  // If we have only two points, we can only draw a straight line
  if (length === 2) {
    context.moveTo(points[0].x, points[0].y)
    context.lineTo(points[1].x, points[1].y)
    context.stroke()

    return
  }

  /**
   * For each interior point, we need to calculate the tangent and
   * pick two points on it that'll serve as control points for curves to and from the point.
   *
   * cp - control prev point
   * cn - control next point
   */
  const controlPoints = []
  for (let i = 0; i < length; i++) { controlPoints.push({}) }
  for (let i = 1; i < length - 1; i++) {
    const currentPoint = points[i]
    const prevPoint = points[i - 1]
    const nextPoint = points[i + 1]

    /* Calculate the normalized tangent slope vector (dx,dy) */
    const rdx = nextPoint.x - prevPoint.x
    const rdy = nextPoint.y - prevPoint.y

    /* Distance between previous and next points */
    const rd = hypotenuse(rdx, rdy)

    /* Normalized delta-x and delta-y (so the total distance is 1) */
    const dx = rdx / rd
    const dy = rdy / rd

    /* Calculate distances to previous and next points, so we know how far out to put the control points on the tangents  */
    const distancePrev = hypotenuse(currentPoint.x - prevPoint.x, currentPoint.y - prevPoint.y)
    const distanceNext = hypotenuse(currentPoint.x - nextPoint.x, currentPoint.y - nextPoint.y)

    /* Calculate control points */
    controlPoints[i] = {
      cp: [
        currentPoint.x - dx * distancePrev * tension,
        currentPoint.y - dy * distancePrev * tension
      ], // previous control point
      cn: [
        currentPoint.x + dx * distanceNext * tension,
        currentPoint.y + dy * distanceNext * tension
      ] // next control point
    }
  }

  /* For the end points, we only need to calculate one control point */
  controlPoints[0] = {
    cn: [
      (points[0].x + controlPoints[1].cp[0]) / 2,
      (points[0].y + controlPoints[1].cp[1]) / 2
    ]
  }
  controlPoints[length - 1] = {
    cp: [
      (points[length - 1].x + controlPoints[length - 2].cn[0]) / 2,
      (points[length - 1].y + controlPoints[length - 2].cn[1]) / 2
    ]
  }

  /* Draw method */
  context.moveTo(points[0].x, points[0].y)

  for (let i = 1; i < length; i++) {
    const point = points[i]
    const controlPoint = controlPoints[i]
    const controlPrevPoint = controlPoints[i - 1]

    context.bezierCurveTo(
      controlPrevPoint.cn[0], controlPrevPoint.cn[1],
      controlPoint.cp[0], controlPoint.cp[1],
      point.x, point.y
    )
  }

  if (!fill) {
    context.stroke()
  } else {
    context.fill()
  }
}
