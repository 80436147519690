import React, { Component } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { LayersContext } from '../../../constants/layersContext'
import { mapIcons } from '../../../constants/images'
import { types } from '../../../constants/layers'

class SelectFishLayer extends Component {
  state = { selectedLayer: null }
  static contextType = LayersContext
  static propTypes = {
    fishLayers: PropTypes.array.isRequired,
    switchesLayerInActive: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fishLayers } = this.props
    const defaultLayer = fishLayers.find(({ is_default }) => is_default)
    this.setState({ ...this.state, selectedLayer: defaultLayer ? defaultLayer : fishLayers[0] })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedLayer } = this.state
    const { loading, active, name, type } = selectedLayer
    const { fishLayers, switchesLayerInActive } = this.props
    if (selectedLayer && prevState.selectedLayer && prevState.selectedLayer.value !== selectedLayer.value) {
      const { showLayer } = this.context
      if (!loading && !active && type === types.fish) {
        showLayer(name)
      }
      if (type !== types.fish && prevState.selectedLayer) {
        switchesLayerInActive(prevState.selectedLayer.name)
      }
    }
    const fishLayerIsupdated = fishLayers.find(({ loading, name, active }) => name === selectedLayer.name && (loading !== selectedLayer.loading || active !== selectedLayer.active))
    if (selectedLayer && fishLayerIsupdated) {
      this.setState({ ...this.state, selectedLayer: fishLayers.find(({ name }) => name === selectedLayer.name) })
    }
  }

  onIconClick() {
    const { fishLayers } = this.props
    const { selectedLayer: { active } } = this.state
    if (active) { this.setState({ ...this.state, selectedLayer: fishLayers[0] }) }
  }

  handleChange = (selectedLayer) => {
    const { selectedLayer: stateSelectedLayer } = this.state
    const { fishLayers } = this.props
    if (stateSelectedLayer.name === selectedLayer.name) {
      this.setState({ ...this.state, selectedLayer: fishLayers[0] })
    } else {
      this.setState({ ...this.state, selectedLayer })
    }
  }

  render() {
    const { fishLayers } = this.props
    const { selectedLayer } = this.state
    if (!selectedLayer || !fishLayers.length || fishLayers.length === 1) {
      return null
    }

    return (
      <div className="layers-list-item select-fish-layer">
        <div
          onClick={() => this.onIconClick()}
          className={`
            layers-list-item__image layers-list-item__image--fish
            ${selectedLayer.loading && 'layers-list-item__image--loading'}
            ${selectedLayer.active && 'layers-list-item__image--active'}
          `}
        >
          <img id="fish-image" src={mapIcons.layers.fishIcon} />
        </div>
        <Select
          className="select-fish-layer__select-box"
          classNamePrefix="select-fish-layer"
          value={selectedLayer}
          onChange={this.handleChange}
          options={fishLayers}
        />
      </div>
    )
  }
}

export default SelectFishLayer
