import { DomUtil } from 'leaflet'
import PropTypes from 'prop-types'
import { MapLayer, withLeaflet } from 'react-leaflet'
import { safelyRemoveLayer, createCanvsaLayer, animateZoom, getLayerData } from '../../helpers/layersUtils'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'
import { deg2rad, rad2deg, isMobile, mercY, isValue, floorMod } from '../../helpers/windyUtils'

class VectorMapOverlay extends MapLayer {
  /* Data for drawing */
  _data = {}
  _zoom = DEFAULT_VIEWPORT.zoom

  /* Settings for layer */
  _velocityScale = 0.005 // Scale of wind speed (with a random value, at which it looks good)
  _maxParticleAge = 100 // The maximum number of frames that a particle draws before regeneration
  _particleLineWidth = 2 // The width of the line drawn particles
  _particleMultiplier = 1 / 1000 // Scalar number of particles (random value, which looks good)
  _particleReduction = 0.75 // Reduce the number of particles to such an extent that it will be normal for mobile devices
  _fps = 40 // Milliseconds per frame (FPS)

  static propTypes = {
    vectorMapLayer: PropTypes.object.isRequired,
    layerName: PropTypes.string.isRequired,
    indexOfActiveTimestamp: PropTypes.number.isRequired,
  }

  componentDidMount() {
    this.initComponent()
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    const { layerName, indexOfActiveTimestamp } = this.props
    if (prevProps.layerName !== layerName) {
      this.initComponent()
    } else if (prevProps.indexOfActiveTimestamp !== indexOfActiveTimestamp) {
      this.initialize()
      this.reset()
    }
  }

  componentWillUnmount() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    safelyRemoveLayer(leafletMap, this._el)
  }

  initComponent() {
    if (!this.leafletElement || !this._el) {
      const {el, _CanvasLayer} = createCanvsaLayer(this, 'wind-layer')
      this._el = el
      this.leafletElement = new _CanvasLayer()
    }

    this.initialize()

    this.attachEvents()
    this.reset()
  }

  initialize() {
    const { vectorMapLayer, layerName } = this.props
    const { bounds } = vectorMapLayer
    const currLayer = getLayerData(this._zoom, vectorMapLayer)

    if (layerName === 'currents') {
      this._velocityScale = 0.02
    } else {
      this._velocityScale = 0.002
    }

    const [ [ la1, lo1 ], [ la2, lo2 ] ] = bounds
    this._data = { ...currLayer, la1, lo1, la2, lo2 }
  }

  /**
   * Adds events for calling reDraw method
   */
  attachEvents() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.on('moveend', () => this.reset())
    leafletMap.on('zoomanim', (event) => animateZoom(event, leafletMap, this._el), this)
  }

  /**
   * Stop animation and redraw frame
   */
  reDraw() {
    const {
      leaflet: { map: leafletMap }
    } = this.props
    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    const {
      _southWest: { lat: lat1, lng: lon1 },
      _northEast: { lat: lat2, lng: lon2 },
    } = leafletMap.getBounds()

    this.stop()

    setTimeout(() => {
      this.start(
        [[ 0, 0 ], [ mapWidth, mapHeight ]],
        mapWidth,
        mapHeight,
        [[ lon1, lat1 ], [ lon2, lat2 ]]
      )
    },100)
  }

  /**
   * Resets canvas data and starts reDraw function for update layer view
   */
  reset() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    const zoom = leafletMap.getZoom()
    if (this._zoom !== zoom) {
      this._zoom = zoom
      this.initialize()
    }

    const topLeft = leafletMap.containerPointToLayerPoint([0, 0])
    DomUtil.setPosition(this._el, topLeft)

    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    this._el.width = mapWidth
    this._el.height = mapHeight

    this.reDraw()
  }

  /**
   * Interpolation for vectors such as wind (u, v, size)
   * https://en.wikipedia.org/wiki/Bilinear_interpolation
   * @return {number[]}
   */
  bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
    const rx = (1 - x)
    const ry = (1 - y)
    const a = rx * ry, b = x * ry, c = rx * y, d = x * y
    const u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d
    const v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d

    return [u, v, Math.sqrt(u * u + v * v)]
  }

  createWindBuilder(uComp, vComp) {
    const uData = uComp.data, vData = vComp.data
    return {
      header: uComp.header,
      data: (i) => [uData[i], vData[i]],
      interpolate: this.bilinearInterpolateVector,
    }
  }

  createBuilder(response) {
    const { data, ...rest } = response
    const uComp = {data: data.u, header: {...rest}}
    const vComp = {data: data.v, header: {...rest}}

    return this.createWindBuilder(uComp, vComp)
  }

  buildGrid(data, callback) {
    const builder = this.createBuilder(data)

    const header = builder.header
    const gridOriginEast = header.lo1, gridOriginNorth = header.la1 // Mesh origin, boundaries (for example, 0.0E, 90.0N)
    const degLon = header.dx, degLat = header.dy // The distance between grid points (for example, 2.5 degrees in longitude, 2.5 degrees in latitude)
    const ni = header.nx, nj = header.ny // Number of grid points W-E and N-S

    /*
      The scan mode is assumed to be 0. Longitude increases from gridOriginEast, and latitude decreases from gridOriginNorth.
      http://www.nco.ncep.noaa.gov/pmb/docs/grib2/grib2_table3-4.shtml
      */
    const grid = []
    let p = 0
    const isContinuous = Math.floor(ni * degLon) >= 360
    for (let j = 0; j < nj; j++) {
      const row = []
      for (let i = 0; i < ni; i++, p++) {
        row[i] = builder.data(p)
      }
      if (isContinuous) {
        // For transferred grids, duplicate the first column as the last one to simplify the interpolation logic.
        row.push(row[0])
      }
      grid[j] = row
    }

    const interpolate = (lambda, Fi) => {
      const i = floorMod(lambda - gridOriginEast, 360) / degLon // Calculate the longitude index in the transferred range [0, 360]
      const j = (gridOriginNorth - Fi) / degLat // Calculate the latitude index in the direction from +90 to -90

      const fi = Math.floor(i), ci = fi + 1
      const fj = Math.floor(j), cj = fj + 1

      let row
      if ((row = grid[fj])) {
        const g00 = row[fi]
        const g10 = row[ci]
        if (isValue(g00) && isValue(g10) && (row = grid[cj])) {
          const g01 = row[fi]
          const g11 = row[ci]
          if (isValue(g01) && isValue(g11)) {
            // All four found points, interpolate value
            return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11)
          }
        }
      }
      return null
    }

    callback({
      interpolate: interpolate,
    })
  }

  /**
   * Calculate the distortion of the wind vector caused by the shape of the projection at the point (x, y). The wind vector changes in place and is returned by this function.
   * @return {*}
   */
  distort(projection, lambda, Fi, x, y, scale, wind, windy) {
    const u = wind[0] * scale
    const v = wind[1] * scale
    const d = this.distortion(projection, lambda, Fi, x, y, windy)

    // Scaling the distortion of vectors by u and v, then add
    wind[0] = d[0] * u + d[2] * v
    wind[1] = d[1] * u + d[3] * v
    return wind
  }

  distortion(projection, lambda, Fi, x, y, windy) {
    const T = 2 * Math.PI
    const H = Math.pow(10, -5.2)
    const hLambda = lambda < 0 ? H : -H
    const hFi = Fi < 0 ? H : -H

    const pLambda = this.project(Fi, lambda + hLambda, windy)
    const pFi = this.project(Fi + hFi, lambda, windy)

    /*
      The scale factor of the meridian (see Snyder, equation 4-3), where R = 1. This solves the problem,
      where the length of the lambda 1º varies depending on Fi. Without this, a compression effect occurs at the poles.
      */
    const k = Math.cos(Fi / 360 * T)
    return [
      (pLambda[0] - x) / hLambda / k,
      (pLambda[1] - y) / hLambda / k,
      (pFi[0] - x) / hFi,
      (pFi[1] - y) / hFi,
    ]
  }

  createField(columns, bounds, callback) {
    /**
     * @returns {Array} wind vector [u, v, magnitude] at the point (x, y) or [NaN, NaN, null], if the wind is not defined at that point.
     */
    const field = (x, y) => {
      const column = columns[Math.round(x)]
      return column && column[Math.round(y)] || [NaN, NaN, null]
    }

    /*
      Frees an array of "columns" for Google Chrome.
      Without this, the array is skipped (in Chrome) every time,
      when a new field is interpolated due to a leakage of the field closure context for reasons that I don’t understand
     */
    field.release = () => columns = []

    /*
      Awful method, but beautiful picture
     */
    field.randomize = (dot) => {
      let x, y
      let safetyNet = 0
      do {
        x = Math.round(Math.floor(Math.random() * bounds.width) + bounds.x)
        y = Math.round(Math.floor(Math.random() * bounds.height) + bounds.y)
      } while (field(x, y)[2] === null && safetyNet++ < 5)
      dot.x = x
      dot.y = y
      return dot
    }

    callback(bounds, field)
  }

  buildBounds(bounds, width, height) {
    const upperLeft = bounds[0]
    const lowerRight = bounds[1]
    const x = Math.round(upperLeft[0])
    const y = Math.max(Math.floor(upperLeft[1], 0), 0)
    const yMax = Math.min(Math.ceil(lowerRight[1], height), height - 1)

    return {x: x, y: y, xMax: width, yMax: yMax, width: width, height: height}
  }

  invert(x, y, windy) {
    const mapLonDelta = windy.east - windy.west
    const worldMapRadius = windy.width / rad2deg(mapLonDelta) * 360 / (2 * Math.PI)
    const mapOffsetY = (worldMapRadius / 2 * Math.log((1 + Math.sin(windy.south)) / (1 - Math.sin(windy.south))))
    const equatorY = windy.height + mapOffsetY
    const a = (equatorY - y) / worldMapRadius

    const lat = 180 / Math.PI * (2 * Math.atan(Math.exp(a)) - Math.PI / 2)
    const lon = rad2deg(windy.west) + x / windy.width * rad2deg(mapLonDelta)
    return [lon, lat]
  }

  /**
   * Both in radians, use deg2rad if necessary
   * @return {number[]}
   */
  project(lat, lon, windy) {
    const ymin = mercY(windy.south)
    const ymax = mercY(windy.north)
    const xFactor = windy.width / (windy.east - windy.west)
    const yFactor = windy.height / (ymax - ymin)

    // y points to the south
    const y = (ymax - mercY(deg2rad(lat))) * yFactor
    const x = (deg2rad(lon) - windy.west) * xFactor
    return [x, y]
  }

  interpolateField(grid, bounds, extent, callback) {
    const projection = {}
    const velocityScale = this._velocityScale

    const columns = []
    let x = bounds.x

    const interpolateColumn = (x) => {
      const column = []
      for (let y = bounds.y; y <= bounds.yMax; y += 2) {
        const coord = this.invert(x, y, extent)
        if (coord) {
          const lambda = coord[0], Fi = coord[1]
          if (isFinite(lambda)) {
            let wind = grid.interpolate(lambda, Fi)
            if (wind) {
              wind = this.distort(projection, lambda, Fi, x, y, velocityScale, wind, extent)
              column[y + 1] = column[y] = wind
            }
          }
        }
      }
      columns[x + 1] = columns[x] = column
    }

    (function batchInterpolate(self) {
      const start = Date.now()
      while (x < bounds.width) {
        interpolateColumn(x)
        x += 2
        if ((Date.now() - start) > 1000) {
          setTimeout(batchInterpolate, 25)
          return
        }
      }
      self.createField(columns, bounds, callback)
    })(this)
  }

  animate(bounds, field) {
    const context = this._el.getContext('2d')
    context.lineWidth = this._particleLineWidth
    context.fillStyle = 'rgba(0, 0, 0, 0.9)'

    const colorStyle = 'rgba(243, 243, 238, 1)'
    const buckets = []

    let particleCount = Math.round(bounds.width * bounds.height * this._particleMultiplier)
    if (isMobile()) { particleCount *= this._particleReduction }

    const particles = []
    for (let i = 0; i < particleCount; i++) {
      particles.push(field.randomize({ age: Math.floor(Math.random() * this._maxParticleAge) }))
    }

    const evolve = () => {
      buckets.length = 0
      particles.forEach((particle) => {
        if (particle.age > this._maxParticleAge) {
          field.randomize(particle).age = 0
        }
        const x = particle.x
        const y = particle.y
        // Vector at current position
        const v = field(x, y)
        const m = v[2]
        if (m === null) {
          // Particle escaped from the grid, never to return
          particle.age = this._maxParticleAge
        } else {
          const xt = x + v[0]
          const yt = y + v[1]
          if (field(xt, yt)[2] !== null) {
            // The path from (x, y) to (xt, yt) is visible, so add this particle to the appropriate drawing block.
            particle.xt = xt
            particle.yt = yt
            buckets.push(particle)
          } else {
            // The particle is not visible, but it still moves across the field.
            particle.x = xt
            particle.y = yt
          }
        }
        particle.age += 1
      })
    }

    const draw = () => {
      // Removes existing traces of particles
      const prevGlobalCompositeOperation = context.globalCompositeOperation
      context.globalCompositeOperation = 'destination-in'
      context.fillRect(bounds.x, bounds.y, bounds.width, bounds.height)
      context.globalCompositeOperation = prevGlobalCompositeOperation

      if (buckets.length > 0) {
        context.beginPath()
        context.strokeStyle = colorStyle
        buckets.forEach((particle) => {
          context.moveTo(particle.x, particle.y)
          context.lineTo(particle.xt, particle.yt)
          particle.x = particle.xt
          particle.y = particle.yt
        })
        context.stroke()
      }
    }

    const frame = () => {
      try {
        this.timer = setTimeout(() => {
          requestAnimationFrame(frame)
          evolve()
          draw()
        }, 1000 / this._fps)
      } catch (e) {
        console.error(e)
      }
    }
    frame()
  }

  start(bounds, width, height, extent) {

    const mapBounds = {
      south: deg2rad(extent[0][1]),
      north: deg2rad(extent[1][1]),
      east: deg2rad(extent[1][0]),
      west: deg2rad(extent[0][0]),
      width: width,
      height: height,
    }

    this.stop()

    this.buildGrid(this._data, (grid) => {
      this.interpolateField(
        grid,
        this.buildBounds(bounds, width, height),
        mapBounds,
        (bounds, field) => {
          this.field = field
          this.animate(bounds, field)
        })
    })
  }

  stop() {
    const context = this._el.getContext('2d')
    context.clearRect(0, 0, this._el.width, this._el.height)

    if (this.field) this.field.release()
    if (this.timer) clearTimeout(this.timer)
  }
}

VectorMapOverlay.prototype.shouldComponentUpdate = () => (true)
VectorMapOverlay.prototype.createLeafletElement = () => (null)
VectorMapOverlay.prototype.render = () => (null)
VectorMapOverlay.prototype._createCanvas = () => document.createElement('canvas')
export default withLeaflet(VectorMapOverlay)
