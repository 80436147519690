import React from 'react'
import PropTypes from 'prop-types'
import { mapIcons } from '../../../constants/images'
import Image from '../../Common/Image/Image'

const Avatar = ({ user }) => (
  <div className="sidebar-avatar-wrapper">
    <div className="sidebar-avatar">
      <Image
        alt="#"
        className="sidebar-menu-icon user-avatar"
        src={`${user.avatar}`}
        defaultSrc={mapIcons.sidebar['avatar']}
      />
    </div>
  </div>
)

Avatar.propTypes = {
  user: PropTypes.object,
}

export default Avatar
