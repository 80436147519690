import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as loaderTypes from '../types/loader.types'

export const INITIAL_STATE = Immutable({
  message: '',
  isLoading: false,
})

export const clearStore = () => INITIAL_STATE

export const showLoader = (state, {payload}) => state.merge({ message: payload, isLoading: true }, { deep: true })

export const HANDLERS = {
  [loaderTypes.SHOW_LOADER]: showLoader,
  [loaderTypes.HIDE_LOADER]: clearStore,
}

export default createReducer(INITIAL_STATE, HANDLERS)
