import { DomUtil } from 'leaflet'
import PropTypes from 'prop-types'
import { MapLayer, withLeaflet } from 'react-leaflet'
import { safelyRemoveLayer, createCanvsaLayer, animateZoom, roundRect } from '../../helpers/layersUtils'
import { drawBezierCurve, hypotenuse } from '../../helpers/bezierCurve'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'
import { getCurrentSetting } from '../../helpers/settings'

class ContourMapOverlay extends MapLayer {
  /* Data for drawing */
  _data = []
  _fishIcon
  _zoom = DEFAULT_VIEWPORT.zoom

  static propTypes = {
    contourMapLayer: PropTypes.object.isRequired,
    layerName: PropTypes.string.isRequired,
    indexOfActiveTimestamp: PropTypes.number.isRequired,
    user: PropTypes.object,
  }

  componentDidMount() {
    this.initComponent()
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    const { layerName, indexOfActiveTimestamp, user } = this.props
    if (prevProps.layerName !== layerName) {
      this.initComponent()
    } else if (prevProps.indexOfActiveTimestamp !== indexOfActiveTimestamp) {
      this.initialize()
      this.reset()
    } else if (JSON.stringify(user) !== JSON.stringify(prevProps.user)) {
      this.reset()
    }
  }

  componentWillUnmount() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    safelyRemoveLayer(leafletMap, this._el)
  }

  initComponent() {
    if (!this.leafletElement || !this._el) {
      const { el, _CanvasLayer } = createCanvsaLayer(this, 'contour-layer')
      this._el = el
      this.leafletElement = new _CanvasLayer()
    }

    this.initialize()
    this.attachEvents()

    this.reset()
  }

  /**
   * Initialize component data and restrictions
   */
  initialize() {
    const { contourMapLayer } = this.props
    this.layer = contourMapLayer
    if (!this.layer) {
      return
    }
    const { bounds, data } = this.layer

    if (!data || !data.length || !bounds) {
      return
    }
    this.layerData = data
  }

  /**
   * Adds events for calling reDraw method
   */
  attachEvents() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.on('moveend', () => this.reset())
    leafletMap.on('zoomanim', (event) => animateZoom(event, leafletMap, this._el), this)
  }

  /**
   * Resets canvas data and starts reDraw function for update layer view
   */
  reset() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    const zoom = leafletMap.getZoom()
    if (this._zoom !== zoom) {
      this._zoom = zoom
      this.initialize()
    }

    const topLeft = leafletMap.containerPointToLayerPoint([0, 0])
    DomUtil.setPosition(this._el, topLeft)

    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    this._el.width = mapWidth
    this._el.height = mapHeight

    if (!this.layer || !this.layerData) {
      return
    }

    this.draw()
  }

  /**
   * Draws a fish map from prepared data
   */
  draw() {
    const {
      leaflet: { map: leafletMap },
    } = this.props
    const bounds = leafletMap.getBounds()
    const context = this._el.getContext('2d')

    context.strokeStyle = 'rgba(255, 255, 255, 1)'
    context.lineWidth = 1

    this.layerData.forEach(({ isolines, value }) => {
      isolines.forEach((data) => {
        const points = data.reduce((points, [ lng, lat ], i) => {
          if (points.length === 0) {
            return [[ lng, lat ]]
          }
          if (data.length - 1 === i) {
            return [...points, [ lng, lat ]]
          }

          const index = points.length - 1
          if (hypotenuse(lng - points[index][0], lat - points[index][1]) > 1.25) {
            return [...points, [ lng, lat ]]
          }
          return points
        }, [])

        if (points.length > 10) {
          const valuePositions = []
          const isoline = points.map(([ lng, lat ], index) => {
            const { x, y } = leafletMap.latLngToContainerPoint([ lat, lng ])
            if (bounds.contains([ lat, lng ])) {
              valuePositions.push({ x, y, index })
            }
            return { x, y }
          })

          drawBezierCurve(context, isoline)
          this._createValueContainer(valuePositions, isoline, value)
        }
      })
    })
  }

  _createValueContainer(valuePositions, points, value) {
    const context = this._el.getContext('2d')
    let scale = 1
    switch (true) {
      case this._zoom <= 7 && this._zoom > 6.5: scale = 1.25; break
      case this._zoom <= 6.5 && this._zoom >= 6: scale = 1.5; break
      default: scale = 1; break
    }
    const { user } = this.props
    const setting = getCurrentSetting('pressure', user)

    context.fillStyle = 'rgba(255, 255, 255, 1)'
    context.font = `bold ${12 / scale}px Roboto`
    context.textAlign = 'center'
    context.textBaseline = 'middle'

    const index = Math.ceil(valuePositions.length / 2)
    if (index !== 0 && valuePositions.length > 2) {
      const { x: x1, y: y1, index: i } = valuePositions[index]
      const { x: x2, y: y2 } = points[i - 1] ? points[i - 1] : points[i + 1]
      const angle = x2 < x1 ? Math.atan2(y1 - y2, x1 - x2) : Math.atan2(y2 - y1, x2 - x1)

      const width = context.measureText(value).width + 20 / scale
      const height = 20 / scale

      context.beginPath()
      context.translate(x1 - width / 2 + width / 2, y1 - height / 2 + height / 2)
      context.rotate(angle)
      context.translate(- (x1 - width / 2 + width / 2), - (y1 - height / 2 + height / 2))

      context.fillStyle = 'rgba(255, 255, 255, 1)'
      roundRect(context,x1 - width / 2, y1 - height / 2, width, height, height / 2)

      context.fillStyle = 'rgba(0, 0, 0, 1)'
      context.fillText((setting.convert(value)).toFixed(0), x1, y1)
      context.closePath()

      context.resetTransform()
    }
  }
}

ContourMapOverlay.prototype.shouldComponentUpdate = () => (true)
ContourMapOverlay.prototype.createLeafletElement = () => (null)
ContourMapOverlay.prototype.render = () => (null)
ContourMapOverlay.prototype._createCanvas = () => document.createElement('canvas')
export default withLeaflet(ContourMapOverlay)
