import { temperaturesDefaultGradient as defaultGradient } from '../constants/gradients'

export function createGradient(canvasElem, toBottom = false, gradientData = defaultGradient, x = 0, dw = 0, y = 0, dh = 0, clear = true) {
  if (!canvasElem) {
    return
  }
  const gradientValue = gradientData ? gradientData : defaultGradient
  const canvas = canvasElem.current ? canvasElem.current : canvasElem
  const { width, height } = canvas
  const context = canvas.getContext('2d')
  if (clear) {
    context.clearRect(x, y, dw !== 0 ? dw : width, dh !== 0 ? dh : height)
  }
  let gradient = null
  if (toBottom) {
    gradient = context.createLinearGradient(0, 0, 0, height)
  } else {
    gradient = context.createLinearGradient(0, 0, width, 0)
  }

  if (gradient) {
    Object.keys(gradientValue).forEach((offset) => {
      if (offset > 1) {
        gradient.addColorStop(offset / 100, gradientValue[offset])
      } else {
        gradient.addColorStop(offset, gradientValue[offset])
      }
    })
    context.beginPath()
    context.fillStyle = gradient
    context.fillRect(x, y, dw !== 0 ? dw : width, dh !== 0 ? dh : height)
  }
}

/**
 * Create Canvas Gradient
 * From remote canvas
 * @gradientData {object}
 * @return {Uint8ClampedArray} (gradient data from canvas)
 */
export function createMockGradient(gradientData = defaultGradient) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const gradient = context.createLinearGradient(0, 0, 0, 256)
  const gradientValue = gradientData ? gradientData : defaultGradient

  canvas.width = 1
  canvas.height = 256

  Object.keys(gradientValue).forEach((offset) => {
    if (offset > 1) {
      gradient.addColorStop(offset / 100, gradientValue[offset])
    } else {
      gradient.addColorStop(offset, gradientValue[offset])
    }
  })

  context.fillStyle = gradient
  context.fillRect(0, 0, 1, 256)

  return context.getImageData(0, 0, 1, 256).data
}

/**
 * Colors the layer. Replaces dark places with corresponding ones in the gradient.
 * @param pixels: Uint8ClampedArray (image data)
 * @param gradient: Uint8ClampedArray (gradient data)
 * @private
 */
export function colorize(pixels, gradient) {
  for (let i = 0; i < pixels.length; i += 4) {
    const pixelOpacity = pixels[i + 3] * 4
    if (pixelOpacity) {
      pixels[i] = gradient[pixelOpacity]
      pixels[i + 1] = gradient[pixelOpacity + 1]
      pixels[i + 2] = gradient[pixelOpacity + 2]
      pixels[i + 3] = 255
    }
  }
}
