export const timelineMarks = (firstLabel) => ({
  0: {
    style: {
      marginLeft: 0,
      textAlign: 'left'
    },
    label: firstLabel
  },
  8: '+24:00:00',
  16: '+48:00:00',
  24: {
    style: {
      left: 'auto',
      right: 0,
      marginLeft: 0,
      textAlign: 'right'
    },
    label: '+72:00:00'
  },
})
