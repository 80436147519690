export const MIN_VALUE = -9999
export const MAX_VALUE = 9999

export const DEFAULT_VIEWPORT = {
  defaultZoom: 6,
  minZoom: 3,
  maxZoom: 12,
  zoomSnap: 0.25,
  zoomDelta: 0.25,
  maxBounds: [[47.7, -99.5], [7.5, -59.5]],
  maxBoundsViscosity: 1.0,
  doubleClickZoom: false,
  center: { lat: 33.68960787248198, lng: -77.73934364318849 }
}
