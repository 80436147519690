import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LayersContext } from '../constants/layersContext'
import { MIN_VALUE, MAX_VALUE } from '../constants/leaflet.settings'
import { get } from '../helpers/fetch'
import statusHelper from '../helpers/statusHelper'
import { types } from '../constants/layers'
import * as layersActions from '../store/actions/layers.actions'
import * as waypointsActions from '../store/actions/waypoints.actions'

class LayersProvider extends PureComponent {
  static contextType = LayersContext
  static propTypes = {
    children: PropTypes.node,
    layersList: PropTypes.array,
    types: PropTypes.array,
    timestamps: PropTypes.array,
    showLayerLoading: PropTypes.func.isRequired,
    hideLayerLoading: PropTypes.func.isRequired,
    switchesLayerActive: PropTypes.func.isRequired,
    switchesLayerInActive: PropTypes.func.isRequired,
    viewAllWaypointsData: PropTypes.func.isRequired,
  }

  state = {
    layers: [],
    indexOfActiveTimestamp: 0,
    playTimeline: false,
    onPlayClick: () => this.setState({ ...this.state, playTimeline: true }),
    onPauseClick: () => this.setState({ ...this.state, playTimeline: false }),
    showLayer: (layerName) => this.showLayer(layerName),
    changeIndexOfActiveTimestamp: (index) => this.changeIndexOfActiveTimestamp(index)
  }

  componentDidMount() { this.initLayerProvider() }
  componentDidUpdate() { this.initLayerProvider() }

  /**
   * Initialize layer provider. Add mock data for layers
   */
  initLayerProvider() {
    const { types } = this.props
    const { layers } = this.state
    if (types && types.length && (!layers || !layers.length)) {
      const layers = types.map(type => ({ type, data: {} }))
      this.setState({ ...this.state, layers })
    }
  }

  async changeIndexOfActiveTimestamp(index) {
    const { layersList, timestamps, showLayerLoading, hideLayerLoading, viewAllWaypointsData } = this.props
    const activeLayer = layersList.filter(({ active, name, depth }) => !depth && active && name)
    if (!activeLayer || !activeLayer.length) {
      this.setState({ ...this.state, indexOfActiveTimestamp: index })
      viewAllWaypointsData(index)
      return
    }
    await new Promise(resolve => {
      Promise.all(activeLayer.map(async ({name: layerName}) => {
        const currentLayer = layersList.find(({ name }) => layerName === name)
        if (currentLayer) {
          showLayerLoading(layerName)
          const currentTimestamp = timestamps[index]
          const currentBounds = currentLayer.timestamps[index].bounds
          await this.fillLayerData(layerName, currentLayer.type, currentTimestamp, currentBounds)
          hideLayerLoading(layerName)
        }
      })).then(() => resolve())
    })

    this.setState({ ...this.state, indexOfActiveTimestamp: index })
    viewAllWaypointsData(index)
  }

  async showLayer(layerName) {
    const { layersList, timestamps, switchesLayerActive, viewAllWaypointsData, showLayerLoading, switchesLayerInActive } = this.props
    const { indexOfActiveTimestamp } = this.state
    const activeLayers = layersList.reduce((activeLayers, { depth, active, ...data }) => {
      return active && !depth ? [...activeLayers, { active, ...data }] : [...activeLayers]
    }, [])
    const currentLayer = layersList.find(({ name: currentLayerName }) => layerName === currentLayerName)
    if (activeLayers && activeLayers.length && currentLayer) {
      activeLayers.forEach(({ type, name }) => {
        if (type === currentLayer.type) {
          switchesLayerInActive(name)
          viewAllWaypointsData(indexOfActiveTimestamp)
        }
      })
    }
    if (currentLayer && timestamps.length && timestamps[indexOfActiveTimestamp]) {
      showLayerLoading(layerName)

      const currentTimestamp = timestamps[indexOfActiveTimestamp]
      const currentBounds = currentLayer.timestamps[indexOfActiveTimestamp].bounds
      await this.fillLayerData(layerName, currentLayer.type, currentTimestamp, currentBounds)

      switchesLayerActive(layerName)
      viewAllWaypointsData(indexOfActiveTimestamp)
    }
  }

  async fillLayerData(layerName, type, currentTimestamp, currentBounds) {
    return new Promise(async resolve => {
      const response = await this.fetchLayerData(layerName, currentTimestamp)
      const updatedLayers = this.generateUpdatedLayer(response, type, currentTimestamp, currentBounds)
      this.setState({ ...this.state, layers: updatedLayers })
      resolve()
    })
  }

  generateUpdatedLayer(response, type, currentTimestamp, currentBounds) {
    const { layers } = this.state
    const formattingResponse = this.formattingResponse(response, type, currentBounds)
    return layers.map((layer) => {
      if (layer.type === type) {
        if (layer.type === types.contour) {
          return { type, data: { bounds: currentBounds, ...formattingResponse }}
        } else {
          return { type, data: { bounds: currentBounds, data: formattingResponse }}
        }
      }
      return layer
    })
  }

  formattingResponse(response, type, bounds) {
    if (type === types.vector) {
      const updatedResponse = response.map(data => this.formattingResponseData(data, type, bounds))
      if (!updatedResponse || !updatedResponse.length) {
        throw new Error('Can\'t convert data for this layer')
      }
      return updatedResponse.map(({ zoom, ...rest }) => ({ ...rest, zoom, dx: zoom / 10, dy: zoom / 10 }))
    }
    if (type === types.contour) {
      return response
    }
    return response.map(data => this.formattingResponseData(data, type, bounds))
  }

  formattingResponseData(responseData, type, bounds) {
    const { layersList } = this.props
    const startingLat = bounds[0][0]
    const startingLng = bounds[0][1]
    const ny = responseData.ny
    const nx = responseData.nx
    const pointStep = parseFloat(responseData.dx)
    const data = []
    const currentLayer = layersList.find(({ depth, active, selected, type: layerType }) => (active || selected) && !depth && type === layerType)
    if (!currentLayer) {
      return []
    }

    const { min, max } = currentLayer

    if (type === types.vector) {
      const { data: { u, v } } = responseData
      const updatedU = u.map(value => {
        if (value && (value > MIN_VALUE && value < MAX_VALUE || min && max && value > min && value < max)) {
          return value >= max ? max : value
        }
        return null
      })
      const updatedV = v.map(value => {
        if (value && (value > MIN_VALUE && value < MAX_VALUE || min && max && value > min && value < max)) {
          return value >= max ? max : value
        }
        return null
      })

      return { ...responseData, data: { u: updatedU, v: updatedV } }
    }

    let dataIndex = 0
    for (let i = 0; i < ny; i++) {
      for (let j = 0; j < nx; j++) {
        if (type === types.gradient || type === types.fish) {
          const lat = startingLat - i * pointStep
          const lng = startingLng + j * pointStep
          const value = parseFloat(responseData.data[dataIndex])
          if (value && (value > MIN_VALUE && value < MAX_VALUE || min && max && value > min && value < max)) {
            data.push({ lat, lng, value: value >= max ? max - min : value - min })
          }
        }
        dataIndex++
      }
    }

    return {...responseData, data}
  }

  async fetchLayerData(layerName, timestamp) {
    try {
      const response = await get(`/layers-alt/${layerName}/${timestamp}`)
      const error = statusHelper(response)
      const json = await response.json()
      if (error) {
        throw new Error(json.message.includes('Unauthorized') ? 'Unauthorized' : json.message)
      }

      return json
    } catch(e) {
      throw new Error(e.message)
    }
  }

  _getLayersData(type) {
    const layerData = this.state.layers.find(({ type: layerType }) => type === layerType)
    return layerData ? layerData.data : []
  }


  render() {
    return (
      <LayersContext.Provider value={this.state}>
        {this.props.children}
      </LayersContext.Provider>
    )
  }
}

const mapStateToProps = ({ layersStore: { layersList, types, timestamps } }) => ({ layersList, types, timestamps })
const mapDispatchToProps = (dispatch) => ({
  showLayerLoading: (layerName) => dispatch(layersActions.showLayerLoading(layerName)),
  hideLayerLoading: (layerName) => dispatch(layersActions.hideLayerLoading(layerName)),
  switchesLayerActive: (layerName) => dispatch(layersActions.switchesLayerActive(layerName)),
  switchesLayerInActive: (layerName) => dispatch(layersActions.switchesLayerInActive(layerName)),
  viewAllWaypointsData: (indexOfActiveTimestamp) => dispatch(waypointsActions.viewAllWaypointsData(indexOfActiveTimestamp))
})
export default connect(mapStateToProps, mapDispatchToProps)(LayersProvider)
