/**
 * Getting layer data
 */
export const GET_DEPTH_LAYER_DATA = 'get depth layer data'
export const GET_DEPTH_LAYER_DATA_SUCCEES = 'get depth layer data success'
export const GET_DEPTH_LAYER_DATA_FAILED = 'get depth layer data failed'

/**
 * Directions controls
 */
export const UPDATE_DIRECTIONS = 'update depth directions'
