export const mapIcons = {
  authLogo: require('../assets/images/icons/logo.png'),
  sidebar: {
    avatar: require('../assets/images/icons/sidebar/avatar.svg'),
    profile: require('../assets/images/icons/sidebar/profile.svg'),
    applications: require('../assets/images/icons/sidebar/layers.svg'),
    snapshots: require('../assets/images/icons/sidebar/camera.svg'),
    waypoints: require('../assets/images/icons/sidebar/waypoints.svg'),
    logout: require('../assets/images/icons/sidebar/logout.svg'),
    logout_hover: require('../assets/images/icons/sidebar/logout_hover.svg'),
    ocean_now_icon: require('../assets/images/icons/sidebar/ocean_now.png'),
    question: require('../assets/images/icons/sidebar/question.svg'),
  },
  layers: {
    air: require('../assets/images/icons/layers/air-temperature.svg'),
    water: require('../assets/images/icons/layers/water-temperature.svg'),
    fishIcon: require('../assets/images/icons/layers/fish.svg'),
  },
  subRoutes: {
    close: require('../assets/images/icons/subroutes/close.svg'),
    download: require('../assets/images/icons/subroutes/download.svg'),
    print: require('../assets/images/icons/subroutes/print.svg'),
  },
  waypoints: [
    require('../assets/images/icons/waypoints/blue.svg'),
    require('../assets/images/icons/waypoints/yellow.svg'),
    require('../assets/images/icons/waypoints/red.svg'),
    require('../assets/images/icons/waypoints/green.svg'),
    require('../assets/images/icons/waypoints/orange.svg'),
  ],
  doNewSnapshot: require('../assets/images/icons/snapshots/doSnapshot.svg'),
  saveWaypoints: require('../assets/images/icons/waypoints/save-waypoints.svg'),
  caretDown: require('../assets/images/icons/profile/caret-down.svg'),
  caretDownLight: require('../assets/images/icons/profile/caret-down-light.svg'),
  applications: {
    lock: require('../assets/images/icons/applications/lock.svg'),
    check: require('../assets/images/icons/applications/check.svg'),
    fish: require('../assets/images/icons/applications/fish.svg'),
    airTemp: require('../assets/images/icons/applications/airTemp.svg'),
    bottomTemp: require('../assets/images/icons/applications/bottomTemp.svg'),
    current: require('../assets/images/icons/applications/current.svg'),
    eddyTracker: require('../assets/images/icons/applications/eddyTracker.svg'),
    rain: require('../assets/images/icons/applications/rain.svg'),
    seaSurface: require('../assets/images/icons/applications/seaSurface.svg'),
    surfacePreassure: require('../assets/images/icons/applications/surfacePreassure.svg'),
    surfaceTemp: require('../assets/images/icons/applications/surfaceTemp.svg'),
    surfaceWind: require('../assets/images/icons/applications/surfaceWind.svg'),
    waterQuality: require('../assets/images/icons/applications/waterQuality.svg'),
    waterSalinity: require('../assets/images/icons/applications/waterSalinty.svg'),
    waterTemp: require('../assets/images/icons/applications/waterTemp.svg'),
    waveHeight: require('../assets/images/icons/applications/waveHeight.svg'),
  },
  timeline: {
    play: require('../assets/images/icons/timeline/play.svg'),
    pause: require('../assets/images/icons/timeline/pause.svg'),
    loop: require('../assets/images/icons/timeline/loop.svg'),
    prev: require('../assets/images/icons/timeline/prev.svg'),
    next: require('../assets/images/icons/timeline/next.svg'),
    runner: require('../assets/images/icons/timeline/runner.svg'),
    flag: require('../assets/images/icons/timeline/flag.svg'),
  },
  depth: {
    lock: require('../assets/images/icons/depth/lock.svg'),
    relief: require('../assets/images/relief-mock.png'),
  }
}
