import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getImage } from '../../../helpers/fetch'

class Image extends Component {
  _mounted = false
  state = { src: null }
  static propTypes = {
    src: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    defaultSrc: PropTypes.string,
    type: PropTypes.string,
  }

  async componentDidMount() {
    this._mounted = true
    const image = await this.createImageSrc()
    if (this._mounted) {
      this.setState(image)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      const image = await this.createImageSrc()
      if (this._mounted) {
        this.setState(image)
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  async createImageSrc() {
    let { src: icon, type, defaultSrc, ...rest } = this.props
    if (!type && typeof icon === 'string') {
      switch (true) {
        case icon.endsWith('svg'): type = 'image/svg+xml'; break
        case icon.endsWith('jpg'): type = 'image/jpeg'; break
        case icon.endsWith('jpeg'): type = 'image/jpeg'; break
        case icon.endsWith('png'): type = 'image/png'; break
        default: type = 'image/png'
      }
    }

    if (!icon || (typeof icon === 'string' && icon.endsWith('undefined'))) {
      return { src: defaultSrc, ...rest }
    } else if (typeof icon === 'string' && icon.startsWith('http')) {
      return { src: icon, ...rest }
    } else if (typeof icon === 'string' && !icon.startsWith('data')) {
      const image = await getImage(icon, type)
      return { src: image && !image.endsWith('data:') ? image : defaultSrc, ...rest }
    } else if (typeof icon === 'object') {
      const reader = new FileReader()
      reader.readAsDataURL(icon)
      return new Promise(resolve => {
        reader.onloadend = () => resolve({ src: reader.result, ...rest })
      })
    }
  }

  onError = () => {
    const { defaultSrc = null } = this.props
    this.setState({ ...this.state, src: defaultSrc })
  }

  render() {
    if (!this.state.src) {
      return null
    }
    return (<img {...this.state} onError={this.onError} />)
  }
}

export default Image
