import React, { Component } from 'react'
import PropTypes from 'prop-types'
import saveAs from 'file-saver'

import { getImage } from '../../../helpers/fetch'
import { uniqueId } from '../../../helpers/lib'
import { mapIcons } from '../../../constants/images'

class SnapshotItem extends Component {
  _mounted = false
  state = { image: null }
  static propTypes = {
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    deleteSnapshot: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this._mounted = true
    const { src } = this.props
    if (src) {
      getImage(src).then(image => {
        this._mounted && this.setState({ image })
      })
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  handleDownload = () => {
    const { image } = this.state
    const { name } = this.props

    /**
     * Fix for Safari (iOS)
      */
    if(navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      const a = document.createElement('a')
      a.href = image
      a.style.display = 'none'
      a.download = name
      document.body.appendChild(a)
      this.click(a)
      a.parentNode.removeChild(a)
    } else {
      try {
        saveAs(image, name)
      } catch (e) {
        console.error(e)
      }
    }
  }

  click = node => {
    try {
      node.dispatchEvent(new MouseEvent('click'))
    } catch (e) {
      const evt = document.createEvent('MouseEvents')
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null)
      node.dispatchEvent(evt)
    }
  }

  render() {
    const { image } = this.state
    const { id, deleteSnapshot } = this.props
    const { subRoutes: { close, download, print } } = mapIcons

    return (
      <div className="snapshot-item-wrapper">
        <div className="snapshot-item">
          <img src={close} alt={uniqueId()} className="snapshot-item-close" onClick={() => deleteSnapshot(id)} />
          <div style={{ backgroundImage: `url(${image})` }} className="snapshot-item-image" />
        </div>
        <div className="snapshot-item-buttons-row">
          <img
            src={download}
            alt={uniqueId()}
            onClick={this.handleDownload}
            className="snapshot-link download-icon"
          />
          <img src={print} alt={uniqueId()} className="snapshot-link print-icon" />
        </div>
      </div>
    )
  }
}

export default SnapshotItem
