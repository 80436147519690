import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'
import * as snapshotsTypes from '../types/snapshots.types'

export const INITIAL_STATE = Immutable({
  snapshots: [],
  loading: false,
  isUpload: false,
  isError: null,
  errorText: '',
})

export const clearStore = () => INITIAL_STATE

/**
 * Get snapshots
 */
export const getSnapshotsRequest = state =>
  state.merge({ loading: true, isError: false, errorText: '' }, { deep: true })

export const getSnapshotsSuccess = (state, {payload}) =>
  state.merge({ loading: false, snapshots: payload }, { deep: true })

export const getSnapshotsFailure = (state, {payload}) =>
  state.merge({ loading: false, errorText: payload, isError: true }, { deep: true })

/**
 * Upload snapshots
 */
export const uploadSnapshotRequest = state =>
  state.merge({ isUpload: true, isError: false, errorText: '' }, { deep: true })

export const uploadSnapshotSuccess = (state, {payload}) =>
  state.merge(
    { isUpload: false, snapshots: typeof payload === 'object' ? [payload] : payload },
    { deep: true },
  )

export const uploadSnapshotFailure = (state, {payload}) =>
  state.merge({ isUpload: false, errorText: payload, isError: true }, { deep: true })

/**
 * Delete snapshots
 */
export const deleteSnapshotRequest = state =>
  state.merge({ loading: true, isError: false, errorText: '' }, { deep: true })

export const deleteSnapshotSuccess = (state, { payload: id }) =>
  state.merge(
    { loading: false, snapshots: [...state.snapshots.filter(snapshot => snapshot.id !== id)] },
    { deep: true },
  )

export const deleteSnapshotFailure = (state, {payload}) =>
  state.merge({ loading: false, errorText: payload, isError: true }, { deep: true })

export const HANDLERS = {
  [authTypes.LOGOUT_USER_SUCCEEDED]: clearStore,
  [snapshotsTypes.FETCH_SNAPSHOTS_REQUESTED]: getSnapshotsRequest,
  [snapshotsTypes.FETCH_SNAPSHOTS_SUCCEEDED]: getSnapshotsSuccess,
  [snapshotsTypes.FETCH_SNAPSHOTS_REQUESTED]: getSnapshotsFailure,
  [snapshotsTypes.UPLOAD_SNAPSHOT_REQUESTED]: uploadSnapshotRequest,
  [snapshotsTypes.UPLOAD_SNAPSHOT_SUCCEEDED]: uploadSnapshotSuccess,
  [snapshotsTypes.UPLOAD_SNAPSHOT_FAILED]: uploadSnapshotFailure,
  [snapshotsTypes.DELETE_SNAPSHOT_REQUESTED]: deleteSnapshotRequest,
  [snapshotsTypes.DELETE_SNAPSHOT_SUCCEEDED]: deleteSnapshotSuccess,
  [snapshotsTypes.DELETE_SNAPSHOT_FAILED]: deleteSnapshotFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
