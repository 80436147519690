import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, Point } from 'leaflet'
import { Marker } from 'react-leaflet'
import { mapIcons } from '../../constants/images'

const WaypointMarker = ({position, index, onDrag, onDragEnd}) => {
  const icon = new Icon({
    iconUrl: mapIcons.waypoints[index],
    iconAnchor: new Point(15, 40),
  })

  return (
    <Marker
      icon={icon}
      draggable={true}
      onDrag={event => onDrag && onDrag(event)}
      onDragEnd={event => onDragEnd && onDragEnd(event)}
      position={position}
      ref={createRef()}
    />
  )
}

WaypointMarker.propTypes = {
  position: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
}

export default WaypointMarker
