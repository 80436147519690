/**
 * Getting layer data
 */
export const GET_LAYERS_LIST = 'get layers list'
export const GET_LAYERS_LIST_SUCCEEDED = 'get layers list success'
export const GET_LAYERS_LIST_FAILED = 'get layers list failed'

/**
 * Show/hide layer loader
 */
export const SHOW_LAYER_LOADING = 'switch on layer loading'
export const HIDE_LAYER_LOADING = 'switch off layer loading'

/**
 * Switches active state
 */
export const SWITCHES_LAYER_ACTIVE = 'switch on layer acitve state'
export const SWITCHES_LAYER_INACTIVE = 'switch off layer acitve state'
