export const authorizedMenu =  [
  {
    title: 'Forgot',
    path: '/profile',
    icon: 'profile',
    profileRoutes: [
      {
        title: 'Edit Profile',
        path: '/profile/edit',
      },
      // {
      //   title: 'Billing',
      //   path: '/profile/billing',
      // },
    ]
  },
  //{
  //  title: 'Applications',
  //  path: '/applications',
  //  icon: 'applications',
  //},
  {
    title: 'Waypoints',
    path: '/waypoints',
    icon: 'waypoints',
  },
  {
    title: 'Snapshots',
    path: '/snapshots',
    icon: 'snapshots',
  },
  {
    title: 'Support',
    path: '/support',
    icon: 'question',
  },
]

export const unauthorizedMenu =  [
  {
    title: 'Login',
    path: '/profile',
    icon: 'profile',
    profileRoutes: [
      {
        title: 'Edit Profile',
        path: '/profile/edit',
      },
      // {
      //   title: 'Billing',
      //   path: '/profile/billing',
      // },
    ]
  },
  {
    title: 'Support',
    path: '/support',
    icon: 'question',
  },
]
