import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import * as snapshotsActions from '../../store/actions/snapshots.actions'
import * as loaderActions from '../../store/actions/loader.actions'

import { generateCanvasBlob, uniqueId } from '../../helpers/lib'
import { downloadAllSnapshots } from '../../helpers/download'

import subMenu from '../../higher-order-components/SubMenu/SubMenu'
import Button from '../../components/Common/Button/Button'
import SnapshotItem from './SnapshotItem/SnapshotItem'

class Snapshots extends Component {
  _snapshotKey = uniqueId()

  static propTypes = {
    user: PropTypes.object.isRequired,
    snapshots: PropTypes.array.isRequired,
    getAllSnapshots: PropTypes.func.isRequired,
    uploadSnapshot: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
  }

  handleSaveSnapshot = async () => {
    const { showLoader, uploadSnapshot } = this.props
    showLoader('Saving snapshot')
    const snapshot = await generateCanvasBlob()
    const snapshotName = uniqueId()
    uploadSnapshot(snapshot, snapshotName)
  }

  componentDidMount() {
    this.props.getAllSnapshots()
  }

  render() {
    const { user, snapshots, deleteSnapshot } = this.props

    return (
      <div className="snapshots-menu">
        {this.renderHeader(user)}
        {this.renderBody(snapshots, deleteSnapshot)}
        {snapshots.length > 0 && this.renderFooter(snapshots)}
      </div>
    )
  }

  renderHeader = () => (
    <div className="snapshots-menu-header">
      <span className="snapshots-menu-greet">Snapshots</span>
      <Button size="btn-sm" color="btn-sapphire" onClick={() => this.handleSaveSnapshot()}>
        + New Snapshot
      </Button>
    </div>
  )

  renderBody = (snapshots, deleteSnapshot) => (
    <SimpleBar>
      <div className="snapshots-menu-body">
        {snapshots &&
        snapshots.map(({ image, ...rest }) => (
          <SnapshotItem
            src={image}
            {...rest}
            deleteSnapshot={id => deleteSnapshot(id)}
            key={`${this._snapshotKey}${image}`}
          />
        ))}
      </div>
    </SimpleBar>
  )

  renderFooter = snapshots => (
    <a className="snapshots-menu-footer">
      <Button size="btn-sm" color="btn-sapphire" onClick={() => downloadAllSnapshots(snapshots)}>
        Download all
      </Button>
    </a>
  )
}

const mapStateToProps = ({ userStore: { user }, snapshotsStore: { snapshots } }) => ({ user, snapshots })

const mapDispatchToProps = dispatch => ({
  getAllSnapshots: () => dispatch(snapshotsActions.getUserInfoRequested()),
  uploadSnapshot: (image, name) => dispatch(snapshotsActions.uploadSnapshotRequested(image, name)),
  deleteSnapshot: id => dispatch(snapshotsActions.deleteSnapshotRequested(id)),
  showLoader: message => dispatch(loaderActions.showLoader(message)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(subMenu(Snapshots)))
