import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { PureComponent, Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { ToastContainer } from 'react-toastify'
import { types } from '../constants/layers'
import { LayersContext } from '../constants/layersContext'
import { DEFAULT_VIEWPORT } from '../constants/leaflet.settings'

import * as layersActions from '../store/actions/layers.actions'

import Support from './Support/Support'
import Profile from './Profile/Profile'
import DepthView from './DepthView/DepthView'
import Snapshots from './Snapshots/Snapshots'
import Waypoints from './Waypoints/Waypoints'
import LeafletMap from './LeafletMap/LeafletMap'
import ProfileEdit from './ProfileEdit/ProfileEdit'
import Applications from './Applications/Applications'
import Authorization from './Authorization/Authorization'

import Legend from '../components/Legend/Legend'
import Sidebar from '../components/Sidebar/Sidebar'
import Timeline from '../components/Timeline/Timeline'
import LayersList from '../components/LayersList/LayersList'
import ScreenLoader from '../components/ScreenLoader/ScreenLoader'
import UserActionsList from '../components/UserActionsList/UserActionsList'
import ZoomControl from '../components/ZoomControl/ZoomControl'
import WaypointsAccordion from '../components/WaypointsAccordion/WaypointsAccordion'

class App extends PureComponent {
  static contextType = LayersContext
  state = { zoom: DEFAULT_VIEWPORT.defaultZoom }
  static propTypes = {
    authorized: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    layersList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    getLayersList: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
  }

  componentDidMount() {
    const {
      authorized,
      history,
      layersList,
      getLayersList,
      location: { pathname },
    } = this.props
    if (!authorized && pathname !== '/auth') { history.push('/auth') }
    if (!layersList || !layersList.length) { getLayersList() }
  }

  componentDidUpdate() {
    const { layersList, getLayersList } = this.props
    const { showLayer } = this.context
    if (!layersList || !layersList.length) { getLayersList() }

    const defaultLayers = layersList.filter(({ is_default, loading, active }) => is_default && !loading && !active)
    if (defaultLayers.length) {
      defaultLayers.forEach(({ name }) => showLayer(name))
    }
  }

  render() {
    const { authorized, match, isLoading, layersList, message = 'Loading' } = this.props
    const activeLayer = layersList.find(({ active, type, depth }) => !depth && active && type === types.gradient)

    return (
      <Fragment>
        <main className="app">
          <div className="app__left-block">
            <Sidebar />
            <LayersList />
            <Timeline />
            {activeLayer && <Legend activeLayer={activeLayer} />}
            {authorized ? <Authorized match={match} /> : <Unauthorized />}
          </div>
          <div className="app__right-block">
            {authorized && <UserActionsList />}
            <WaypointsAccordion />
            {authorized && <Route path="/depth" component={DepthView} />}
            <ZoomControl zoom={this.state.zoom} onZoomChange={(zoom) => this.setState({ ...this.state, zoom })} />
          </div>
          <LeafletMap zoom={this.state.zoom} onZoomChange={(zoom) => this.setState({ ...this.state, zoom })} />
        </main>
        <ToastContainer autoClose={5000} />
        {authorized && isLoading && <ScreenLoader message={message} />}
      </Fragment>
    )
  }
}

const Unauthorized = () => (
  <Switch>
    <Route exact={true} path="/auth" component={Authorization} />
    <Route path="/signup" component={Authorization} />
    <Route path="/api/auth/password/find/:token" component={Authorization} />
    <Route path="/support" component={Support} />
    <Route path="/demo" component={null} />
    <Redirect to="/auth" />
  </Switch>
)

const Authorized = match => (
  <Switch>
    <Route exact={true} path="/" component={null} />
    <Route path="/profile" component={ProfileRoutes} match={match} />
    <Route path="/applications" component={Applications} />
    <Route path="/waypoints" component={Waypoints} />
    <Route path="/snapshots" component={Snapshots} />
    <Route path="/support" component={Support} />
    <Route path="/depth" component={null} />
    <Redirect to="/" />
  </Switch>
)

const ProfileRoutes = ({ match: { url } }) => (
  <Fragment>
    <Route path={url} component={Profile} />
    <Route path={`${url}/edit`} component={ProfileEdit} />
  </Fragment>
)

ProfileRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

const mapStateToProps = ({
  authStore: { authorized },
  layersStore: { layersList },
  loaderStore: { message, isLoading },
}) => ({ authorized, layersList, message, isLoading })
const mapDispatchToProps = dispatch => ({ getLayersList: () => dispatch(layersActions.getLayersList()) })


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
