import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'
import * as depthTypes from '../types/depth.types'
import * as waypointsTypes from '../types/waypoints.types'

export const INITIAL_STATE = Immutable({
  loading: false,
  isError: false,
  errorText: null,
  directions: {},
  depthData: [],
})

export const clearStore = () => INITIAL_STATE

export const getDepthLayerDataSuccess = (state, { payload: depthData }) => state.merge({ depthData }, { deep: true })
export const getDepthLayerDataError = (state, { payload: error }) => state.merge({ isError: true, errorText: error }, { deep: true })

export const updateDirections = (state, { payload: { left, right } }) => state.merge({ directions: { left, right } }, { deep: true })

export const HANDLERS = {
  [authTypes.LOGOUT_USER_SUCCEEDED]: clearStore,
  [authTypes.LOGIN_USER_SUCCEEDED]: clearStore,
  [waypointsTypes.RESET_WAYPOINTS]: clearStore,

  [depthTypes.GET_DEPTH_LAYER_DATA_SUCCEES]: getDepthLayerDataSuccess,
  [depthTypes.GET_DEPTH_LAYER_DATA_FAILED]: getDepthLayerDataError,

  [depthTypes.UPDATE_DIRECTIONS]: updateDirections,
}

export default createReducer(INITIAL_STATE, HANDLERS)
