import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import Button from '../Common/Button/Button'
import FormInput from '../Forms/FormInput'
import BackEndErrorMessage from '../Common/Errors/BackEndErrorMessage/BackEndErrorMessage'

const validate = Yup.object().shape({
  email: Yup.string().email().trim().lowercase().required('Email required'),
  name: Yup.string().required('Name required'),
  company: Yup.string().required('Company required'),
  password: Yup.string().required('No password provided')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'Password must contain latin characters and digits'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords don\'t match')
    .required('No password confirmation provided')
    .min(8, 'Password is too short, should be 8 chars minimum.')
})

const SignUp = ({ handlers: {onSignUpUser, onCancel}, errorText }) => (
  <div className="auth-form sign-up-form">
    <div className="auth-form-layout auth-form-header">Sign Up</div>
    <div className="auth-form-layout">
      {errorText && <BackEndErrorMessage>{errorText}</BackEndErrorMessage>}
      <Formik
        initialValues={{ name: '', company: '', email: '', password: '', password_confirmation: '' }}
        validationSchema={validate}
        onSubmit={values => onSignUpUser(values)}
      >
        {props => {
          const {
            values: { email, password, name, password_confirmation, company },
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props
          return (
            <Form className="auth-form-sign-up-inputs">
              <FormInput
                label="Name"
                type="text"
                name="name"
                placeholder="User name"
                value={name}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.name}
                isTouched={touched.name}
              />
              <FormInput
                label="Company"
                type="text"
                name="company"
                placeholder="Company name"
                value={company}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.company}
                isTouched={touched.company}
              />
              <FormInput
                label="Email"
                type="text"
                name="email"
                placeholder="email@address.com"
                value={email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.email}
                isTouched={touched.email}
              />
              <FormInput
                label="Password"
                type="password"
                name="password"
                placeholder="••••••••••••••••"
                value={password}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.password}
                isTouched={touched.password}
              />
              <FormInput
                label="Confirm password"
                type="password"
                name="password_confirmation"
                placeholder="••••••••••••••••"
                value={password_confirmation}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.password_confirmation}
                isTouched={touched.password_confirmation}
              />
              <div className="auth-form-sign-up-buttons">
                <Button color="btn-primary btn-right m-t-10" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button type="submit" color="btn-sole btn-right m-t-10" disabled={isSubmitting}>
                  Sign Up
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  </div>
)

SignUp.propTypes = {
  handlers: PropTypes.shape({
    onSignUpUser: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }),
  errorText: PropTypes.string.isRequired,
  values: PropTypes.any,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default SignUp
