import { call, put, takeLatest } from 'redux-saga/effects'
import { purgeStoredState } from 'redux-persist'

import { deleteLocalItem } from '../../helpers/auth'
import { REDUX_PERSIST } from '../../config/ReduxPersist'

import * as appTypes from '../types/app.types'
import * as authActions from '../actions/auth.actions'

export function* backToAuth() {
  yield put(authActions.logoutSucceessed())
  yield call(deleteLocalItem, 'token')
  yield call(deleteLocalItem, 'persist:root')
  yield call(purgeStoredState, REDUX_PERSIST.storeConfig)
}

export default [takeLatest(appTypes.ERROR_401, backToAuth)]
