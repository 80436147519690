import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { depths } from '../../constants/depths'
import { types } from '../../constants/layers'
import { calculateLeft } from '../../helpers/depthViewUtils'
import { drawBezierCurve } from '../../helpers/bezierCurve'
import { colorize, createGradient, createMockGradient } from '../../helpers/gradients'
import { convertMeasure } from '../../helpers/waypointsUtils'

class DepthViewBody extends Component {
  static propTypes = {
    layersList: PropTypes.array.isRequired,
    waypoints: PropTypes.array.isRequired,
    depthData: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.canvasRef = canvas => {
      if (canvas) {
        const { clientWidth, clientHeight } = canvas.parentNode
        canvas.width = clientWidth
        canvas.height = clientHeight
        this.canvas = canvas
      }
    }
    this.depthCanvasRef = canvas => {
      if (canvas) {
        const { clientWidth, clientHeight } = canvas.parentNode
        canvas.width = clientWidth
        canvas.height = clientHeight
        this.depthCanvas = canvas
      }
    }
  }

  componentDidMount() {
    const { layersList } = this.props
    const activeLayer = layersList.find(({ active, type, depth }) => depth && active && type === types.gradient)
    this._createDepthLines()
    if (activeLayer && !activeLayer.loading) {
      this._createHeatmapView(activeLayer)
    }
  }

  componentDidUpdate() {
    const { layersList } = this.props
    const activeLayer = layersList.find(({active, type, loading, depth}) => depth && active && !loading && type === types.gradient)

    if (!activeLayer) {
      let context = this.canvas.getContext('2d')
      context.clearRect(0, 0, this.canvas.width, this.canvas.height)

      context = this.depthCanvas.getContext('2d')
      context.clearRect(0, 0, this.depthCanvas.width, this.depthCanvas.height)
      this._createDepthLines()
    } else {
      if (!activeLayer.loading) {
        this._createHeatmapView(activeLayer)
      }
    }
  }

  _calculateGradientWidth() {
    const { depthData } = this.props
    const { width } = this.canvas

    const distanceList = []
    let distance = 0

    for (let i = 1; i < depthData.length; i++) {
      const dist = convertMeasure(depthData[i - 1].latitude, depthData[i - 1].longitude, depthData[i].latitude, depthData[i].longitude)
      distanceList.push(distance + dist)
      distance += dist
    }

    if (distanceList.length === 0) {
      return [ width ]
    } else if (distanceList.length === 1) {
      return [ width / 2, width]
    } else {
      return [
        width * (0.05 + (distanceList[0] / distance) / 2),
        ...distanceList.map((dist, index) => {
          if (index === distanceList.length - 1) {
            return width
          } else {
            return width * ((distanceList[index + 1] / distance) / 2 + (dist / distance) / 2)
          }
        })
      ]
    }
  }

  _createHeatmapView(activeLayer) {
    const { depthData } = this.props
    const depthsLength = depths.length - 1
    /* Array of depth control index */
    let depthMaxHeightsList = []
    const calcOpactity = (value, max, min) => Math.min(Math.max(((parseFloat(value) - parseFloat(min)) / (parseFloat(max) - parseFloat(min))), 0), 1)
    if (!activeLayer || !depthData || !depthData.length || !this.canvas) {
      return
    }

    /* Array of points width */
    const { width, height } = this.canvas
    const { max, min, gradient: layerGradient } = activeLayer
    const gradient = createMockGradient(layerGradient)
    const context = this.canvas.getContext('2d')
    context.clearRect(0, 0, width, height)

    let gradientMatrix = depthData.map((depth, i) => {
      depthMaxHeightsList.push([-1])
      let prevValue = 0
      if (!depth) { return depths.map(() => [0], []) }
      const data = depth.data[activeLayer.name]
      return depths.map((key, index) => {
        if (!data || !data[key]) {
          return prevValue
        }
        const value = data[key].value
        let alpha = prevValue
        if (value >= min && value <= max) {
          alpha = calcOpactity(value, max, min)
          prevValue = alpha
        } else {
          if (depthMaxHeightsList[i] < 0) {
            depthMaxHeightsList[i] = index
          }
        }
        return alpha
      })
    })

    gradientMatrix = gradientMatrix.map((dradient, index) => {
      if (dradient[0] === 0) {
        if (index === gradientMatrix.length - 1) {
          return gradientMatrix[gradientMatrix.length - 2]
        }
        return gradientMatrix[index + 1][0] === 0 ? gradientMatrix[index - 1] : gradientMatrix[index + 1]
      }
      return dradient
    })

    /* Create vertical gradients */
    const widths = this._calculateGradientWidth()
    gradientMatrix.forEach((waypoint, index) => {
      if (Array.isArray(waypoint)) {
        const gradient = waypoint.reduce((opacity, alpha, i) => ({ ...opacity, [i / depthsLength]: `rgba(0, 0, 0, ${alpha})` }), {})
        createGradient(this.canvas, true, gradient, index === 0 ? 0 : widths[index - 1], widths[index])
      }
    })

    const colored = context.getImageData(0, 0, width, height)
    colorize(colored.data, gradient)
    context.putImageData(colored, 0, 0)

    this._createDepth([ depthsLength + 1, depthMaxHeightsList[0], ...depthMaxHeightsList, depthMaxHeightsList[depthMaxHeightsList.length - 1], depthsLength + 1 ])
  }

  _createDepth(depthMaxHeightsList) {
    const { depthData } = this.props
    const { width, height } = this.depthCanvas
    const context = this.depthCanvas.getContext('2d')
    context.clearRect(0, 0, width, height)
    const depthsLength = depths.length
    const entryPointLeftList = calculateLeft(depthData)

    const depth = depthMaxHeightsList.map((depthIndex, index) => {
      let x = 0
      switch (true) {
        case index === 1: x = 0; break
        case index === 0: x = 0; break
        case index === depthMaxHeightsList.length - 1: x = width; break
        case index === depthMaxHeightsList.length - 2: x = width; break
        default: x = width / 100 * entryPointLeftList[index - 2]; break
      }
      if (depthIndex < 0) {
        return { x, y: height }
      }
      if (index === 1 || index === depthMaxHeightsList.length - 2) {
        return { x, y: depthIndex === 0 ? 0 : height * depthIndex / depthsLength + 20 }
      }

      return { x, y: height * depthIndex / depthsLength }
    })

    context.beginPath()
    context.fillStyle = '#214360'
    drawBezierCurve(context, depth, true)
    this._createDepthLines()
  }

  _createDepthLines() {
    const { waypoints } = this.props
    const entryPointLeftList = calculateLeft(waypoints)
    const { width, height } = this.depthCanvas
    const context = this.depthCanvas.getContext('2d')

    context.strokeStyle = '#ffffff'
    context.lineWidth = 1
    entryPointLeftList.forEach(position => {
      const x = width / 100 * position
      context.beginPath()
      context.moveTo(x, 0)
      context.lineTo(x, height)
      context.stroke()
    })
  }

  render() {
    return (
      <Fragment>
        <canvas className="heatmap-canvas" ref={this.canvasRef} />
        <canvas className="depth-canvas" ref={this.depthCanvasRef} />
      </Fragment>
    )
  }
}

export default DepthViewBody
