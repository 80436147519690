/**
 * Saving token into localStorage
 * @param token
 */
export function setLocalItem(token) {
  localStorage.setItem('token', token)
}

/**
 * Getting element from localStorage by key
 * @param key
 * @returns {string}
 */
export function getLocalItem(key) {
  return localStorage.getItem(key)
}

/**
 * Deleting element in localStorage by key
 * @param key
 */
export function deleteLocalItem(key) {
  localStorage.removeItem(key)
}
