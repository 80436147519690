import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import Button from '../../components/Common/Button/Button'
import { uniqueId } from '../../helpers/lib'
import SimpleBar from 'simplebar-react'
import WaypointsSwitches from './WaypointsSwitches/WaypointsSwitches'
import subMenu from '../../higher-order-components/SubMenu/SubMenu'
import * as waypointsActions from '../../store/actions/waypoints.actions'
import PropTypes from 'prop-types'

class Waypoints extends Component {
  static propTypes = {
    getWaypoints: PropTypes.func.isRequired,
    deleteWaypoints: PropTypes.func.isRequired,
    createWaypoints: PropTypes.func.isRequired,
    switchesWaypoints: PropTypes.func.isRequired,
    waypointsList: PropTypes.array,
    waypoints: PropTypes.array,
  }

  componentDidMount() {
    this.props.getWaypoints()
  }

  render() {
    const { waypointsList, deleteWaypoints, createWaypoints, switchesWaypoints, waypoints } = this.props
    return (
      <div className="waypoints-menu">
        <h3 className="waypoints-menu__header">Waypoints</h3>
        <SimpleBar className="waypoints-menu__simple-bar">
          <div className="waypoints-menu__body">
            {waypointsList && waypointsList.map(({name, ...rest}) => (
              <WaypointsSwitches
                {...rest}
                key={uniqueId()}
                onDeleteClick={deleteWaypoints}
                onSwitchesClick={switchesWaypoints}
              >{name}</WaypointsSwitches>
            ))}
          </div>
        </SimpleBar>
        <div className="waypoints-menu__footer">
          <Button
            size="btn-flexible"
            color="btn-sapphire"
            onClick={() => waypoints && waypoints.length ? createWaypoints() : false}
          >+ Save New Waypoint</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ waypointsStore: { waypointsList, waypoints } }) => ({ waypointsList, waypoints: waypoints.filter(({ hide = false }) => !hide) })
const mapDispatchToProps = (dispatch) => ({
  getWaypoints: () => dispatch(waypointsActions.getWaypoints()),
  deleteWaypoints: (id) => dispatch(waypointsActions.deleteWaypoints(id)),
  createWaypoints: () => dispatch(waypointsActions.createWaypoints()),
  switchesWaypoints: (id, points, active) => dispatch(waypointsActions.switchesWaypoints(id, points, active)),
})
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subMenu(Waypoints))
)
