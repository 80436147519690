import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'

class ZoomControl extends Component {
  state = { zoom: DEFAULT_VIEWPORT.defaultZoom }
  static propTypes = {
    zoom: PropTypes.number.isRequired,
    onZoomChange: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { zoom } = this.props
    this.setState({ ...this.state, zoom: zoom })
  }

  componentDidUpdate(prevProps) {
    const { zoom: prevZoom } = prevProps
    const { zoom } = this.props

    if (prevZoom !== zoom) {
      this.setState({ ...this.state, zoom })
    }
  }

  onChange = (zoom) => {
    this.setState({ ...this.state, zoom })
    this.props.onZoomChange(zoom)
  }

  render() {
    const { zoom } = this.state
    const { defaultZoom, minZoom, maxZoom, zoomSnap } = DEFAULT_VIEWPORT
    return (
      <div className="zoom-control">
        <div className="zoom-control__slider">
          <button
            onClick={() => zoom + zoomSnap <= maxZoom ? this.onChange(zoom + zoomSnap) : false}
            className="zoom-control__zoom-in"
          >+</button>
          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={zoomSnap}
            vertical={true}
            defaultValue={defaultZoom}
            onChange={(zoom) => this.setState({ ...this.state, zoom })}
            onAfterChange={this.props.onZoomChange}
          />
          <button
            onClick={() => zoom - zoomSnap >= minZoom ? this.onChange(zoom - zoomSnap) : false}
            className="zoom-control__zoom-out"
          >-</button>
        </div>
      </div>
    )
  }
}

export default ZoomControl
