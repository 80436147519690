import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { mapIcons } from '../../constants/images'
import { authorizedMenu } from '../../constants/menu'
import { uniqueId } from '../../helpers/lib'

import * as userActions from '../../store/actions/user.actions'
import * as authActions from '../../store/actions/auth.actions'

import subMenu from '../../higher-order-components/SubMenu/SubMenu'

class Profile extends Component {
  profileRoutesUniqueId = uniqueId()

  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    authorized: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getUserInfo()
  }

  render() {
    const { onLogout, history, authorized, user } = this.props
    return (
      <div className="profile-menu">
        {this.renderHeader(user)}
        {this.renderBody()}
        {this.renderSignOut(onLogout, history, authorized)}
      </div>
    )
  }

  renderHeader = ({ name = 'User', email = 'jsdfsdhf@sad.sdr' }) => (
    <div className="profile-menu-header">
      <span className="profile-menu-greet">Welcome</span>
      <span className="profile-menu-name">{name}</span>
      <span className="profile-menu-email">{email}</span>
    </div>
  )

  renderBody = () => {
    const { location: { pathname } } = this.props
    return (
      <ul className="profile-menu-body">
        {
          authorizedMenu[0].profileRoutes.map(({title, path}, index) => {
            const newPath = path.slice(1)
            const newPathName = pathname.slice(1)
            const checkCurrentPath = pathname === path || (newPathName.includes(newPath) && newPath !== '')
            return (
              <Link key={`${this.profileRoutesUniqueId}${index}`} to={path} >
                <li className={`${checkCurrentPath && 'active-sub-link'}`}>{title}</li>
              </Link>
            )
          })
        }
      </ul>
    )
  }

  renderSignOut = (onLogout, history) => {
    return (
      <a
        className="sign-out-button"
        onClick={async () => {
          await onLogout()
          await history.push('/auth')
        }}
      >
        <img className="sign-out-icon bg-icon" src={mapIcons.sidebar.logout} />
        <img className="sign-out-icon icon-hover" src={mapIcons.sidebar.logout_hover} />
        <span>Sign Out</span>
      </a>
    )
  }
}

const mapStateToProps = state => {
  const { authorized } = state.authStore
  const { user } = state.userStore
  return { authorized, user }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(authActions.logoutUser()),
  getUserInfo: () => dispatch(userActions.getUserInfoRequested()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(subMenu(Profile)),
)
