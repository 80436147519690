import {
  LOGIN_USER_REQUESTED,
  LOGIN_USER_SUCCEEDED,
  LOGIN_USER_FAILED,
  CLEAR_ERROR_TXT,
  LOGOUT_USER_SUCCEEDED,
  LOGOUT_USER_REQUESTED,
  LOGOUT_USER_FAILED,
  SIGN_UP_USER_REQUESTED,
  SIGN_UP_USER_SUCCEEDED,
  SIGN_UP_USER_FAILED,
  CONFIRM_USER_REGISTRATION_REQUESTED,
  CONFIRM_USER_REGISTRATION_SUCCEEDED,
  CONFIRM_USER_REGISTRATION_FAILED,
  SET_LOGIN_VIEW,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCEEDED,
  RESET_PASSWORD_FAILED,
  SEND_RESET_PASSWORD_TOKEN_REQUESTED,
  SEND_RESET_PASSWORD_TOKEN_SUCCEEDED,
  SEND_RESET_PASSWORD_TOKEN_FAILED,
  PASSWORD_CHANGE_AFTER_RESET_REQUESTED,
  PASSWORD_CHANGE_AFTER_RESET_SUCCEEDED,
  PASSWORD_CHANGE_AFTER_RESET_FAILED,
  SET_CHANGE_PASSWORD_VIEW,
} from '../types/auth.types'

export const loginUser = credentials => ({ type: LOGIN_USER_REQUESTED, payload: credentials })
export const loginSucceessed = token => ({ type: LOGIN_USER_SUCCEEDED, payload: token })
export const loginFailed = error => ({ type: LOGIN_USER_FAILED, payload: error })

export const logoutUser = () => ({ type: LOGOUT_USER_REQUESTED, payload: null })
export const logoutSucceessed = () => ({ type: LOGOUT_USER_SUCCEEDED, payload: null })
export const logoutFailed = error => ({ type: LOGOUT_USER_FAILED, payload: error })

export const signUpUser = credentials => ({ type: SIGN_UP_USER_REQUESTED, payload: credentials })
export const signUpSucceessed = data => ({ type: SIGN_UP_USER_SUCCEEDED, payload: data })
export const signUpFailed = error => ({ type: SIGN_UP_USER_FAILED, payload: error })

export const confirmUserSignUp = token => ({ type: CONFIRM_USER_REGISTRATION_REQUESTED, payload: token })
export const confirmUserSignUpSucceessed = data => ({ type: CONFIRM_USER_REGISTRATION_SUCCEEDED, payload: data })
export const confirmUserSignUpFailed = error => ({ type: CONFIRM_USER_REGISTRATION_FAILED, payload: error })

export const clearError = () => ({ type: CLEAR_ERROR_TXT, payload: null })
export const setLoginView = () => ({ type: SET_LOGIN_VIEW, payload: null })
export const setChangePasswordView = () => ({ type: SET_CHANGE_PASSWORD_VIEW, payload: null })

export const resetPassword = credentials => ({ type: RESET_PASSWORD_REQUESTED, payload: credentials })
export const resetPasswordSucceessed = data => ({ type: RESET_PASSWORD_SUCCEEDED, payload: data })
export const resetPasswordFailed = error => ({ type: RESET_PASSWORD_FAILED, payload: error })

export const sendResetPasswordToken = token => ({ type: SEND_RESET_PASSWORD_TOKEN_REQUESTED, payload: token })
export const sendResetPasswordTokenSucceessed = data => ({ type: SEND_RESET_PASSWORD_TOKEN_SUCCEEDED, payload: data })
export const sendResetPasswordTokenFailed = error => ({ type: SEND_RESET_PASSWORD_TOKEN_FAILED, payload: error })

export const passwordChangeAfterReset = credentials => ({ type: PASSWORD_CHANGE_AFTER_RESET_REQUESTED, payload: credentials })
export const passwordChangeAfterResetSucceessed = data => ({ type: PASSWORD_CHANGE_AFTER_RESET_SUCCEEDED, payload: data })
export const passwordChangeAfterResetFailed = error => ({ type: PASSWORD_CHANGE_AFTER_RESET_FAILED, payload: error })