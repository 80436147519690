import {
  GET_USER_INFO_SUCCEEDED,
  GET_USER_INFO_REQUESTED,
  GET_USER_INFO_FAILED,
  PROFILE_INFO_CHANGE_REQUESTED,
  PROFILE_INFO_CHANGE_SUCCEEDED,
  PROFILE_INFO_CHANGE_FAILED,
} from '../types/user.types'

export const getUserInfoRequested = () => ({ type: GET_USER_INFO_REQUESTED, payload: null })
export const getUserInfoSucceessed = data => ({ type: GET_USER_INFO_SUCCEEDED, payload: data })
export const getUserInfoFailed = error => ({ type: GET_USER_INFO_FAILED, payload: error })

export const profileChangeRequested = form => ({ type: PROFILE_INFO_CHANGE_REQUESTED, payload: form })
export const profileChangeSucceessed = data => ({ type: PROFILE_INFO_CHANGE_SUCCEEDED, payload: data })
export const profileChangeFailed = error => ({ type: PROFILE_INFO_CHANGE_FAILED, payload: error })

