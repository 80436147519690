import { convertMeasure } from './waypointsUtils'

export function calculateLeft(waypoints) {
  const distances = []
  let totalDistance = 0

  for (let i = 1; i < waypoints.length; i++) {
    const dist = convertMeasure(waypoints[i - 1].latitude, waypoints[i - 1].longitude, waypoints[i].latitude, waypoints[i].longitude)
    distances.push(totalDistance + dist)
    totalDistance += dist
  }

  if (distances.length === 0) {
    return [50]
  } else if (distances.length === 1) {
    return [25, 75]
  } else {
    return [5, ...distances.map((dist, index) => {
      return index === distances.length - 1 ? 95 : dist / totalDistance * 100
    })]
  }
}
