export const formInputsList = {
  name: {
    label: 'Username',
    type: 'text',
    placeholder: 'User name',
  },
  email: {
    label: 'Email Address',
    type: 'text',
    placeholder: 'email@address.com',
  },
  company: {
    label: 'Company Name',
    type: 'text',
    placeholder: 'Company Name',
  }
}

export const profileSettings = [
  {
    label: 'Temperature Units',
    setting: 'temperature',
    values: [
      {
        value: 'celsius',
        label: 'Celsius',
        convert: (value) => value - 273.15,
        unit: '°C'
      },
      {
        value: 'kelvin',
        label: 'Kelvin',
        convert: (value) => value,
        unit: '°K'
      },
      {
        value: 'fahrenheit',
        label: 'Fahrenheit',
        convert: (value) => (value - 273.15) * 9 / 5 + 32,
        unit: '°F'
      },
    ],
  },
  {
    label: 'Distance Units',
    setting: 'distance',
    values: [
      {
        value: 'NM',
        label: 'NM',
        convert: (value) => value / 1852,
        unit: 'Nautical Miles'
      },
      {
        value: 'km',
        label: 'km',
        convert: (value) => value / 1000,
        unit: 'Kilometers'
      },
      {
        value: 'mi',
        label: 'mi',
        convert: (value) => value / 1609.344,
        unit: 'Miles'
      },
    ],
  },
  {
    label: 'Pressure Units',
    setting: 'pressure',
    values: [
      {
        value: 'mm',
        label: 'mm',
        convert: (value) => value / 133.322
      },
      {
        value: 'inches',
        label: 'in',
        convert: (value) => value / 3386.389
      },
      {
        value: 'Pa',
        label: 'Pa',
        convert: (value) => value
      },
      {
        value: 'hPa',
        label: 'hPa',
        convert: (value) => value / 100
      },
      {
        value: 'kPa',
        label: 'kPa',
        convert: (value) => value / 1000
      },
    ],
  },
  {
    label: 'Velocity Units',
    setting: 'velocity',
    values: [
      {
        value: 'km/h',
        label: 'km/h'
      },
      {
        value: 'm/s',
        label: 'm/s'
      },
      {
        value: 'mph',
        label: 'mph'
      },
      {
        value: 'knots',
        label: 'kt'
      },
    ],
  },
  {
    label: 'Delimiter Settings',
    setting: 'delimiter',
    values: [
      {
        value: 'dot',
        label: 'dot',
        convert: (value) => value.replace(',', '.')
      },
      {
        value: 'comma',
        label: 'comma',
        convert: (value) => value.replace('.', ',')
      },
    ]
  },
  {
    label: 'Wave/Sea Level Height',
    setting: 'height',
    values: [
      {
        value: 'm',
        label: 'm',
        convert: (value) => value,
        unit: 'm'
      },
      {
        value: 'ft',
        label: 'ft',
        convert: (value) => Math.round(value * 3.28084),
        unit: 'ft'
      },
    ]
  }
]

export const applicationsOptions = [
  { value: 'navi', label: 'Navigation' },
  { value: 'scooby', label: 'Scooby Dooooo' },
]
