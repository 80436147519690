import React from 'react'
import PropTypes from 'prop-types'

import ErrorMessage from '../Errors/ErrorMessage/ErrorMessage'

const TextArea = ({
  label,
  value,
  handleChange,
  handleBlur,
  name,
  placeholder = '',
  errorText = '',
  isTouched = false,
}) => {
  return (
    <div className="text-area-wrapper">
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`form-control text-area ${value !== '' ? 'input-not-empty' : ''} ${errorText && isTouched ? 'error' : 'text-input' }`}
      />
      {errorText && isTouched && <ErrorMessage message={errorText} classes="text-area-error"/>}
    </div>
  )
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  isTouched: PropTypes.bool,
}

export default TextArea
