import React from 'react'
import PropTypes from 'prop-types'

const ScreenLoader = ({message}) => (
  <div data-html2canvas-ignore="true" className="screen-loader">
    <div className="screen-loader-content">
      <span>{message}</span>
      <div className="loading-dots">
        <div className="loading-dots-dot"/>
        <div className="loading-dots-dot"/>
        <div className="loading-dots-dot"/>
      </div>
    </div>
  </div>
)

ScreenLoader.propTypes = {
  message: PropTypes.string.isRequired
}

export default ScreenLoader