import { apply, call, put, takeLatest } from 'redux-saga/effects'
import { purgeStoredState } from 'redux-persist'

import { REDUX_PERSIST } from '../../config/ReduxPersist'
import statusHelper from '../../helpers/statusHelper'
import { setLocalItem, deleteLocalItem } from '../../helpers/auth'
import { post, get, postWithoutToken, getWithoutToken } from '../../helpers/fetch'

import * as authTypes from '../types/auth.types'
import * as userTypes from '../types/user.types'

import * as authActions from '../actions/auth.actions'
import * as userActions from '../actions/user.actions'

export function* loginUser({payload}) {
  try {
    const response = yield call(post, '/auth/login', payload)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(!!json.message && json.message.includes('Unauthorized') ? 'Invalid email or password' : json.message)
    }
    const { access_token } = json
    setLocalItem(access_token)
    yield put(authActions.loginSucceessed(json))
  } catch (e) {
    yield put(authActions.loginFailed(e.message))
  }
}

export function* getUserInfo() {
  try {
    const response = yield call(get, '/auth/user')
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    yield put(userActions.getUserInfoSucceessed(json))
  } catch (e) {
    yield put(userActions.getUserInfoFailed(e.message))
  }
}

export function* logoutUser() {
  try {
    const response = yield call(post, '/auth/logout')
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json)
    }
    yield put(authActions.logoutSucceessed())
  } catch (e) {
    yield put(authActions.logoutFailed(e.message))
  } finally {
    yield call(deleteLocalItem, 'token')
    yield call(deleteLocalItem, 'persist:root')
    yield call(purgeStoredState, REDUX_PERSIST.storeConfig)
  }
}

export function* signUpUser({payload}) {
  try {
    const response = yield call(post, '/auth/signup', payload)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      const { errors } = json
      throw new Error(errors[Object.keys(errors)[0]])
    }
    yield put(authActions.signUpSucceessed(json))
  } catch (e) {
    yield put(authActions.signUpFailed(e.message))
  }
}

export function* confirmUserRegistration({payload}) {
  try {
    const response = yield call(get, `/auth/signup/activate/${payload}`)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    yield put(authActions.confirmUserSignUpSucceessed(json))
  } catch (e) {
    yield put(authActions.confirmUserSignUpFailed(e.message))
  }
}

export function* resetPassword({payload}) {
  try {
    const response = yield call(postWithoutToken, '/auth/password/create', payload)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    yield put(authActions.resetPasswordSucceessed(json.message))
  } catch (e) {
    yield put(authActions.resetPasswordFailed(e.message))
  }
}

export function* sendResetPasswordToken({payload}) {
  try {
    const response = yield call(getWithoutToken, `/auth/password/find/${payload}`)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    yield put(authActions.sendResetPasswordTokenSucceessed(json))
  } catch (e) {
    yield put(authActions.sendResetPasswordTokenFailed(e.message))
  }
}

export function* passwordChangeAfterReset({payload}) {
  try {
    const response = yield call(postWithoutToken, '/auth/password/reset', payload)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.errors.token)
    }
    yield put(authActions.passwordChangeAfterResetSucceessed(json))
  } catch (e) {
    yield put(authActions.passwordChangeAfterResetFailed(e.message))
  }
}

export default [
  takeLatest(userTypes.GET_USER_INFO_REQUESTED, getUserInfo),
  takeLatest(authTypes.LOGIN_USER_SUCCEEDED, getUserInfo),
  takeLatest(authTypes.LOGIN_USER_REQUESTED, loginUser),
  takeLatest(authTypes.LOGOUT_USER_REQUESTED, logoutUser),
  takeLatest(authTypes.SIGN_UP_USER_REQUESTED, signUpUser),
  takeLatest(authTypes.CONFIRM_USER_REGISTRATION_REQUESTED, confirmUserRegistration),
  takeLatest(authTypes.RESET_PASSWORD_REQUESTED, resetPassword),
  takeLatest(authTypes.SEND_RESET_PASSWORD_TOKEN_REQUESTED, sendResetPasswordToken),
  takeLatest(authTypes.PASSWORD_CHANGE_AFTER_RESET_REQUESTED, passwordChangeAfterReset),
]
