import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { createGradient } from '../../helpers/gradients'
import { mapIcons } from '../../constants/images'

class DepthExpand extends Component {
  static propTypes = {
    authorized: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.canvasRef = canvas => {
      if (canvas) {
        const { clientWidth, clientHeight } = canvas.parentNode
        canvas.width = clientWidth
        canvas.height = clientHeight
        this.canvas = canvas
      }
    }
  }

  componentDidMount() { createGradient(this.canvas, true) }

  render() {
    const { authorized } = this.props

    return (
      <div className="depth-expand">
        <div className="depth-expand__header">
          <span className="depth-expand__title">Depth</span>
          {authorized && <Link to={'/depth'} className="depth-expand__expand-button">Expand</Link>}
        </div>
        <div className={`depth-expand__body ${!authorized && 'depth-expand__body--lock'}`}>
          <canvas ref={this.canvasRef} />
          <img className="depth-expand__mock-relief" src={mapIcons.depth.relief} />

          <span className="depth-expand__body-line" />
          <span className="depth-expand__body-line" />
          <span className="depth-expand__body-line" />
          <span className="depth-expand__body-line" />
          <span className="depth-expand__body-line" />
          <span className="depth-expand__body-line" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ authStore: { authorized } }) => ({ authorized })
export default connect(mapStateToProps)(DepthExpand)
