import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { config } from '../../helpers/config'
import { createGradient } from '../../helpers/gradients'
import { getCurrentSetting } from '../../helpers/settings'

class Legend extends Component {
  static propTypes = {
    activeLayer: PropTypes.object.isRequired,
    className: PropTypes.string,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.canvasRef = canvas => {
      if (canvas) {
        const {clientWidth, clientHeight} = canvas.parentNode
        canvas.width = clientWidth
        canvas.height = clientHeight
        this.canvas = canvas
      }
    }
  }

  componentDidMount() {
    const { activeLayer: { gradient } } = this.props
    createGradient(this.canvas, false, gradient)
  }

  /**
   * Calculate average value if min and max is number
   * @return {Number || String}
   */
  calcAverageValue = (min, max) => {
    if ((typeof min !== 'number' && typeof min !== 'string') || (typeof max !== 'number' && typeof max !== 'string')) {
      return ''
    }
    return (parseFloat(min) + parseFloat(max)) / 2
  }

  render() {
    const { activeLayer, className = '', user } = this.props
    if (!activeLayer) { return null }
    const { title, iconDark } = activeLayer

    return (
      <div className={`legend ${className}`}>
        <div className="legend-content">
          <div className="legend-header">
            <span>Legend:</span>
            <div className="legend-title">
              <span className="legend-title-img" style={{backgroundImage: `url(${config.baseUrl}${iconDark})`}}/>
              <span className="legend-title-name">{title}</span>
            </div>
          </div>
          <div className="legend-body">
            <canvas ref={this.canvasRef} />
          </div>
          {this._renderFooter(activeLayer, user)}
        </div>
      </div>
    )
  }

  _renderFooter = (activeLayer, user) => {
    const { min, max, unit_type, unit_type_legend } = activeLayer
    if (unit_type === 'temperature' || unit_type === 'height') {
      const setting = getCurrentSetting(unit_type, user)
      return (
        <div className="legend-footer">
          <span>{Math.floor(setting.convert(parseFloat(min)))} {setting.unit}</span>
          <span>{Math.round(setting.convert(this.calcAverageValue(min, max)))} {setting.unit}</span>
          <span>{Math.ceil(setting.convert((parseFloat(max))))} {setting.unit}</span>
        </div>
      )
    }
    return (
      <div className="legend-footer">
        <span>{Math.floor(parseFloat(min))} {unit_type_legend}</span>
        <span>{Math.round(this.calcAverageValue(min, max))} {unit_type_legend}</span>
        <span>{Math.ceil(parseFloat(max))} {unit_type_legend}</span>
      </div>
    )
  }
}

const mapStateToProps = ({ layersStore: { layersList }, userStore: { user } }) => ({ layersList, user })
export default connect(mapStateToProps)(Legend)
