import React, { Component } from 'react'
import Select, { components } from 'react-select'
import PropTypes from 'prop-types'

import { mapIcons } from '../../../constants/images'

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <img className="caret caret-up" src={mapIcons.caretDown} />
      ) : (
        <img className="caret" src={mapIcons.caretDown} />
      )}
    </components.DropdownIndicator>
  )

const DropdownIndicatorLight = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <img className="caret caret-up" src={mapIcons.caretDownLight} />
      ) : (
        <img className="caret" src={mapIcons.caretDownLight} />
      )}
    </components.DropdownIndicator>
  )

class FormSelect extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    disabled: PropTypes.bool,
  }

  handleChange = value => {
    const { name, handleChange } = this.props
    // this is going to call setFieldValue and manually update values.topcis
    handleChange && handleChange(name, value)
  }

  handleBlur = () => {
    const { name, handleBlur } = this.props
    // this is going to call setFieldTouched and manually update touched.topcis
    handleBlur && handleBlur(name, true)
  }

  render() {
    const {
      options,
      classes = '',
      label,
      name,
      type,
      value,
      classNamePrefix = 'select-input',
      disabled = false,
    } = this.props

    const selectProps = {
      id: name,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      className: `${disabled ? 'input-disabled' : ''}`,
      isSearchable: false,
      placeholder: value,
      value,
      name,
      options,
      isDisabled: disabled,
      classNamePrefix,
      components: type && type === 'dark' ? { DropdownIndicator: DropdownIndicatorLight } : { DropdownIndicator },
    }

    if (type && type === 'dark') {
      return (
        <div className={`form-select form-select-dark ${classes}`}>
          <label htmlFor={name}>{label}</label>
          <Select {...selectProps}  />
        </div>
      )
    }

    return (
      <div className={`form-select ${classes}`}>
        <label htmlFor={name}>{label}</label>
        <Select {...selectProps} />
      </div>
    )
  }
}

export default FormSelect
