/**
 * Fetching snapshots
 */

export const FETCH_SNAPSHOTS_REQUESTED = 'fetch_snapshots_requested'
export const FETCH_SNAPSHOTS_SUCCEEDED = 'fetch_snapshots_succeeded'
export const FETCH_SNAPSHOTS_FAILED = 'fetch_snapshots_failed'

/**
 * Uploading snapshots
 */

export const UPLOAD_SNAPSHOT_REQUESTED = 'upload_snapshot_requested'
export const UPLOAD_SNAPSHOT_SUCCEEDED = 'upload_snapshot_succeeded'
export const UPLOAD_SNAPSHOT_FAILED = 'upload_snapshot_failed'

/**
 * Uploading snapshots
 */

export const DELETE_SNAPSHOT_REQUESTED = 'delete_snapshot_requested'
export const DELETE_SNAPSHOT_SUCCEEDED = 'delete_snapshot_succeeded'
export const DELETE_SNAPSHOT_FAILED = 'delete_snapshot_failed'
