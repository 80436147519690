import PropTypes from 'prop-types'
import React from 'react'
import EditAvatar from '../EditAvatar/EditAvatar'
import FormInput from '../../Forms/FormInput'
import { formInputsList } from '../../../constants/options'

const ProfileInformation = (props) => {
  const { avatar, handlers, errors, touched, information } = props
  const { handleUploadAvatar, handleChange, handleBlur } = handlers
  return (
    <div className="profile-information">
      <div className="sub-title">Information</div>
      <div className="profile-information__inputs">
        {information && Object.keys(information).map((key) => {
          if (!formInputsList[key]) { return null }
          return (
            <FormInput
              key={key}
              label={formInputsList[key].label}
              type={formInputsList[key].type || 'text'}
              name={key}
              placeholder={formInputsList[key].placeholder}
              value={information[key]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorText={errors[key]}
              isTouched={touched[key]}
            />
          )
        })}
      </div>
      <EditAvatar
        avatar={avatar}
        uploadAvatar={handleUploadAvatar}
      />
    </div>
  )
}

ProfileInformation.propTypes = {
  avatar: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  handlers: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  information: PropTypes.object.isRequired,
}

export default ProfileInformation
