import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

const profileMenuScreen = Component => {
  class ProfileMenuScreen extends React.Component {
    render() {
      return (
        <div className="profile-menu-screen">
          <SimpleBar className="profile-menu-screen__scroll-bar" data-simplebar-force-visible={'y'}>
            <div className="profile-menu-screen-content">
              <Link className="close" to="/profile"/>
              <Component {...this.props} />
            </div>
          </SimpleBar>
        </div>
      )
    }
  }
  ProfileMenuScreen.displayName = `ProfileMenuScreen(${Component.displayName || Component.name || 'Component'})`
  return withRouter(ProfileMenuScreen)
}

export default profileMenuScreen
