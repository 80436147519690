import React  from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { mapIcons } from '../../../constants/images'

const WaypointsCounter = ({ waypoints, authorized }) => (
  waypoints && (waypoints.length || waypoints.length === 0) ? (
    <div className="waypoints-counter">
      <img className="waypoints-counter__icon" src={mapIcons.timeline.flag} />
      <span className="waypoints-counter__value">{waypoints.length}/{authorized ? '5' : '1'}</span>
    </div>
  ) : null
)

WaypointsCounter.propTypes = {
  waypoints: PropTypes.array,
  authorized: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  waypointsStore: { waypoints },
  authStore: { authorized },
}) => ({ waypoints: waypoints.filter(({ hide = false }) => !hide), authorized })
export default connect(mapStateToProps)(WaypointsCounter)
