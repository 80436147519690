import React from 'react'
import PropTypes from 'prop-types'

const TextInput = ({ name, classes = '', type = 'text', onChange, hasAutoComplete = false, value, ...rest }) => (
  <input
    autoComplete={hasAutoComplete ? 'on' : 'off'}
    className={`form-control ${value !== '' ? 'input-not-empty' : ''} ${classes}`}
    onChange={onChange}
    value={value || ''}
    type={type}
    id={name}
    {...rest}
  />
)

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  hasAutoComplete: PropTypes.bool,
  value: PropTypes.string.isRequired,
  classes: PropTypes.string,
}

export default TextInput
