import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'
import * as Types from '../types/waypoints.types'

export const INITIAL_STATE = Immutable({
  waypoints: [],
  waypointsList: [],
  isError: false,
  activeRouteId: null,
  errorText: '',
})

export const clearStore = () => INITIAL_STATE
export const addHidedWaypoint = (state, { payload }) => state.merge({ waypoints: payload }, { deep: true })
export const addWaypoint = (state, { payload }) => state.merge({
  waypoints: (state.waypoints.length <= 5) ? [...state.waypoints, {...payload}] : [...state.waypoints]
}, { deep: true })
export const deleteWaypointByIndex = (state, { payload: index }) => state.merge({
  waypoints: state.waypoints.filter((waypoint, i) => i !== index)
}, { deep: true })
export const updateWaypointByIndex = (state, { payload: { latitude, longitude, index } }) => state.merge({
  waypoints: state.waypoints.map((waypoint, i) => i !== index ? waypoint : {...waypoint, latitude, longitude})
}, { deep: true })
export const switchesWaypoints = (state, { payload: { id: routeId, points, active } }) => state.merge({
  waypointsList: state.waypointsList.map(({id, active, ...rest}) => {
    return id === routeId && !active ? {...rest, id, active: true} : {...rest, id, active: false}
  }),
  activeRouteId: active ? null : routeId,
  waypoints: active ? [] : points
}, { deep: true })
export const resetWaypoints = (state) => state.merge({
  waypointsList: state.waypointsList.map(({...rest}) => ({...rest, active: false})),
  activeRouteId: null,
  waypoints: []
}, { deep: true })

export const getWaypointsSuccessed = (state, { payload }) => state.merge({ waypointsList: payload, isError: false, errorText: '' }, { deep: true })
export const getWaypointsError = (state, { payload }) => state.merge({ isError: true, errorText: payload}, { deep: true })

export const createWaypointsSuccessed = (state, { payload: { waypoints, id } } ) => state.merge({ waypointsList: waypoints, activeRouteId: id, isError: false, errorText: '' }, { deep: true })
export const createWaypointsError = (state, { payload }) => state.merge({ isError: true, errorText: payload}, { deep: true })

export const deleteWaypointsSuccessed = (state, { payload }) => state.merge({ waypointsList: payload, isError: false, errorText: '' }, { deep: true })
export const deleteWaypointsError = (state, { payload }) => state.merge({ isError: true, errorText: payload }, { deep: true })

export const viewWaypointDataSuccessed = (state, { payload }) => state.merge({ waypoints: payload, isError: false, errorText: '' }, { deep: true })
export const viewWaypointDataError = (state, { payload }) => state.merge({ isError: true, errorText: payload }, { deep: true })

export const HANDLERS = {
  [authTypes.LOGOUT_USER_SUCCEEDED]: clearStore,
  [Types.ADD_WAYPOINT]: addWaypoint,
  [Types.ADD_HIDED_WAYPOINT]: addHidedWaypoint,
  [Types.DELETE_WAYPOINT_BY_INDEX]: deleteWaypointByIndex,
  [Types.UPDATE_WAYPOINT_BY_INDEX]: updateWaypointByIndex,
  [Types.SWITCHES_WAYPOINTS]: switchesWaypoints,
  [Types.RESET_WAYPOINTS]: resetWaypoints,

  [Types.GET_WAYPOINTS_SUCCESSED]: getWaypointsSuccessed,
  [Types.GET_WAYPOINTS_ERROR]: getWaypointsError,

  [Types.CREATE_WAYPOINTS_SUCCESSED]: createWaypointsSuccessed,
  [Types.CREATE_WAYPOINTS_ERROR]: createWaypointsError,

  [Types.DELETE_WAYPOINTS_SUCCESSED]: deleteWaypointsSuccessed,
  [Types.DELETE_WAYPOINTS_ERROR]: deleteWaypointsError,

  [Types.VIEW_WAYPOINT_DATA_SUCCESSED]: viewWaypointDataSuccessed,
  [Types.VIEW_WAYPOINT_DATA_ERROR]: viewWaypointDataError,

  [Types.VIEW_ALL_WAYPOINTS_DATA_SUCCESSED]: viewWaypointDataSuccessed,
  [Types.VIEW_ALL_WAYPOINTS_DATA_ERROR]: viewWaypointDataError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
