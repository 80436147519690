import React from 'react'
import PropTypes from 'prop-types'
import { mapIcons } from '../../../constants/images'
import Button from '../../Common/Button/Button'
import Image from '../../Common/Image/Image'

const EditAvatar = ({ avatar, uploadAvatar }) => (
  <div className="edit-avatar">
    <span className="edit-avatar__header">Profile Image</span>
    <div className="edit-avatar__body">
      <Image
        alt="#"
        className="edit-avatar__img"
        src={avatar}
        defaultSrc={mapIcons.sidebar.avatar}
      />
      <Button
        label="CHOOSE NEW IMAGE"
        type="file"
        classes="btn-primary btn-right m-t-10"
        onClick={uploadAvatar}
      />
    </div>
  </div>
)

EditAvatar.propTypes = {
  avatar: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  uploadAvatar: PropTypes.func,
}

export default EditAvatar
