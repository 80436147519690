import { apply, call, put, takeLatest } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { deleteWithToken, get, postFormData } from '../../helpers/fetch'

import * as snapshotsTypes from '../types/snapshots.types'
import * as snapshotsActions from '../actions/snapshots.actions'
import { successToast, errorToast } from './toast.saga'
import * as loaderActions from '../actions/loader.actions'

export function* getSnapshots() {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Fetching snapshots')), 2000)
  try {
    const response = yield call(get, '/snapshots')
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    const {
      data: { snapshots },
    } = json
    yield put(snapshotsActions.getUserInfoSucceessed(snapshots))
  } catch (e) {
    yield put(snapshotsActions.getUserInfoFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* uploadSnapshot({ payload }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Uploading snapshot')), 2000)
  try {
    const response = yield call(postFormData, '/snapshots', payload)
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      yield call(errorToast, json)
      throw new Error(json.message)
    }
    const {
      data: { snapshots },
    } = json
    yield call(successToast, `A snapshot was saved with the name: ${payload.name}`)
    yield put(snapshotsActions.uploadSnapshotSucceeded(snapshots))
    yield call(getSnapshots)
  } catch (e) {
    yield put(snapshotsActions.uploadSnapshotFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* deleteSnapshot({ payload: id }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Deleting snapshot')), 2000)
  try {
    const response = yield call(deleteWithToken, `/snapshots/${id}`)
    const hasError = statusHelper(response)

    if (hasError) {
      const json = yield apply(response, response.json)
      throw new Error(json.message)
    }
    yield put(snapshotsActions.deleteSnapshotSucceeded(id))
  } catch (e) {
    yield put(snapshotsActions.deleteSnapshotFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export default [
  takeLatest(snapshotsTypes.FETCH_SNAPSHOTS_REQUESTED, getSnapshots),
  takeLatest(snapshotsTypes.UPLOAD_SNAPSHOT_REQUESTED, uploadSnapshot),
  takeLatest(snapshotsTypes.DELETE_SNAPSHOT_REQUESTED, deleteSnapshot),
]
