import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { formattingDate } from '../../../helpers/timelineUtils'
import { timelineMarks } from '../../../constants/timeline'
import TimelineSliderControl from '../TimelineSliderControl/TimelineSliderControl'

class TimelineSlider extends Component {
  state = { value: 0 }
  static propTypes = {
    value: PropTypes.number,
    onValueChange: PropTypes.func,
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    currentTimestamp: PropTypes.string,
    timestamps: PropTypes.array,
    maxTimestampIndex: PropTypes.number
  }

  componentDidMount() {
    this.setState({ ...this.state, value: this.props.value })
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (prevProps.value !== value) {
      this.setState({ ...this.state, value })
    }
  }

  render() {
    const { onValueChange, currentTimestamp, timestamps, maxTimestampIndex, onPrevClick, onNextClick } = this.props
    return (
      <div className="timeline-slider">
        <TimelineSliderControl
          currentTimestamp={currentTimestamp}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        />
        <Slider
          value={this.state.value}
          dots={true}
          min={0}
          max={maxTimestampIndex}
          step={1}
          included={false}
          marks={timelineMarks(formattingDate(timestamps[0]))}
          defaultValue={0}
          onChange={(value) => this.setState({ ...this.state, value })}
          onAfterChange={onValueChange}
        />
      </div>
    )
  }
}

export default TimelineSlider
