import { apply, call, put, takeLatest } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { postFormData } from '../../helpers/fetch'

import * as userTypes from '../types/user.types'
import * as userActions from '../actions/user.actions'
import * as loaderActions from '../actions/loader.actions'
import * as waypointsActions from '../actions/waypoints.actions'

export function* profileChange({ payload: { history, indexOfActiveTimestamp, ...data } }) {
  yield put(loaderActions.showLoader('Saving profile changes'))
  try {
    const response = yield call(postFormData, '/profile/change', { ...data })
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(json.message)
    }
    if (indexOfActiveTimestamp || indexOfActiveTimestamp === 0) {
      yield put(waypointsActions.viewAllWaypointsData(indexOfActiveTimestamp))
    }
    yield put(userActions.profileChangeSucceessed(json))
    if (history && history.location) {
      history.push('/')
    }
  } catch (e) {
    yield put(userActions.profileChangeFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
  }
}

export default [
  takeLatest(userTypes.PROFILE_INFO_CHANGE_REQUESTED, profileChange),
]
