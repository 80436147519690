import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LayersContext } from '../../../constants/layersContext'
import Image from '../../../components/Common/Image/Image'

class LayersListItem extends Component {
  static contextType = LayersContext
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    hideLayer: PropTypes.func.isRequired,
    showLayer: PropTypes.func.isRequired,
  }

  onItemClick = () => {
    const { showLayer, hideLayer, loading, active, name } = this.props
    if (!loading && !active) { showLayer(name) }
    if (active) { hideLayer(name) }
  }

  render() {
    const { title, loading, icon, active } = this.props
    return (
      <div
        className={`layers-list-item ${active && 'layers-list-item--active'}`}
        onClick={this.onItemClick}
      >
        <div className={`layers-list-item__image ${loading && 'layers-list-item__image--loading'}`}>
          {icon && <Image src={icon} />}
        </div>
        <span className="layers-list-item__name" title={title}>{title}</span>
      </div>
    )
  }
}

export default LayersListItem
