import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import Button from '../Common/Button/Button'
import FormInput from '../Forms/FormInput'
import BackEndErrorMessage from '../Common/Errors/BackEndErrorMessage/BackEndErrorMessage'

const validate = Yup.object().shape({
  email: Yup.string().email().trim().lowercase().required('Email required'),
  password: Yup.string().trim().required('Password required'),
})

const Login = ({ handlers: { onLogin, onRegister, onForgot }, errorText }) => (
  <div className="auth-form">
    <div className="auth-form-layout auth-form-header">Login</div>
    <div className="auth-form-layout">
      {errorText && <BackEndErrorMessage>{errorText}</BackEndErrorMessage>}
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validate}
        onSubmit={values => onLogin(values)}
      >
        {props => {
          const {
            values: { email, password },
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props
          return (
            <Form className="auth-form-inputs">
              <FormInput
                label="Email"
                type="text"
                name="email"
                placeholder="email@address.com"
                value={email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.email}
                isTouched={touched.email}
              />
              <FormInput
                label="Password"
                type="password"
                name="password"
                placeholder="••••••••••••••••"
                value={password}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.password}
                isTouched={touched.password}
              />
              <div className="forgot-submit">
                <div onClick={() => onForgot()} className="forgot">
                  Forgot Password
                </div>
                <Button type="submit" color="btn-sole" disabled={isSubmitting}>
                  Login
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
    <div className="auth-form-layout register-block">
      <span>Don’t have an account?</span>
      <Button color="btn-primary" onClick={() => onRegister()}>
        Register
      </Button>
    </div>
  </div>
)

Login.propTypes = {
  handlers: PropTypes.shape({
    onLogin: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onForgot: PropTypes.func.isRequired,
  }),
  errorText: PropTypes.string.isRequired,
  values: PropTypes.any,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default Login
