import { Browser, DomUtil, Layer } from 'leaflet'
import { uniqueId } from './lib'

/**
 * Removes canvas overlay for map
 * @param leafletMap (our leaflet maps)
 * @param el (canvas layer from component)
 */
export function safelyRemoveLayer(leafletMap, el) {
  const { overlayPane } = leafletMap.getPanes()
  if (overlayPane && overlayPane.contains(el)) {
    overlayPane.removeChild(el)
  }
}

/**
 * Util for CanvasLayers
 * Create base parameters for layers
 *
 * @param layer (this param) {class}
 * @param className {string}
 * @return {{el: canvas, _CanvasLayer: *}}
 */
export function createCanvsaLayer(layer, className = '') {
  const el = !className ? DomUtil.create('canvas', `leaflet-canvas-${uniqueId()}`) : DomUtil.create('canvas', className)
  const _CanvasLayer = Layer.extend({
    onAdd: (leafletMap) => leafletMap.getPanes().overlayPane.appendChild(el),
    addTo: (leafletMap) => leafletMap.addLayer(layer),
    onRemove: (leafletMap) => safelyRemoveLayer(leafletMap, el)
  })

  const { leaflet: { map: leafletMap } } = layer.props
  const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
  el.width = mapWidth
  el.height = mapHeight
  el.style['transformProp'] = '50% 50%'

  return { el, _CanvasLayer }
}

/**
 * Animate zoom if we can do it
 * @param event: LeafletZoomEvent
 * @param leafletMap: leaflet map layer
 * @param el: canvas
 */
export function animateZoom(event, leafletMap, el) {
  if (!leafletMap.options.zoomAnimation || !Browser.any3d) {
    return
  }

  const scale = leafletMap.getZoomScale(event.zoom)
  const offset = leafletMap._getCenterOffset(event.center)._multiplyBy(-scale).subtract(leafletMap._getMapPanePos())

  if (DomUtil.setTransform) {
    DomUtil.setTransform(el, offset, scale)
  } else {
    el.style[DomUtil.TRANSFORM] = `${DomUtil.getTranslateString(offset)} scale(${scale})`
  }
}

/**
 * Convert server data (back-end) to client data (front-end)
 * @param layerData (layer data from back-end)
 * @return {Object} (layers list)
 */
export function serverToClientLayersData({layers}) {
  return layers.reduce((layerList, {timestamps, ...data}) => ([
    ...layerList,
    {
      ...data,
      loading: false,
      active: false,
      selected: false,
      timestamps: timestamps.map(({ timestamp, bounds }) => {
        const [
          { latitude: startLatitude, longitude: startLongitude },
          { latitude: endLatitude, longitude: endLongitude },
        ] = bounds
        const maxBounds = [
          [parseFloat(startLatitude), parseFloat(startLongitude)],
          [parseFloat(endLatitude), parseFloat(endLongitude)]
        ]
        return { timestamp, bounds: maxBounds }
      })
    }
  ]), [])
}

/**
 * Get all timestamps for timeline
 * @param layers
 * @return {Array} (timstamps list)
 */
export function getTimestampsList({layers}) {
  const layerWithAllTimestamps = layers.find(({ timestamps }) => timestamps.length >= 1)
  if (!layerWithAllTimestamps) {
    return []
  }
  return layerWithAllTimestamps.timestamps.map(({timestamp}) => timestamp).slice(0, 25)
}

/**
 * Get all types for layers
 * @param layers
 * @return {Array} (types list)
 */
export function getTypesList({layers}) {
  return layers.reduce((types, { type }) => {
    if (types.find(typeName => type === typeName)) {
      return types
    }
    return [...types, type]
  }, [])
}

/**
 * Method, that help find layer data
 * @param mapZoom {number}
 * @param mapLayer {object}
 * @return {object} layer data for needed zoom and active timestamp
 * @private
 */
export function getLayerData(mapZoom, mapLayer) {
  if (!mapLayer) {
    return null
  }
  let zoom = 1
  switch (true) {
    case mapZoom <= 7 && mapZoom > 6.5: zoom = 2; break
    case mapZoom <= 6.5 && mapZoom >= 3: zoom = 3; break
    default: zoom = 1; break
  }
  return {
    ...mapLayer.data.find(({ zoom: dataZoom }) => dataZoom === zoom),
    bounds: mapLayer.bounds
  }
}

/**
 * Create rounded rect (without stroke, only fill method)
 * @param context {CanvasRenderingContext2D}
 * @param x {number}
 * @param y {number}
 * @param width {number}
 * @param height {number}
 * @param radius {number}
 */
export function roundRect(context, x, y, width, height, radius = 5) {
  context.beginPath()
  context.moveTo(x + radius, y)
  context.lineTo(x + width - radius, y)
  context.quadraticCurveTo(x + width, y, x + width, y + radius)
  context.lineTo(x + width, y + height - radius)
  context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  context.lineTo(x + radius, y + height)
  context.quadraticCurveTo(x, y + height, x, y + height - radius)
  context.lineTo(x, y + radius)
  context.quadraticCurveTo(x, y, x + radius, y)
  context.closePath()
  context.fill()
}
