import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'

export const INITIAL_STATE = Immutable({
  authorized: false,
  isError: false,
  isCheckEmail: false,
  isEmailVerified: false,
  isEmailSent: false,
  isPasswordChanged: false,
  errorText: '',
  successText: '',
  resetCredentials: null,
})

/**
 * Login actions
 */
export const loginRequest = state => state.merge({ isError: false, authorized: false, errorText: '' }, { deep: true })
export const loginSuccess = state => state.merge({ authorized: true }, { deep: true })
export const loginFailure = (state, { payload }) =>
  state.merge({ isError: true, errorText: payload, authorized: false }, { deep: true })

/**
 * Clearing state actions
 */
export const clearError = state =>
  state.merge({ errorText: '', isCheckEmail: false, isEmailVerified: false }, { deep: true })
export const setLoginView = state =>
  state.merge(
    {
      isCheckEmail: false,
      isEmailVerified: false,
      isEmailSent: false,
      resetCredentials: null,
      isPasswordChanged: false,
    },
    { deep: true },
  )

export const setChangePasswordView = state => state.merge({ isEmailSent: false, successText: '' }, { deep: true })

/**
 * Logout actions
 */
export const logoutRequest = state => state.merge({ isError: false, errorText: '' }, { deep: true })
export const logoutSuccess = state => state.merge({ authorized: false }, { deep: true })
export const logoutFailure = (state, { payload }) => state.merge({ isError: true, errorText: payload }, { deep: true })

/**
 * SignUp actions
 */
export const signUpRequest = state =>
  state.merge({ isError: false, errorText: '', isCheckEmail: false }, { deep: true })
export const signUpSuccess = state => state.merge({ isCheckEmail: true }, { deep: true })
export const signUpFailure = (state, { payload }) =>
  state.merge({ isError: true, errorText: payload, isCheckEmail: false }, { deep: true })

export const confirmUserEmailRequest = state =>
  state.merge({ isEmailVerified: false, isError: false, errorText: '' }, { deep: true })
export const confirmUserEmailSuccess = state => state.merge({ isEmailVerified: true }, { deep: true })
export const confirmUserEmailFailure = (state, { payload }) =>
  state.merge({ isEmailVerified: false, isError: true, errorText: payload }, { deep: true })

/**
 * Forgot password actions
 */
export const resetPassword = state =>
  state.merge(
    { isEmailSent: false, isError: false, errorText: '', successText: '', resetCredentials: null },
    { deep: true },
  )
export const resetPasswordSuccess = (state, { payload }) =>
  state.merge({ isEmailSent: true, successText: payload }, { deep: true })
export const resetPasswordFailure = (state, { payload }) =>
  state.merge({ isEmailSent: false, isError: true, errorText: payload, successText: '' }, { deep: true })

export const sendResetPasswordToken = state =>
  state.merge({ isError: false, errorText: '', resetCredentials: null }, { deep: true })
export const sendResetPasswordTokenSuccess = (state, { payload }) =>
  state.merge({ resetCredentials: payload }, { deep: true })
export const sendResetPasswordTokenFailure = (state, { payload }) =>
  state.merge({ isError: true, errorText: payload, resetCredentials: null }, { deep: true })

export const passwordChangeAfterReset = state =>
  state.merge({ isError: false, errorText: '', isPasswordChanged: false }, { deep: true })
export const passwordChangeAfterResetSuccess = state =>
  state.merge({ isPasswordChanged: true, resetCredentials: null }, { deep: true })
export const passwordChangeAfterResetFailure = (state, { payload }) =>
  state.merge({ isError: true, errorText: payload, isPasswordChanged: false }, { deep: true })

export const HANDLERS = {
  [authTypes.LOGIN_USER_REQUESTED]: loginRequest,
  [authTypes.LOGIN_USER_SUCCEEDED]: loginSuccess,
  [authTypes.LOGIN_USER_FAILED]: loginFailure,

  [authTypes.CLEAR_ERROR_TXT]: clearError,

  [authTypes.LOGOUT_USER_REQUESTED]: logoutRequest,
  [authTypes.LOGOUT_USER_SUCCEEDED]: logoutSuccess,
  [authTypes.LOGIN_USER_FAILED]: logoutFailure,

  [authTypes.SIGN_UP_USER_REQUESTED]: signUpRequest,
  [authTypes.SIGN_UP_USER_SUCCEEDED]: signUpSuccess,
  [authTypes.SIGN_UP_USER_FAILED]: signUpFailure,

  [authTypes.SET_LOGIN_VIEW]: setLoginView,
  [authTypes.SET_CHANGE_PASSWORD_VIEW]: setChangePasswordView,

  [authTypes.CONFIRM_USER_REGISTRATION_REQUESTED]: confirmUserEmailRequest,
  [authTypes.CONFIRM_USER_REGISTRATION_SUCCEEDED]: confirmUserEmailSuccess,
  [authTypes.CONFIRM_USER_REGISTRATION_FAILED]: confirmUserEmailFailure,

  [authTypes.RESET_PASSWORD_REQUESTED]: resetPassword,
  [authTypes.RESET_PASSWORD_SUCCEEDED]: resetPasswordSuccess,
  [authTypes.RESET_PASSWORD_FAILED]: resetPasswordFailure,

  [authTypes.SEND_RESET_PASSWORD_TOKEN_REQUESTED]: sendResetPasswordToken,
  [authTypes.SEND_RESET_PASSWORD_TOKEN_SUCCEEDED]: sendResetPasswordTokenSuccess,
  [authTypes.SEND_RESET_PASSWORD_TOKEN_FAILED]: sendResetPasswordTokenFailure,

  [authTypes.PASSWORD_CHANGE_AFTER_RESET_REQUESTED]: passwordChangeAfterReset,
  [authTypes.PASSWORD_CHANGE_AFTER_RESET_SUCCEEDED]: passwordChangeAfterResetSuccess,
  [authTypes.PASSWORD_CHANGE_AFTER_RESET_FAILED]: passwordChangeAfterResetFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
