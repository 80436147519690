import AuthReducer from './auth.reducer'
import UserReducer from './user.reducer'
import SnapshotsReducer from './snapshots.reducer'
import LayersReducer from './layers.reducer'
import waypointsReducer from './waypoints.reducer'
import LoaderReducer from './loader.reducer'
import DepthReducer from './depth.reducer'

/**
 * Easy way to combine reducers.
 */
export default {
  authStore: AuthReducer,
  userStore: UserReducer,
  snapshotsStore: SnapshotsReducer,
  layersStore: LayersReducer,
  waypointsStore: waypointsReducer,
  loaderStore: LoaderReducer,
  depthStore: DepthReducer,
}
