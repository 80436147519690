/**
 * Function that converts a params object to a string for requests
 * @param params {Object}
 * @return {string}
 */
export const convertHttpParamsToQueryString = (params = {}) => {
  const hasParams = !!Object.keys(params).length
  let queryString = hasParams ? '?' : ''
  if (hasParams) {
    const paramNames = Object.keys(params)
    for (const paramName of paramNames) {
      const paramValue = params[paramName]
      if (paramValue !== undefined) {
        queryString += `${queryString.length > 1 ? '&' : ''}${paramName}=${paramValue}`
      }
    }
  }

  return queryString
}

/**
 * Function that converts a query string to a params object
 * @param query {string}
 * @return {Object}
 */
export const convertQueryStringToHttpParams = (query = '') => {
  let httpParams = Object.create(null)

  query = query.trim().replace(/^[?#&]/, '')
  if (!query) {
    return httpParams
  }

  for (const param of query.split('&')) {
    const [key, value] = param.replace(/\+/g, ' ').split('=')
    httpParams = {...httpParams, [key]: value}
  }

  return httpParams
}
