import { DomUtil } from 'leaflet'
import PropTypes from 'prop-types'
import { MapLayer, withLeaflet } from 'react-leaflet'
import { safelyRemoveLayer, createCanvsaLayer, animateZoom, getLayerData } from '../../helpers/layersUtils'
import { createMockGradient, colorize } from '../../helpers/gradients'
import { LayersContext } from '../../constants/layersContext'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'

class HeatMapOverlay extends MapLayer {
  /* Data for drawing */
  _gradient
  _circle
  _heatMap
  _zoom = DEFAULT_VIEWPORT.zoom

  /* Data for set up drawing */
  _radius = 0

  /* Drawing area restrictions */
  _maxValue = 300
  _nx = 401
  _ny = 402

  static contextType = LayersContext
  static propTypes = {
    heatMapLayer: PropTypes.object.isRequired,
    gradient: PropTypes.object,
    layerName: PropTypes.string.isRequired,
    indexOfActiveTimestamp: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  }

  componentDidMount() {
    this.initComponent()
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    const { layerName, indexOfActiveTimestamp } = this.props
    if (prevProps.layerName !== layerName) {
      this.initComponent()
    } else if (prevProps.indexOfActiveTimestamp !== indexOfActiveTimestamp) {
      this.initialize()
      this.reset()
    }
  }

  componentWillUnmount() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    safelyRemoveLayer(leafletMap, this._el)
  }

  initComponent() {
    if (!this.leafletElement || !this._el) {
      const { el, _CanvasLayer } = createCanvsaLayer(this, 'heatmap-layer')
      this._el = el
      this.leafletElement = new _CanvasLayer()
    }
    const { min, max, gradient } = this.props
    this._gradient = createMockGradient(gradient)

    this._maxValue = max - min

    this.initialize()
    this.attachEvents()

    this.reset()
  }

  /**
   * Initialize component data and restrictions
   */
  initialize() {
    const { heatMapLayer } = this.props
    this.layer = getLayerData(this._zoom, heatMapLayer)
    if (!this.layer) {
      return
    }
    const { bounds, data, nx, ny, dx } = this.layer
    if (!data || !data.length || !bounds) {
      return
    }
    const {
      leaflet: { map: leafletMap }
    } = this.props
    this.layerData = data
    const pointerDensity = parseFloat(dx)

    const { x: x1 } = leafletMap.latLngToContainerPoint([bounds[1][0], bounds[1][1]])
    const { x: x2 } = leafletMap.latLngToContainerPoint([bounds[1][0] - pointerDensity, bounds[1][1] + pointerDensity])

    this._radius = Math.abs(x1 - x2) / 2
    this._ny = ny
    this._nx = nx
  }

  /**
   * Adds events for calling reDraw method
   */
  attachEvents() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.on('moveend', () => this.reset())
    leafletMap.on('zoomanim', (event) => animateZoom(event, leafletMap, this._el), this)
  }

  /**
   * Resets canvas data and starts reDraw function for update layer view
   */
  reset() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    const zoom = leafletMap.getZoom()
    if (this._zoom !== zoom) {
      this._zoom = zoom
      this.initialize()
    }

    const topLeft = leafletMap.containerPointToLayerPoint([0, 0])
    DomUtil.setPosition(this._el, topLeft)

    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    this._el.width = mapWidth
    this._el.height = mapHeight

    if (!this.layer || !this.layerData) {
      return
    }

    if (this._radius > 0) {
      this.draw()
    }
  }

  /**
   * Draws a heat map from prepared data
   */
  draw() {
    const {
      leaflet: { map: leafletMap }
    } = this.props
    const bounds = leafletMap.getBounds()

    this._createCircle()
    const context = this._el.getContext('2d')
    context.clearRect(0, 0, this._el.width, this._el.height)

    this.layerData.forEach(({ lat, lng, value }) => {
      if (bounds.contains([lat, lng])) {
        const { x, y } = leafletMap.latLngToContainerPoint([lat, lng])
        const opacity = Math.min(Math.max((value / this._maxValue), 0.1), 1)

        this.drawPoint(context, { x, y, opacity })
      }
    })

    const colored = context.getImageData(0, 0, this._el.width, this._el.height)
    colorize(colored.data, this._gradient)
    context.putImageData(colored, 0, 0)
  }

  drawPoint(context, point) {
    context.globalAlpha = point.opacity
    context.drawImage(this._circle, point.x - this._radius, point.y - this._radius)
  }

  /**
   * Draws a source circle with a shadow to fill
   * From remote canvas
   * @private
   */
  _createCircle() {
    if (!this._circle) {
      this._circle = this._createCanvas()
    }
    const context = this._circle.getContext('2d')

    this._circle.width = this._circle.height = this._radius * 2
    context.clearRect(0, 0, this._circle.width, this._circle.height)

    context.fillStyle = 'black'
    context.beginPath()
    context.arc(this._radius, this._radius, this._radius, 0, Math.PI * 2)
    context.fill()
  }

  /**
   * Draw heat map overlay in "virtual" canvas. Then we will can add Tile generation
   * @private
   */
  _createHeatMap() {
    if (!this._heatMap) {
      this._heatMap = this._createCanvas()
    }
    const context = this._heatMap.getContext('2d')
    this._heatMap.width = this._el.width
    this._heatMap.height = this._el.height
    context.clearRect(0, 0, this._heatMap.width, this._heatMap.height)
  }
}

HeatMapOverlay.prototype.shouldComponentUpdate = () => (true)
HeatMapOverlay.prototype.createLeafletElement = () => (null)
HeatMapOverlay.prototype.render = () => (null)
HeatMapOverlay.prototype._createCanvas = () => document.createElement('canvas')
export default withLeaflet(HeatMapOverlay)
