import html2canvas from 'html2canvas'

/**
 * Generating unique id
 * @returns {string}
 */
export const uniqueId = () => {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

/**
 * Get Url value after regex value
 * @param regex
 * @returns {string}
 */
export const getUrlValue = (regex) => {
  const url = window.location.href
  const match = url.match(regex)
  return match ? match[1] : ''
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

/**
 * Generate file to upload
 * @returns {Canvas<Blob>}
 */
export const generateCanvasBlob = async () => {
  let canvasBlob = null
  const body = document.body

  await html2canvas(body, {
    imageTimeout: 0,
    foreignObjectRendering: true,
    allowTaint: true,
    useCORS: true,
    width: body.offsetWidth,
    height: body.offsetHeight,
    scale: 1
  }).then(canvas => {
    document.body.appendChild(canvas)
    // Generate the base64 representation of the canvas
    const base64image = canvas.toDataURL('image/png')

    // Split the base64 string in data and contentType
    const block = base64image.split(';')
    // Get the content type
    const mimeType = block[0].split(':')[1] // In this case "image/png"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1] // For example:  iVBORw0KGgouqw23....

    // Convert b64 to blob and store it into a constiable (with real base64 as value)
    canvasBlob = b64toBlob(realData, mimeType)
  })
  return canvasBlob
}
