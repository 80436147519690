import {
  FETCH_SNAPSHOTS_REQUESTED,
  FETCH_SNAPSHOTS_SUCCEEDED,
  FETCH_SNAPSHOTS_FAILED,
  UPLOAD_SNAPSHOT_REQUESTED,
  UPLOAD_SNAPSHOT_SUCCEEDED,
  UPLOAD_SNAPSHOT_FAILED,
  DELETE_SNAPSHOT_REQUESTED,
  DELETE_SNAPSHOT_SUCCEEDED,
  DELETE_SNAPSHOT_FAILED,
} from '../types/snapshots.types'

export const getUserInfoRequested = () => ({ type: FETCH_SNAPSHOTS_REQUESTED, payload: null })
export const getUserInfoSucceessed = data => ({ type: FETCH_SNAPSHOTS_SUCCEEDED, payload: data })
export const getUserInfoFailed = error => ({ type: FETCH_SNAPSHOTS_FAILED, payload: error })

export const uploadSnapshotRequested = (image, name) => ({ type: UPLOAD_SNAPSHOT_REQUESTED, payload: {image, name} })
export const uploadSnapshotSucceeded = data => ({ type: UPLOAD_SNAPSHOT_SUCCEEDED, payload: data })
export const uploadSnapshotFailed = error => ({ type: UPLOAD_SNAPSHOT_FAILED, payload: error })

export const deleteSnapshotRequested = (id) => ({ type: DELETE_SNAPSHOT_REQUESTED, payload: id })
export const deleteSnapshotSucceeded = id => ({ type: DELETE_SNAPSHOT_SUCCEEDED, payload: id })
export const deleteSnapshotFailed = error => ({ type: DELETE_SNAPSHOT_FAILED, payload: error })

