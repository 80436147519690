import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { mapIcons } from '../../../constants/images'

class Checkbox extends Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
  }

  onInputChange(event) {
    const checked = event.target.checked
    this.props.onChange(checked)
  }

  render() {
    const { children, classes = '', checked, disabled, name } = this.props

    const input = (
      <div className="checkbox-input-wrapper">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={event => this.onInputChange(event)}
          disabled={disabled}
        />
        <div className={checked ? 'checkbox-bar-checked' : 'checkbox-bar'}>
          <div className={checked ? 'checkbox-circle-checked' : 'checkbox-circle'}/>
        </div>
      </div>
    )
    const label = <span className="checkbox-label">{children}</span>
    let elements = [input, label]
    if(disabled) elements.push(<img className="checkbox-lock-icon" src={mapIcons.applications.lock} alt="$#"/>)

    return (
      <label className={`checkbox ${classes} ${checked ? 'checkbox-checked' : ''}`} style={disabled && {opacity: 0.2}}>
        {Children.map(elements, element => element)}
      </label>
    )
  }
}

export default Checkbox
