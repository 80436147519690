import {
  ADD_WAYPOINT,
  ADD_HIDED_WAYPOINT,
  DELETE_WAYPOINT_BY_INDEX,
  UPDATE_WAYPOINT_BY_INDEX,
  GET_WAYPOINTS,
  GET_WAYPOINTS_SUCCESSED,
  GET_WAYPOINTS_ERROR,
  CREATE_WAYPOINTS,
  CREATE_WAYPOINTS_SUCCESSED,
  CREATE_WAYPOINTS_ERROR,
  DELETE_WAYPOINTS,
  DELETE_WAYPOINTS_SUCCESSED,
  DELETE_WAYPOINTS_ERROR,
  SWITCHES_WAYPOINTS,
  VIEW_WAYPOINT_DATA,
  VIEW_WAYPOINT_DATA_SUCCESSED,
  VIEW_WAYPOINT_DATA_ERROR,
  VIEW_ALL_WAYPOINTS_DATA,
  VIEW_ALL_WAYPOINTS_DATA_SUCCESSED,
  VIEW_ALL_WAYPOINTS_DATA_ERROR,
  RESET_WAYPOINTS,
} from '../types/waypoints.types'

export const addWaypoint = (latitude, longitude) => ({ type: ADD_WAYPOINT, payload: { latitude, longitude } })
export const addHidedWaypoint = (waypoints) => ({ type: ADD_HIDED_WAYPOINT, payload: waypoints })
export const updateWaypointByIndex = (latitude, longitude, index) => ({ type: UPDATE_WAYPOINT_BY_INDEX, payload: { latitude, longitude, index } })
export const deleteWaypointByIndex = (index) => ({ type: DELETE_WAYPOINT_BY_INDEX, payload: index })
export const switchesWaypoints = (id, points, active) => ({ type: SWITCHES_WAYPOINTS, payload: { id, points, active } })
export const resetWaypoints = () => ({ type: RESET_WAYPOINTS })

export const getWaypoints = () => ({ type: GET_WAYPOINTS })
export const getWaypointsSuccessed = (waypoints) => ({ type: GET_WAYPOINTS_SUCCESSED, payload: waypoints })
export const getWaypointsError = (error) => ({ type: GET_WAYPOINTS_ERROR, payload: error })

export const createWaypoints = () => ({ type: CREATE_WAYPOINTS })
export const createWaypointsSuccessed = (waypoints, id) => ({ type: CREATE_WAYPOINTS_SUCCESSED, payload: { waypoints, id } })
export const createWaypointsError = (error) => ({ type: CREATE_WAYPOINTS_ERROR, payload: error })

export const viewWaypointData = (latitude, longitude, index, indexOfActiveTimestamp) => ({ type: VIEW_WAYPOINT_DATA, payload: { longitude, latitude, index, indexOfActiveTimestamp } })
export const viewWaypointDataSuccessed = (waypoints) => ({ type: VIEW_WAYPOINT_DATA_SUCCESSED, payload: waypoints })
export const viewWaypointDataError = (error) => ({ type: VIEW_WAYPOINT_DATA_ERROR, payload: error })

export const viewAllWaypointsData = (indexOfActiveTimestamp) => ({ type: VIEW_ALL_WAYPOINTS_DATA, payload: indexOfActiveTimestamp })
export const viewAllWaypointsDataSuccessed = (waypoints) => ({ type: VIEW_ALL_WAYPOINTS_DATA_SUCCESSED, payload: waypoints })
export const viewAllWaypointsDataError = (error) => ({ type: VIEW_ALL_WAYPOINTS_DATA_ERROR, payload: error })

export const deleteWaypoints = (id) => ({ type: DELETE_WAYPOINTS, payload: id })
export const deleteWaypointsSuccessed = (waypoints) => ({ type: DELETE_WAYPOINTS_SUCCESSED, payload: waypoints })
export const deleteWaypointsError = (error) => ({ type: DELETE_WAYPOINTS_ERROR, payload: error })
