import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Common/Button/Button'

const successLayout = (onLoginPage, successText, headerText = 'Success!') => (
  <div className="success-form">
    <div className="success-form-layout success-form-header">{headerText}</div>
    <div className="success-form-layout success-form-body">
      <span className="success-text">{successText}</span>
      <Button type="submit" color="btn-sole btn-right m-t-10" onClick={() => onLoginPage()}>
        OK
      </Button>
    </div>
  </div>
)

const SuccessForm = ({ onLoginPage, isEmailVerified, isEmailSent, isPasswordChanged, successText }) => {
  if (isPasswordChanged) {
    return successLayout(onLoginPage, 'Your password successfully changed! Now you can log in.')
  } else if (isEmailSent && successText) {
    return successLayout(onLoginPage, successText)
  } else if (isEmailVerified) {
    return successLayout(onLoginPage, 'Your email address successfully confirmed! Now you can log in.')
  } else return successLayout(onLoginPage, 'Please, check your email and verify your email address.', 'Confirm email')
}

SuccessForm.propTypes = {
  onLoginPage: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  isEmailSent: PropTypes.bool.isRequired,
  isPasswordChanged: PropTypes.bool.isRequired,
  successText: PropTypes.string,
}

export default SuccessForm
