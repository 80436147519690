import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import Button from '../Common/Button/Button'
import FormInput from '../Forms/FormInput'
import BackEndErrorMessage from '../Common/Errors/BackEndErrorMessage/BackEndErrorMessage'

const validate = Yup.object().shape({
  email: Yup.string().email().trim().lowercase().required('Email required'),
})

const Forgot = ({ handlers: { onSubmitForgot, onCancel }, errorText }) => (
  <div className="auth-form forgot-form">
    <div className="auth-form-layout auth-form-header">Forgot password</div>
    <div className="auth-form-layout">
      {errorText && <BackEndErrorMessage>{errorText}</BackEndErrorMessage>}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validate}
        onSubmit={values => onSubmitForgot(values)}
      >
        {props => {
          const {
            values: { email },
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props
          return (
            <Form className="auth-form-inputs">
              <FormInput
                label="Email"
                type="text"
                name="email"
                placeholder="email@address.com"
                value={email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errorText={errors.email}
                isTouched={touched.email}
              />
              <div className="auth-form-sign-up-buttons">
                <Button color="btn-primary btn-right m-t-10" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button type="submit" color="btn-sole btn-right m-t-10" disabled={isSubmitting}>
                  Send email
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  </div>
)

Forgot.propTypes = {
  handlers: PropTypes.shape({
    onSubmitForgot: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }),
  errorText: PropTypes.string.isRequired,
  values: PropTypes.any,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

export default Forgot
