import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class AppErrorBoundary extends Component {
  state = { hasError: false }
  static propTypes = {
    children: PropTypes.node
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    return !hasError ? (
      children
    ) : (
      <div className="app-error">
        <div className="comiserate">:-/</div>
        <h1>An unknown error occurred.</h1>
        <p>
          Try refreshing the page or returning <a href="/">home</a>.
        </p>
        <p>
          If this error continues to occur, please contact us at &nbsp;
          <a href="mailto:support@oceannow.com">support@oceannow.com</a>.
        </p>
      </div>
    )
  }
}
