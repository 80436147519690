import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { types } from '../../constants/layers'
import { depths } from '../../constants/depths'

import Legend from '../../components/Legend/Legend'
import { LayersContext } from '../../constants/layersContext'
import { getCurrentSetting } from '../../helpers/settings'
import * as depthActions from '../../store/actions/depth.actions'
import * as layersActions from '../../store/actions/layers.actions'
import DepthViewBody from '../../components/DepthView/DepthViewBody'
import DepthViewLayersList from '../../components/DepthView/DepthViewLayersList'
import DepthViewWaypoints from '../../components/DepthView/DepthViewWaypoints'
import DepthViewRotation from '../../components/DepthView/DepthViewRotation'

class DepthView extends Component {
  static contextType = LayersContext
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,

    waypoints: PropTypes.array.isRequired,
    layersList: PropTypes.array.isRequired,
    depthData: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,

    switchesLayerInActive: PropTypes.func.isRequired,
    showLayerLoading: PropTypes.func.isRequired,
    getDepthLayerData: PropTypes.func.isRequired,
    updateDirections: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { history, waypoints } = this.props
    if (!waypoints || !waypoints.length) { history.push('/') }
  }

  componentDidUpdate() {
    const { waypoints, history } = this.props
    if (!waypoints || !waypoints.length) { history.push('/') }
  }

  onDirectionChange = ({ left, right }) => {
    const { updateDirections, getDepthLayerData } = this.props
    const { indexOfActiveTimestamp, onPauseClick, playTimeline } = this.context
    if (playTimeline) { onPauseClick() }
    updateDirections(left, right)

    getDepthLayerData(indexOfActiveTimestamp)
  }

  render() {
    const { layersList, waypoints, depthData, switchesLayerInActive, showLayerLoading, getDepthLayerData, user } = this.props
    const activeLayer = layersList.find(({ active, type, depth }) => depth && active && type === types.gradient)
    const depthLayers = layersList.filter(({ depth, type }) => depth && type === types.gradient)
    const { indexOfActiveTimestamp } = this.context
    const setting = getCurrentSetting('height', user)

    return (
      <div className="depth-view">
        <Link to={'/'} className="depth-view__close" />
        {waypoints.length === 1 ? <DepthViewRotation
          onDirectionChange={this.onDirectionChange}
        /> : null}
        <div className="depth-view__body">
          <DepthViewWaypoints waypoints={waypoints} />
          <DepthViewLayersList
            switchesLayerInActive={switchesLayerInActive}
            showLayerLoading={showLayerLoading}
            getDepthLayerData={getDepthLayerData}
            layersList={depthLayers}
            indexOfActiveTimestamp={indexOfActiveTimestamp}
          />
          <div className="depth-view__canvas-wrapper">
            <DepthViewBody
              waypoints={waypoints}
              layersList={layersList}
              depthData={depthData}
              indexOfActiveTimestamp={indexOfActiveTimestamp}
            />
          </div>
          {depths && depths.length ? depths.map(depth => (
            <span key={`depth-${depth}`} className="depth-view__depth-meter-line">{setting.convert(depth)} {setting.value}</span>
          )) : null}
        </div>
        {activeLayer && <Legend className={'depth-legend'} activeLayer={activeLayer} />}
      </div>
    )
  }
}

const mapStateToProps = ({
  waypointsStore: { waypoints },
  layersStore: { layersList },
  depthStore: { depthData },
  userStore: { user },
}) => ({ waypoints, layersList, depthData, user })

const mapDispatchToProps = (dispatch) => ({
  switchesLayerInActive: (layerName) => dispatch(layersActions.switchesLayerInActive(layerName)),
  showLayerLoading: (layerName) => dispatch(layersActions.showLayerLoading(layerName)),
  getDepthLayerData: (indexOfActiveTimestamp) => dispatch(depthActions.getDepthLayerData(indexOfActiveTimestamp)),
  updateDirections: (left, right) => dispatch(depthActions.updateDirections(left, right)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DepthView))
