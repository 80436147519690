/**
 * Getting user
 */

export const LOGIN_USER_REQUESTED = 'login_user_requested'
export const LOGIN_USER_SUCCEEDED = 'login_user_succeeded'
export const LOGIN_USER_FAILED = 'login_user_failed'
export const LOGOUT_USER_REQUESTED = 'logout_user_requested'
export const LOGOUT_USER_SUCCEEDED = 'logout_user_succeeded'
export const LOGOUT_USER_FAILED = 'logout_user_failed'
export const SIGN_UP_USER_REQUESTED = 'sign_up_user_requested'
export const SIGN_UP_USER_SUCCEEDED = 'sign_up_user_succeeded'
export const SIGN_UP_USER_FAILED = 'sign_up_user_failed'
export const CONFIRM_USER_REGISTRATION_REQUESTED = 'confirm_user_registration_requested'
export const CONFIRM_USER_REGISTRATION_SUCCEEDED = 'confirm_user_registration_succeeded'
export const CONFIRM_USER_REGISTRATION_FAILED = 'confirm_user_registration_failed'

/**
 * Clear data in authStore
 */

export const CLEAR_ERROR_TXT = 'clear_error'
export const SET_LOGIN_VIEW = 'set_login_view'
export const SET_CHANGE_PASSWORD_VIEW = 'set_change_password_view'

/**
 * Forgot action types
 */
export const RESET_PASSWORD_REQUESTED = 'reset_password_request'
export const RESET_PASSWORD_SUCCEEDED = 'reset_password_succeeded'
export const RESET_PASSWORD_FAILED = 'reset_password_failed'
export const SEND_RESET_PASSWORD_TOKEN_REQUESTED = 'send_reset_password_token_request'
export const SEND_RESET_PASSWORD_TOKEN_SUCCEEDED = 'send_reset_password_token_ucceeded'
export const SEND_RESET_PASSWORD_TOKEN_FAILED = 'send_reset_password_token_failed'
export const PASSWORD_CHANGE_AFTER_RESET_REQUESTED = 'password_change_after_reset_requested'
export const PASSWORD_CHANGE_AFTER_RESET_SUCCEEDED = 'password_change_after_reset_ucceeded'
export const PASSWORD_CHANGE_AFTER_RESET_FAILED = 'password_change_after_reset_failed'