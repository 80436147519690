import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { LayersContext } from '../../constants/layersContext'
import * as userActions from '../../store/actions/user.actions'
import { profileSettings, formInputsList } from '../../constants/options'

import profileMenuScreen from '../../higher-order-components/ProfileMenuScreen/ProfileMenuScreen'
import SubMenuHeader from '../../components/Common/SubMenuHeader/SubMenuHeader'
import Button from '../../components/Common/Button/Button'
import ProfileInformation from '../../components/ProfileEdit/ProfileInformation/ProfileInformation'
import ProfileSettings from '../../components/ProfileEdit/ProfileSettings/ProfileSettings'

const validate = Yup.object().shape({
  email: Yup.string().email().trim().lowercase().required('Email required'),
  name: Yup.string().required('Name required'),
  company: Yup.string().nullable().required('Company required'),
})

class ProfileEdit extends Component {
  static contextType = LayersContext
  static propTypes = {
    user: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool,
    history: PropTypes.object.isRequired,
    onProfileChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.formikRef = (formik) => this.formik = formik
    this.state = { avatar: null }
  }

  componentDidMount() {
    const { user } = this.props
    if (user) {
      this.setState({ avatar: `${user.avatar}` })
    }
  }

  handleUploadAvatar = (photo) => this.setState({ avatar: photo })
  onSaveAndCloseClick = () => {
    const { history } = this.props
    const { values, errors } = this.formik.state
    if (!Object.values(errors).length) {
      this.onSubmit({...values, history})
    }
  }
  onSubmit = (values) => {
    const { avatar } = this.state
    const { history = {} } = values
    const settings = profileSettings.reduce((settings, { setting }) => {
      if (values[setting]) {
        return [...settings, { setting, value: values[setting].value }]
      }
      return settings
    }, [])
    const informations = Object.keys(formInputsList).reduce((informations, key) => ({ ...informations, [key]: values[key] }), {})
    let data = { ...informations, settings }
    if (history && history.location) {
      data = { ...data, history }
    }
    const { indexOfActiveTimestamp } = this.context
    if (typeof avatar === 'object') {
      this.props.onProfileChange({ ...data, avatar, indexOfActiveTimestamp })
    } else {
      this.props.onProfileChange({ ...data, indexOfActiveTimestamp })
    }
  }

  render() {
    const { user: { name, company, email, settings = [] } } = this.props
    const formikSettingsFormat = Array.isArray(settings) && settings.reduce((settings, { setting, value }) => {
      const neededSetting = profileSettings.find(({ setting: name }) => name === setting)
      let label = value
      if (neededSetting && neededSetting.values && neededSetting.values.length) {
        label = neededSetting.values.find(({ value: val }) => val === value).label
      }
      return ({ ...settings, [setting]: { label, value } })
    }, {})
    const initialValues =  { name, company, email, ...formikSettingsFormat }
    return (
      <Formik
        ref={this.formikRef}
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={this.onSubmit}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched }) => {
          const handlers = { handleChange, handleBlur, setFieldValue, setFieldTouched, handleUploadAvatar: (photo) => this.handleUploadAvatar(photo) }
          return (
            <Form className="profile-edit-form">
              <div className="profile-edit">
                <div className="profile-edit__wrapper">
                  <SubMenuHeader>Profile</SubMenuHeader>
                  <div className="profile-edit__form">
                    <ProfileInformation
                      errors={errors}
                      touched={touched}
                      information={values}
                      handlers={handlers}
                      avatar={this.state.avatar}
                    />
                    <ProfileSettings
                      settings={values}
                      handlers={handlers}
                    />
                  </div>
                </div>
                <div className="profile-edit__footer">
                  <Button
                    type="submit"
                    color="btn-sole btn-right m-t-10 m-r-11"
                  >Save</Button>
                  <Button
                    type="button"
                    color="btn-primary btn-right m-t-10"
                    onClick={this.onSaveAndCloseClick}
                  >Save & Close</Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

const mapStateToProps = ({ userStore: { user, isUpdating }}) => ({ user, isUpdating })
const mapDispatchToProps = dispatch => ({ onProfileChange: form => dispatch(userActions.profileChangeRequested(form)) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(profileMenuScreen(ProfileEdit)))
