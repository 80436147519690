import React from 'react'
import PropTypes from 'prop-types'

const WaypointsSwitches = ({ children, onDeleteClick, onSwitchesClick, points, active, id }) => (
  <div className="waypoints-switches">
    <span
      className="waypoints-switches__name"
      onClick={() => onSwitchesClick(id, points, active)}
    >{children}</span>
    {active && <div className="check-icon waypoints-switches__active" />}
    {!active && <div
      className="cross-icon waypoints-switches__delete"
      onClick={() => onDeleteClick(id)}
    />}
  </div>
)

WaypointsSwitches.propTypes = {
  id: PropTypes.number.isRequired,
  points: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onSwitchesClick: PropTypes.func,
}

export default WaypointsSwitches
