import { getLocalItem } from './auth'
import { config } from './config'
import { convertHttpParamsToQueryString } from './httpGetParamsService'

/**
 * GET query
 * @param url {string}
 * @param params
 * @returns {Promise<Response>}
 */
export function get(url, params = {}) {
  const token = getLocalItem('token') || ''
  const queryString = convertHttpParamsToQueryString(params)
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  })

  return fetch(`${config.api}${url}${queryString}`, { method: 'get', headers })
}

/**
 * GET query without Authorization
 * @param url {string}
 * @param params
 * @returns {Promise<Response>}
 */
export function getWithoutToken(url, params = '') {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  })

  return fetch(`${config.api}${url}${params}`, { method: 'get', headers })
}

/**
 * GET query for Image
 * @param url {string}
 * @param type {string} (is type of image)
 * @returns {Promise<Response>}
 */
export async function getImage(url, type = 'image/png') {
  if (!url) { return null }

  const requestUrl = url.startsWith('http') ? url : `${config.baseUrl}${url[0] !== '/' ? '/' + url : url}`
  const reader = new FileReader()

  const responseToBase64 = async (response) => {
    if (typeof response === 'string' && response.startsWith('data:')) {
      return Promise.resolve(response)
    }
    if (!response.ok) {
      return Promise.reject()
    }
    try {
      const blob = await response.blob()
      reader.readAsDataURL(new Blob([blob], { type }))

      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    } catch {
      return Promise.reject()
    }
  }

  return fetch(requestUrl, { method: 'get' })
    .then(responseToBase64)
    .catch(() => {
      const token = getLocalItem('token') || ''
      const headers = new Headers({ Authorization: `Bearer ${token}` })

      return fetch(requestUrl, { method: 'get', headers })
    })
    .then(responseToBase64)
    .catch(() => requestUrl)
}

/**
 * POST query
 * @param url {string}
 * @param body
 * @returns {Promise<Response>}
 */
export function post(url, body = {}) {
  const token = getLocalItem('token') || ''
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  })

  return fetch(`${config.api}${url}`, { method: 'post', headers, body: JSON.stringify(body) })
}

/**
 * POST query without Authorization token
 * @param url {string}
 * @param body
 * @returns {Promise<Response>}
 */
export function postWithoutToken(url, body = {}) {
  const headers = new Headers({ 'Content-Type': 'application/json' })
  return fetch(`${config.api}${url}`, { method: 'post', headers, body: JSON.stringify(body) })
}

/**
 * POST query as formData
 * @param url {string}
 * @param body
 * @returns {Promise<Response>}
 */
export function postFormData(url, body = {}) {
  const token = getLocalItem('token') || ''
  const headers = new Headers({ Authorization: `Bearer ${token}`, Accept: 'application/json' })
  const formData = new FormData()

  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      if (Array.isArray(body[key])) {
        if (body[key].length) {
          body[key].forEach((data, index) => {
            Object.keys(data).forEach(dataKey => formData.append(`${key}[${index}][${dataKey}]`, data[dataKey]))
          })
        }
      } else {
        formData.append(key, body[key])
      }
    }
  }

  return fetch(`${config.api}${url}`, { method: 'post', headers, body: formData })
}

/**
 * DELETE query
 * @param url {string}
 * @returns {Promise<Response>}
 */
export function deleteWithToken(url) {
  const token = getLocalItem('token')
  const headers =  new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  })

  return fetch(`${config.api}${url}`, { method: 'delete', headers })
}
