import { apply, call, put, takeLatest } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { postWithoutToken } from '../../helpers/fetch'

import * as supportTypes from '../types/support.types'
import * as supportActions from '../actions/support.actions'
import * as loaderActions from '../actions/loader.actions'

import { errorToast, successToast } from './toast.saga'

export function* profileChange({payload: {email, message}}) {
  const body = {email, message: message.split('\n').join('<br/>')}
  yield put(loaderActions.showLoader('Sending message'))
  try {
    const response = yield call(postWithoutToken, '/support', body)
    const hasError = statusHelper(response)
    if (hasError) {
      const json = yield apply(response, response.json)
      yield call(errorToast, json)
      throw new Error(json.message)
    }
    yield call(successToast, 'Your message was successfully sent')
    yield put(supportActions.sendSupportMessageSucceeded())
  } catch (e) {
    yield put(supportActions.sendSupportMessageFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
  }
}

export default [
  takeLatest(supportTypes.SEND_SUPPORT_MESSAGE_REQUESTED, profileChange),
]
