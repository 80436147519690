import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { config } from '../../../helpers/config'
import { types } from '../../../constants/layers'
import { MIN_VALUE } from '../../../constants/leaflet.settings'

class WaypointsAccordionItem extends Component {
  static propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    onDeleteWaypointClick: PropTypes.func.isRequired,
    toggleBodyIndex: PropTypes.func.isRequired,
    showingBodyIndex: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object,
    layersList: PropTypes.array.isRequired,
  }

  onDeleteWaypointClick(event) {
    const { onDeleteWaypointClick, index } = this.props
    event.stopPropagation()
    onDeleteWaypointClick(index)
  }

  _renderHeader = ({ latitude, longitude, index, toggleBodyIndex }) => (
    <div className="waypoints-accordion-item__header waypoint-color" onClick={() => toggleBodyIndex(index)}>
      <div className="icon" />
      <div className="lat-lng-value">Lat: <span>{latitude.toFixed(3)}</span></div>
      <div className="lat-lng-value">Lon: <span>{longitude.toFixed(3)}</span></div>
      <div className="cross-icon" onClick={this.onDeleteWaypointClick.bind(this)} />
    </div>
  )

  _renderBody = (data, layersList) => (
    <div className="waypoints-accordion-item__body">
      {Object.keys(data).map(layerName => {
        const currentLayer = layersList.find(({ name }) => layerName === name)
        if (!data[layerName] || !currentLayer) {
          return null
        }

        const { iconDark, title, type } = currentLayer
        let { value, unit } = data[layerName]
        if (!value || value <= MIN_VALUE) {
          return null
        }

        if (type === types.fish) {
          switch (true) {
            case value >= - 3 && value < -1: value = 'Least Likely'; break
            case value >= -1 && value < 0: value = 'Likely'; break
            default: value = 'Most Likely'; break
          }
        } else {
          switch (unit) {
            case 'kelvin': unit = '°K'; break
            case 'celsius': unit = '°C'; break
            case 'fahrenheit': unit = '°F'; break
            default: unit = unit ? unit : ''
          }
          value = `${value} ${unit}`
        }

        return (
          <div key={`bodyOf${layerName}`} className="waypoint-data">
            <div className="waypoint-data__wrapper">
              <span
                className={`waypoint-data__image ${!iconDark && 'waypoint-data__image--blank'}`}
                style={iconDark && {backgroundImage: `url(${config.baseUrl}${iconDark})`}}
              />
              <span className="waypoint-data__name" title={title}>{title}</span>
              <span className="waypoint-data__delimitter" />
              <span className="waypoint-data__value">{value}</span>
            </div>
          </div>
        )})}
    </div>
  )

  render() {
    const { data, layersList, showingBodyIndex, index } = this.props
    return (
      <div className="waypoints-accordion-item">
        {this._renderHeader({...this.props})}
        {showingBodyIndex === index && data && layersList && layersList.length ? this._renderBody(data, layersList) : null}
      </div>
    )
  }
}

export default WaypointsAccordionItem
