import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LayersContext } from '../../constants/layersContext'
import { uniqueId } from '../../helpers/lib'
import { convertMeasure, formatting } from '../../helpers/waypointsUtils'
import { getCurrentSetting } from '../../helpers/settings'
import * as depthActions from '../../store/actions/depth.actions'

import * as waypointsActions from '../../store/actions/waypoints.actions'

import DepthExpand from '../DepthExpand/DepthExpand'
import WaypointsAccordionItem from './WaypointsAccordionItem/WaypointsAccordionItem'

class WaypointsAccordion extends Component {
  state = { showingBodyIndex: 0, distance: 0 }
  static contextType = LayersContext
  static propTypes = {
    waypoints: PropTypes.array.isRequired,
    layersList: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object,

    deleteWaypointByIndex: PropTypes.func.isRequired,
    resetWaypoints: PropTypes.func.isRequired,
    getDepthLayerData: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this._calculateDistance()
  }

  componentDidUpdate() {
    this._calculateDistance()
  }

  onToggleBodyIndexClick(index) {
    if (this.state.showingBodyIndex === index) {
      this.setState({ ...this.state, showingBodyIndex: -999 })
    } else {
      this.setState({ ...this.state, showingBodyIndex: index })
    }
  }

  onDeleteWaypointClick(index) {
    const { deleteWaypointByIndex, waypoints, getDepthLayerData } = this.props
    const { indexOfActiveTimestamp } = this.context
    if (waypoints && waypoints.length > 0) {
      deleteWaypointByIndex(index)
      getDepthLayerData(indexOfActiveTimestamp)

      if (this.state.showingBodyIndex === index || waypoints.length === 1) {
        const showingBodyIndex = waypoints.length === 1 ? 0 : -999
        this.setState({ ...this.state, showingBodyIndex })
      }
    }
  }

  _calculateDistance() {
    const { waypoints } = this.props
    const { distance: prevDistance } = this.state
    if (waypoints.length > 1) {
      let distance = 0
      for (let i = 1; i < waypoints.length; i++) {
        distance += convertMeasure(waypoints[i - 1].latitude, waypoints[i - 1].longitude, waypoints[i].latitude, waypoints[i].longitude)
      }
      if (distance !== prevDistance) {
        this.setState({ ...this.state, distance })
      }
    } else {
      if (prevDistance !== 0) {
        this.setState({...this.state, distance: 0})
      }
    }
  }

  render() {
    const { waypoints, layersList, location: { pathname }, resetWaypoints, user } = this.props
    const { showingBodyIndex, distance } = this.state
    const setting = getCurrentSetting('distance', user)

    return (
      <div className="waypoints-accordion">
        {waypoints && waypoints.length ? waypoints.map((rest, index) => (
          <WaypointsAccordionItem
            {...rest}
            key={uniqueId()}
            index={index}
            showingBodyIndex={showingBodyIndex}
            layersList={layersList}
            toggleBodyIndex={(index) => this.onToggleBodyIndexClick(index)}
            onDeleteWaypointClick={(index) => this.onDeleteWaypointClick(index)}
          />
        )) : null}
        {distance && distance !== 0 ? <div className="nautical-miles">
          <span className="nautical-miles__value">{formatting(setting.convert(distance), 2, user)} {setting.unit}</span>
          <button className="nautical-miles__button" onClick={resetWaypoints}>Reset</button>
        </div> : null}
        {waypoints && waypoints.length && pathname !== '/depth' ? <DepthExpand /> : null}
      </div>
    )
  }
}

const mapStateToProps = ({
  layersStore: { layersList },
  waypointsStore: { waypoints },
  userStore: { user }
}) => ({ waypoints: waypoints.filter(({ hide = false }) => !hide), layersList, user })
const mapDispatchToProps = (dispatch) => ({
  deleteWaypointByIndex: (index) => dispatch(waypointsActions.deleteWaypointByIndex(index)),
  resetWaypoints: () => dispatch(waypointsActions.resetWaypoints()),
  getDepthLayerData: (indexOfActiveTimestamp) => dispatch(depthActions.getDepthLayerData(indexOfActiveTimestamp)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WaypointsAccordion))
