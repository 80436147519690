import { profileSettings } from '../constants/options'

export function getCurrentSetting(settingName, user = {}) {
  const { values } = profileSettings.find(({ setting }) => setting === settingName)
  let setting = values[0]
  if (!user || !user.settings) {
    return setting
  }
  if (Array.isArray(user.settings)) {
    const unitType = user.settings.find(({ setting }) => setting === settingName)
    if (unitType) {
      setting = values.find(({ value }) => unitType.value === value)
    }
  }
  return setting
}
