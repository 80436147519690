import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Button extends  Component {
  static propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    type: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
    classes: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = { isHover: false }
  }

  onChange(e) {
    this.props.onClick(e.target.files[0])
  }

  render() {
    const { children, type = 'button',  size = '', color = '', classes = '', onClick, label } = this.props
    const buttonLabel = label ? <span className={`btn-text ${type === 'file' ? 'file-text' : ''}`}>{label}</span> : null

    if(type === 'file') {
      return (
        <label
          htmlFor="file-upload"
          className={`btn ${color} ${size} ${classes} upload-button`}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
        >
          {buttonLabel}
          <input
            id="file-upload"
            className="upload-button-file"
            type={type}
            onChange={this.onChange}
            ref={ref => (this.input = ref)}
          />
        </label>
      )
    }

    return (
      <button type={type} onClick={onClick} className={`btn ${color} ${size} ${classes}`}>
        {children}
      </button>
    )
  }
}

export default Button
