import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LayersContext } from '../../constants/layersContext'
import TimelineControl from './TimelineControl/TimelineControl'
import TimelineSlider from './TimelineSlider/TimelineSlider'
import WaypointsCounter from './WaypointsCounter/WaypointsCounter'

class Timeline extends Component {
  playFunction = null
  maxTimestampIndex = 24
  state = { value: 0, loop: false }
  static contextType = LayersContext
  static propTypes = {
    layersList: PropTypes.array,
    timestamps: PropTypes.array.isRequired,
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    const { changeIndexOfActiveTimestamp, playTimeline } = this.context
    if (!playTimeline && this.playFunction) {
      clearInterval(this.playFunction)
    }
    if (prevState.value !== value) {
      changeIndexOfActiveTimestamp(value)
    }
  }

  onPlayClick() {
    if (!this.context.playTimeline) {
      this._playFunction()
      this.context.onPlayClick()
      this.playFunction = setInterval(() => this._playFunction(), 2000)
    }
  }

  onPauseClick() {
    this.context.onPauseClick()
  }

  onLoopClick() {
    this.setState({ ...this.state, loop: !this.state.loop })
  }

  onValueChange(value) {
    this.context.onPauseClick()
    this.setState({ ...this.state, value })
  }

  onNextClick() {
    const { value } = this.state
    this.context.onPauseClick()

    if (value === this.maxTimestampIndex) {
      this.setState({ ...this.state, value: 0 })
    } else {
      this.setState({ ...this.state, value: value + 1 })
    }
  }

  onPrevClick() {
    const { value } = this.state
    this.context.onPauseClick()
    if (value === 0) {
      this.setState({ ...this.state, value: this.maxTimestampIndex })
    } else {
      this.setState({ ...this.state, value: value - 1 })
    }
  }

  render() {
    const { timestamps } = this.props
    const { loop, value } = this.state
    const { playTimeline } = this.context
    return (
      <div className="timeline">
        <div className="timeline__wrapper">
          <TimelineControl
            onPlayClick={() => this.onPlayClick()}
            onPauseClick={() => this.onPauseClick()}
            onLoopClick={() => this.onLoopClick()}
            activeLoop={loop}
            activePlay={playTimeline}
          />
          <TimelineSlider
            timestamps={timestamps}
            currentTimestamp={timestamps[value]}
            maxTimestampIndex={this.maxTimestampIndex}
            value={value}
            onPrevClick={() => this.onPrevClick()}
            onNextClick={() => this.onNextClick()}
            onValueChange={(value) => this.onValueChange(value)}
          />
        </div>
        <WaypointsCounter />
      </div>
    )
  }

  _playFunction() {
    const { layersList } = this.props
    const { value, loop } = this.state
    const loadedLayer = layersList.find(({ loading }) => loading)

    if (!loadedLayer) {
      if (value === this.maxTimestampIndex) {
        this.setState({ ...this.state, value: 0 })
      }

      if (value < this.maxTimestampIndex) {
        this.setState({ ...this.state, value: value + 1 })
      } else {
        if (loop) {
          this.setState({ ...this.state, value: 0 })
        } else {
          this.onPauseClick()
        }
      }
    }
  }
}

const mapStateToProps = ({
  layersStore: { timestamps, layersList }
}) => ({ timestamps, layersList })
export default connect(mapStateToProps)(Timeline)
