import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'
import * as Types from '../types/layers.types'

export const INITIAL_STATE = Immutable({
  layersList: [],
  timestamps: [],
  types: [],
  isError: false,
  errorText: '',
})

export const clearStore = () => INITIAL_STATE

export const getLayersList = (state) => state.merge({ isError: false, errorText: '' }, { deep: true })
export const getLayersListSuccess = (state, { payload: { layers, timestamps, types } }) => state.merge({ layersList: layers, timestamps, types }, { deep: true })
export const getLayersListFailure = (state, { payload }) => state.merge({ isError: true, errorText: payload }, { deep: true })

export const showLayerLoading = (state, { payload: layerName }) => state.merge({
  layersList: state.layersList.map(({ name, ...rest }) => layerName === name ? { ...rest, name, loading: true, selected: true, is_default: false } : { ...rest, name })
}, { deep: true })
export const hideLayerLoading = (state, { payload: layerName }) => state.merge({
  layersList: state.layersList.map(({ name, ...rest }) => layerName === name ? { ...rest, name, loading: false } : { ...rest, name })
}, { deep: true })

export const switchesLayerActive = (state, { payload: layerName }) => state.merge({
  layersList: state.layersList.map(({ name, ...rest }) => layerName === name ? { ...rest, name, loading: false, selected: true, active: true } : { ...rest, name })
}, { deep: true })
export const switchesLayerInActive = (state, { payload: layerName }) => state.merge({
  layersList: state.layersList.map(({ name, ...rest }) => layerName === name ? { ...rest, name, selected: false, active: false } : { ...rest, name })
}, { deep: true })

export const HANDLERS = {
  [authTypes.LOGOUT_USER_SUCCEEDED]: clearStore,
  [authTypes.LOGIN_USER_SUCCEEDED]: clearStore,

  [Types.GET_LAYERS_LIST]: getLayersList,
  [Types.GET_LAYERS_LIST_SUCCEEDED]: getLayersListSuccess,
  [Types.GET_LAYERS_LIST_FAILED]: getLayersListFailure,

  [Types.SHOW_LAYER_LOADING]: showLayerLoading,
  [Types.HIDE_LAYER_LOADING]: hideLayerLoading,

  [Types.SWITCHES_LAYER_ACTIVE]: switchesLayerActive,
  [Types.SWITCHES_LAYER_INACTIVE]: switchesLayerInActive,
}

export default createReducer(INITIAL_STATE, HANDLERS)
