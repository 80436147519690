import * as appActions from '../store/actions/app.actions'
import { Store } from '../index'

/**
 * Checking status of response
 * @param res
 * @returns {boolean}
 */

export default function statusHelper(res) {
  if (res.status === 401 && Store.getState().authStore.authorized) {
    Store.dispatch(appActions.unauthorizedUser())
  }
  return !(res.status >= 200 && res.status < 300)
}
