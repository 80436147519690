import { apply, all, call, put, takeLatest, select } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { get, post, deleteWithToken } from '../../helpers/fetch'
import { types as layerTypes } from '../../constants/layers'

import * as types from '../types/waypoints.types'
import * as depthActions from '../actions/depth.actions'
import * as actions from '../actions/waypoints.actions'
import * as loaderActions from '../actions/loader.actions'

export function* getWaypoints() {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Fetching waypoints')), 2000)
  const { waypointsStore: { activeRouteId } } = yield select()
  try {
    const response = yield call(get, '/routes')
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(
        json.message.includes('Unauthorized') ? 'Unauthorized' : json.message,
      )
    }

    const { data: { routes } } = json
    const waypoints = routes.map((route) => activeRouteId === route.id ? ({...route, active: true}) : ({...route, active: false}))
    yield put(actions.getWaypointsSuccessed(waypoints))
  } catch (e) {
    yield put(actions.getWaypointsError(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* createWaypoints() {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Fetching waypoints')), 2000)
  const { waypointsStore: { waypoints, waypointsList } } = yield select()
  try {
    const response = yield call(post, '/routes', { name: `Waypoints ${waypointsList.length + 1}`, points: waypoints.filter(({ hide = false }) => !hide) })
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(
        json.message.includes('Unauthorized') ? 'Unauthorized' : json.message,
      )
    }
    const { data: { routes } } = json
    yield put(actions.createWaypointsSuccessed([
      ...waypointsList.map(route => ({...route, active: false})),
      {...routes, active: true}
    ], routes.id))
  } catch (e) {
    yield put(actions.createWaypointsError(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* viewWaypointData({ payload: { longitude, latitude, index, indexOfActiveTimestamp } }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Get waypoint data')), 2000)
  try {
    const {
      waypointsStore: { waypoints },
      layersStore: { layersList, timestamps },
    } = yield select()
    const layersNames = layersList.reduce((namesList, { name, active, type }) => {
      if (type === layerTypes.fish) {
        return active ? [...namesList, name] : [...namesList]
      }
      return [...namesList, name]
    }, [])
    const timestamp = timestamps[indexOfActiveTimestamp]

    const response = yield call(get, '/layers-alt/point', { layers: layersNames, longitude, latitude, timestamp })
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(
        json.message.includes('Unauthorized') ? 'Unauthorized' : json.message,
      )
    }
    const updatedWaypoints = waypoints.filter(({ hide = false }) => !hide).map((waypoint, i) => i === index ? {...json} : {...waypoint})
    yield put(actions.viewWaypointDataSuccessed(updatedWaypoints))
    yield put(depthActions.getDepthLayerData(indexOfActiveTimestamp))
  } catch (e) {
    yield put(actions.viewWaypointDataError(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* viewAllWaypointsData({ payload: indexOfActiveTimestamp }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Get waypoints data')), 2000)
  try {
    const {
      waypointsStore: { waypoints },
      layersStore: { layersList, timestamps },
    } = yield select()
    const layersNames = layersList.reduce((namesList, { name, active, type }) => {
      if (type === layerTypes.fish) {
        return active ? [...namesList, name] : [...namesList]
      }
      return [...namesList, name]
    }, [])
    const timestamp = timestamps[indexOfActiveTimestamp]
    const response = yield all(waypoints.filter(({ hide = false }) => !hide).map(({ latitude, longitude }) => call(get, '/layers-alt/point', { layers: layersNames, longitude, latitude, timestamp })))
    const errorIndex = response.findIndex(res => statusHelper(res))
    const json = yield all(response.map(res => apply(res, res.json)))
    if (errorIndex >= 0) {
      throw new Error(
        json[errorIndex].message.includes('Unauthorized') ? 'Unauthorized' : json[errorIndex].message,
      )
    }
    yield put(actions.viewAllWaypointsDataSuccessed(json))
    yield put(depthActions.getDepthLayerData(indexOfActiveTimestamp))
  } catch (e) {
    yield put(actions.viewAllWaypointsDataError(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export function* deleteWaypoints({ payload: routeId }) {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Deleting waypoints')), 2000)
  const { waypointsStore: { waypointsList } } = yield select()
  try {
    const response = yield call(deleteWithToken, `/routes/${routeId}`)
    const hasError = statusHelper(response)
    if (hasError) {
      const json = yield apply(response, response.json)
      throw new Error(
        json.message.includes('Unauthorized') ? 'Unauthorized' : json.message,
      )
    }
    const routes = waypointsList.filter(({id}) => id !== routeId)
    yield put(actions.deleteWaypointsSuccessed(routes))
  } catch (e) {
    yield put(actions.deleteWaypointsError(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export default [
  takeLatest(types.GET_WAYPOINTS, getWaypoints),
  takeLatest(types.CREATE_WAYPOINTS, createWaypoints),
  takeLatest(types.DELETE_WAYPOINTS, deleteWaypoints),
  takeLatest(types.VIEW_WAYPOINT_DATA, viewWaypointData),
  takeLatest(types.VIEW_ALL_WAYPOINTS_DATA, viewAllWaypointsData),
]
