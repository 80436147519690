import { apply, call, put, takeLatest } from 'redux-saga/effects'
import statusHelper from '../../helpers/statusHelper'
import { get } from '../../helpers/fetch'
import { serverToClientLayersData, getTimestampsList, getTypesList } from '../../helpers/layersUtils'

import * as types from '../types/layers.types'
import * as actions from '../actions/layers.actions'
import * as loaderActions from '../actions/loader.actions'

export function* getLayersList() {
  const timeout = setTimeout(() => put(loaderActions.showLoader('Fetching layers')), 2000)
  try {
    const response = yield call(get, '/layers-alt')
    const hasError = statusHelper(response)
    const json = yield apply(response, response.json)
    if (hasError) {
      throw new Error(
        json.message.includes('Unauthorized') ? 'Unauthorized' : json.message,
      )
    }
    const layers = yield call(serverToClientLayersData, json.data)
    const timestamps = yield call(getTimestampsList, json.data)
    const types = yield call(getTypesList, json.data)

    yield put(actions.getLayersListSucceessed(layers, timestamps, types))
  } catch (e) {
    yield put(actions.getLayersListFailed(e.message))
  } finally {
    yield put(loaderActions.hideLoader())
    clearTimeout(timeout)
  }
}

export default [
  takeLatest(types.GET_LAYERS_LIST, getLayersList),
]
