/**
 * Formatting timestamp data to design view
 * @param date {string} (timestamp from server)
 * @return {string}
 */
export function formattingDate(date) {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }
  if (!date) {
    return ''
  }
  const fixedDate = date.replace(/-/g, '/')
  return new Date(fixedDate).toLocaleString('en-US', dateOptions)
    .replace(/^([^,]*),([^,]*),(.*)$/, '$1,$2$3')
}
