import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import * as authTypes from '../types/auth.types'
import * as userTypes from '../types/user.types'

export const INITIAL_STATE = Immutable({
  user: null,
  loading: false,
  isUpdating: false,
  isError: null,
  errorText: '',
})

export const loginSuccess = (state, {payload: {id}}) => state.merge({ user: { id } }, { deep: true })

export const logoutSuccess = () => INITIAL_STATE

export const confirmedUserSignUp = (state, {payload}) =>
  state.merge({ loading: false, isError: false, user: payload }, { deep: true })

export const getUserInfoSuccess = (state, {payload}) =>
  state.merge({ user: payload, isError: false, errorText: '' }, { deep: true })

export const getUserInfoFailure = (state, {payload}) =>
  state.merge({ errorText: payload, isError: true }, { deep: true })

export const profileChangeRequest = state =>
  state.merge({ isUpdating: true, isError: false  }, { deep: true })

export const profileChangeSuccees = (state, {payload}) =>
  state.merge({ isUpdating: false, user: payload, isError: false, errorText: '' }, { deep: true })

export const profileChangeFailure = (state, {payload}) =>
  state.merge({ isUpdating: false, errorText: payload, isError: true }, { deep: true })

export const HANDLERS = {
  [authTypes.LOGIN_USER_SUCCEEDED]: loginSuccess,
  [authTypes.CONFIRM_USER_REGISTRATION_SUCCEEDED]: confirmedUserSignUp,
  [authTypes.LOGOUT_USER_SUCCEEDED]: logoutSuccess,
  [userTypes.GET_USER_INFO_SUCCEEDED]: getUserInfoSuccess,
  [userTypes.GET_USER_INFO_FAILED]: getUserInfoFailure,
  [userTypes.PROFILE_INFO_CHANGE_REQUESTED]: profileChangeRequest,
  [userTypes.PROFILE_INFO_CHANGE_SUCCEEDED]: profileChangeSuccees,
  [userTypes.PROFILE_INFO_CHANGE_FAILED]: profileChangeFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
