import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formattingDate } from '../../../helpers/timelineUtils'
import { mapIcons } from '../../../constants/images'

class TimelineSliderControl extends Component {
  static propTypes = {
    currentTimestamp: PropTypes.string,
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
  }

  render() {
    const { onPrevClick, onNextClick, currentTimestamp } = this.props
    return (
      <div className="timeline-slider-control">
        <button className="timeline-slider-control__btn" onClick={onPrevClick}>
          <img src={mapIcons.timeline.prev} />
        </button>
        <div className="timeline-slider-control__current-timestamp">
          {formattingDate(currentTimestamp)}
        </div>
        <button className="timeline-slider-control__btn" onClick={onNextClick}>
          <img src={mapIcons.timeline.next} />
        </button>
      </div>
    )
  }
}

export default TimelineSliderControl
