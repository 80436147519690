export const depths = ['0', '5', '10', '15', '20', '30', '40', '50', '75', '100', '125', '150', '200', '250', '300', '400', '500', '600', '800', '1000', '1200', '1500', '2000']

const step = 0.1
export const directions = [
  {
    label: 'North',
    latDiff: step,
    lonDiff: 0,
  },
  {
    label: 'North East',
    latDiff: step,
    lonDiff: step,
  },
  {
    label: 'East',
    latDiff: 0,
    lonDiff: step,
  },
  {
    label: 'South East',
    latDiff: -step,
    lonDiff: step,
  },
  {
    label: 'South',
    latDiff: -step,
    lonDiff: 0,
  },
  {
    label: 'South West',
    latDiff: -step,
    lonDiff: -step,
  },
  {
    label: 'West',
    latDiff: 0,
    lonDiff: -step,
  },
  {
    label: 'North West',
    latDiff: step,
    lonDiff: -step,
  }
]
