import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './config/StoreConfig'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import Provider from 'react-redux/es/components/Provider'
import { PersistGate } from 'redux-persist/es/integration/react'
import LayersProvider from './config/LayersProvider'
import './index.scss'

import App from './containers/App'
import AppErrorBoundary from './components/AppErrorBoundary/AppErrorBoundary'

const { persistor, store } = createStore()

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <AppErrorBoundary>
        <LayersProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LayersProvider>
      </AppErrorBoundary>
    </PersistGate>
  </Provider>
)
export const Store = store
ReactDOM.render(<Root />, document.getElementById('root'))
serviceWorker.unregister()

window.requestAnimationFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 20)
    }
})()
