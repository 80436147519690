import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { mapIcons } from '../../../constants/images'

class TimelineControl extends Component {
  static propTypes = {
    onPlayClick: PropTypes.func.isRequired,
    onPauseClick: PropTypes.func.isRequired,
    onLoopClick: PropTypes.func.isRequired,
    activeLoop: PropTypes.bool.isRequired,
    activePlay: PropTypes.bool.isRequired,
  }

  render() {
    const { onPlayClick, onPauseClick, onLoopClick, activePlay, activeLoop } = this.props
    return (
      <div className="timeline-control">
        <button
          onClick={onPlayClick}
          className={`timeline-control-btn timeline-control__play-btn ${activePlay && 'timeline-control-btn--active'}`}
        >
          <img src={mapIcons.timeline.play} />
        </button>
        <button
          onClick={onPauseClick}
          className={`timeline-control-btn timeline-control__pause-btn ${!activePlay && 'timeline-control-btn--active'}`}
        >
          <img src={mapIcons.timeline.pause} />
        </button>
        <button
          onClick={onLoopClick}
          className={`timeline-control-btn timeline-control__loop-btn ${activeLoop && 'timeline-control-btn--active'}`}
        >
          <img src={mapIcons.timeline.loop} />
        </button>
      </div>
    )
  }
}

export default TimelineControl
