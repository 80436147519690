import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../Common/TextInput/TextInput'
import ErrorMessage from '../Common/Errors/ErrorMessage/ErrorMessage'

const FormInput = ({
  label,
  value,
  handleChange,
  handleBlur,
  type,
  name,
  placeholder = '',
  errorText = '',
  isTouched = false,
}) => {
  return (
    <Fragment>
      <label htmlFor={name}>{label}</label>
      <TextInput
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        classes={errorText && isTouched ? 'error' : 'text-input'}
      />
      {errorText && isTouched && <ErrorMessage message={errorText} />}
    </Fragment>
  )
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  isTouched: PropTypes.bool,
}

export default FormInput
