import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import * as supportActions from '../../store/actions/support.actions'

import SubMenuHeader from '../../components/Common/SubMenuHeader/SubMenuHeader'
import Button from '../../components/Common/Button/Button'
import FormInput from '../../components/Forms/FormInput'
import TextArea from '../../components/Common/TextArea/TextArea'

const validate = Yup.object().shape({
  email: Yup.string().email().trim().lowercase().required('Email required'),
  message: Yup.string().trim().required('Message required'),
})

class Support extends Component {
  static propTypes = {
    user: PropTypes.object,
    authorized: PropTypes.bool.isRequired,
    onSendMessage: PropTypes.func.isRequired,
  }

  render() {
    const { authorized, onSendMessage, user } = this.props
    const initEmail = user ? { email: user.email } : { email: '' }

    return (
      <Formik
        initialValues={{ message: '', ...initEmail }}
        validationSchema={validate}
        onSubmit={values => onSendMessage(values)}
      >
        {props => {
          const {
            values: { email, message },
            touched,
            errors,
            handleChange,
            handleBlur,
          } = props
          return (
            <Form style={{ height: '100%' }}>
              <div className="support-screen">
                <div className="support-screen-content">
                  <Link className="close" to={authorized ? '/' : '/demo'} />
                  <SubMenuHeader>Support</SubMenuHeader>
                  {this.renderInputs(email, message, handleChange, handleBlur, errors, touched)}
                  <Button type="submit" color="btn-sole">
                    Send
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }

  renderInputs = (email, message, handleChange, handleBlur, errors, touched) => {
    return (
      <div className="support-screen-inputs">
        <FormInput
          label="Email Address"
          type="text"
          name="email"
          placeholder="user@email.com"
          value={email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errorText={errors.email}
          isTouched={touched.email}
        />
        <TextArea
          label="Message"
          name="message"
          placeholder="Type your message…"
          value={message}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errorText={errors.message}
          isTouched={touched.message}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ authStore: { authorized }, userStore: { user } }) => ({ authorized, user })

const mapDispatchToProps = dispatch => ({
  onSendMessage: form => dispatch(supportActions.sendSupportMessageRequested(form)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Support))
