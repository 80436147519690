import { mapIcons } from './images'

/**
 * disabled - is mock parameter. Should be deleted after integrating with Api
 * @type {*[]}
 */

export const data = [
  { name: 'Fish Finder', icon: mapIcons.applications.fish },
  { name: 'Water Quality', icon: mapIcons.applications.waterQuality },
  { name: 'Surface Temperature', icon: mapIcons.applications.surfaceTemp },
  { name: 'Bottom Temperature', icon: mapIcons.applications.bottomTemp },
  { name: 'Current', icon: mapIcons.applications.current },
  { name: 'Eddy Tracker', icon: mapIcons.applications.eddyTracker },
  { name: 'Wave Height', icon: mapIcons.applications.waveHeight },
  { name: 'Surface Wind', icon: mapIcons.applications.surfaceWind },
  { name: 'Rain', icon: mapIcons.applications.rain },
  { name: 'Surface Pressure', icon: mapIcons.applications.surfacePreassure },
  { name: 'Air Temperature', icon: mapIcons.applications.airTemp },
  { name: 'Wave Direction', icon: mapIcons.applications.current, disabled: true },
  { name: 'Water Temperature', icon: mapIcons.applications.waterTemp, disabled: true },
  { name: 'Sea Surface Height', icon: mapIcons.applications.seaSurface, disabled: true },
  { name: 'Water Salinity', icon: mapIcons.applications.waterSalinity, disabled: true },
]