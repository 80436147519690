/**
 * Add, delete, update, switches waypoint
 */
export const ADD_WAYPOINT = 'add waypoint to map'
export const ADD_HIDED_WAYPOINT = 'add hided waypoint around real to map'
export const UPDATE_WAYPOINT_BY_INDEX = 'update waypoint into map by index of array'
export const DELETE_WAYPOINT_BY_INDEX = 'remove waypoint from map by index of array'
export const SWITCHES_WAYPOINTS = 'switches waypoint by id'
export const RESET_WAYPOINTS = 'reset waypoints on the map'

/**
 * Create waypoints
 */
export const CREATE_WAYPOINTS = 'create waypoints'
export const CREATE_WAYPOINTS_SUCCESSED = 'create waypoints successed'
export const CREATE_WAYPOINTS_ERROR = 'create waypoints error'

/**
 * View waypoint data
 */
export const VIEW_WAYPOINT_DATA = 'view waypoint data'
export const VIEW_WAYPOINT_DATA_SUCCESSED = 'view waypoint data successed'
export const VIEW_WAYPOINT_DATA_ERROR = 'view waypoint data error'

/**
 * View all waypoints data
 */
export const VIEW_ALL_WAYPOINTS_DATA = 'view all waypoints data'
export const VIEW_ALL_WAYPOINTS_DATA_SUCCESSED = 'view all waypoints data successed'
export const VIEW_ALL_WAYPOINTS_DATA_ERROR = 'view all waypoints data error'

/**
 * Get waypoints list
 */
export const GET_WAYPOINTS = 'get waypoints list'
export const GET_WAYPOINTS_SUCCESSED = 'get waypoints list successed'
export const GET_WAYPOINTS_ERROR = 'get waypoints list error'

/**
 * Delete waypoints
 */
export const DELETE_WAYPOINTS = 'delete waypoints list'
export const DELETE_WAYPOINTS_SUCCESSED = 'delete waypoints list successed'
export const DELETE_WAYPOINTS_ERROR = 'delete waypoints list error'
