import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const subMenu = Component => {
  class SubMenu extends React.Component {
    render() {
      return (
        <ReactCSSTransitionGroup
          className="sub-menu"
          transitionName="fade"
          transitionAppear={true}
          transitionAppearTimeout={250}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div className="sub-menu__container">
            <Component {...this.props} />
          </div>
        </ReactCSSTransitionGroup>
      )
    }
  }
  SubMenu.displayName = `SubMenu(${Component.displayName || Component.name || 'Component'})`
  return withRouter(SubMenu)
}

export default subMenu
