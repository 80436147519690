import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { uniqueId } from '../../helpers/lib'
import { mapIcons } from '../../constants/images'
import { data } from '../../constants/appData'
import { applicationsOptions } from '../../constants/options'

import subMenu from '../../higher-order-components/SubMenu/SubMenu'
import Button from '../../components/Common/Button/Button'
import Checkbox from '../../components/Common/CheckBox/CheckBox'
import FormSelect from '../../components/Forms/FormSelect/FormSelect'
import ScrollBar from '../../components/Common/ScrollBar/ScrollBar'

class Applications extends Component {
  uniqueElem = uniqueId()
  mockFocus = [
    {
      text: 'Commercial Fisherman',
      status: 'open'
    },
    {
      text: 'Oil & Gas',
      status: 'closed'
    },
    {
      text: 'Oceanographer',
      status: 'closed'
    },
    {
      text: 'Enthusiast',
      status: ''
    },
    {
      text: 'Oceanographer',
      status: 'closed'
    },
    {
      text: 'Enthusiast',
      status: ''
    },
  ]

  state = { check: false }
  static propTypes = {
    user: PropTypes.object,
    authorized: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div className="applications-menu">
        <h3 className="applications-menu-header">Applications</h3>
        <div className="applications-menu-body">
          <div className="applications-menu-focus-wrapper">
            <ScrollBar>{this.renderFocus()}</ScrollBar>
            <Button size="btn-s" color="btn-sapphire" classes="m-t-20-5">+ Save New Focus</Button>
          </div>
          <div className="applications-menu-data-wrapper">
            <h3 className="applications-menu-header" style={{paddingBottom: 13.5, marginBottom: 15}}>Data</h3>
            {this.renderData()}
            <FormSelect
              label="Basemap"
              name="basemap"
              type="dark"
              options={applicationsOptions}
              value={applicationsOptions[0].label}
            />
          </div>
        </div>
      </div>
    )
  }

  renderFocus = () => (
    <ul className="applications-focus">
      {this.mockFocus.map(({text, status}, index) => {
        const key = this.uniqueElem + index
        switch (status) {
          case 'open':
            return (
              <li className="applications-focus-item" key={key}>
                {text}
                <img className="applications-focus-img-check" src={mapIcons.applications.check} alt="#"/>
              </li>
            )
          case 'closed':
            return (
              <li className="applications-focus-item" key={key}>
                {text}
                <img className="applications-focus-img-lock" src={mapIcons.applications.lock} alt="#"/>
              </li>
            )
          default: return <li className="applications-focus-item" key={key}>{text}</li>
        }
      })}
    </ul>
  )

  renderData = () => {
    const { check } = this.state
    return (
      <div className="applications-data">
        <ScrollBar>
          {
            data.map(({name, icon, disabled}, index) => {
              const key = this.uniqueElem + index
              if(disabled) {
                return (
                  <Checkbox key={key} checked={!disabled} onChange={() => null} disabled={disabled}>
                    <img className="checkbox-img" src={icon} alt="key"/>
                    <span className="checkbox-span">{name}</span>
                  </Checkbox>
                )
              }
              return (
                <Checkbox key={key} checked={check} onChange={() => this.setState({check: !check})}>
                  <img className="checkbox-img" src={icon} alt="key"/>
                  <span className="checkbox-span">{name}</span>
                </Checkbox>
              )
            })
          }
        </ScrollBar>
      </div>
    )
  }
}

const mapStateToProps = ({ authStore: { authorized }, userStore: { user } }) => ({ authorized, user })

export default withRouter(connect(mapStateToProps)(subMenu(Applications)))
