import {
  GET_LAYERS_LIST,
  GET_LAYERS_LIST_SUCCEEDED,
  GET_LAYERS_LIST_FAILED,
  SHOW_LAYER_LOADING,
  HIDE_LAYER_LOADING,
  SWITCHES_LAYER_ACTIVE,
  SWITCHES_LAYER_INACTIVE,
} from '../types/layers.types'

export const getLayersList = () => ({ type: GET_LAYERS_LIST })
export const getLayersListSucceessed = (layers, timestamps, types) => ({ type: GET_LAYERS_LIST_SUCCEEDED, payload: { layers, timestamps, types } })
export const getLayersListFailed = error => ({ type: GET_LAYERS_LIST_FAILED, payload: error })

export const showLayerLoading = layerName => ({ type: SHOW_LAYER_LOADING, payload: layerName })
export const hideLayerLoading = layerName => ({ type: HIDE_LAYER_LOADING, payload: layerName })

export const switchesLayerActive = layerName => ({ type: SWITCHES_LAYER_ACTIVE, payload: layerName })
export const switchesLayerInActive = layerName => ({ type: SWITCHES_LAYER_INACTIVE, payload: layerName })
