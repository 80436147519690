import { call } from 'redux-saga/effects'
import { toast } from 'react-toastify'

export function* errorToast({errors = {}}) {
  if(errors) {
    for(let errKey in errors) {
      for(let text of errors[errKey]) {
        yield call(toast.error, text, {position: toast.POSITION.TOP_CENTER})
      }
    }
  }
}

export function* infoToast(text) {
  yield call(toast.info, text, {position: toast.POSITION.TOP_CENTER})
}

export function* successToast(text) {
  yield call(toast.success, text, {position: toast.POSITION.TOP_CENTER})
}