import {
  GET_DEPTH_LAYER_DATA,
  GET_DEPTH_LAYER_DATA_SUCCEES,
  GET_DEPTH_LAYER_DATA_FAILED,
  UPDATE_DIRECTIONS,
} from '../types/depth.types'

export const getDepthLayerData = (indexOfActiveTimestamp) => ({ type: GET_DEPTH_LAYER_DATA, payload: { indexOfActiveTimestamp } })
export const getDepthLayerDataSuccess = (data) => ({ type: GET_DEPTH_LAYER_DATA_SUCCEES, payload: data })
export const getDepthLayerDataError = (error) => ({ type: GET_DEPTH_LAYER_DATA_FAILED, payload: error })

export const updateDirections = (left, right) => ({ type: UPDATE_DIRECTIONS, payload: { left, right } })
