import { getCurrentSetting } from './settings'

/**
 * See this: https://en.wikipedia.org/wiki/Haversine_formula
 *
 * @param lat1 - latitude of first layer
 * @param lon1 - longitude of first layer
 * @param lat2 - latitude of second layer
 * @param lon2 - longitude of second layer
 * @return {number} - value in meters
 */
export function convertMeasure(lat1, lon1, lat2, lon2){
  const R = 6378.137 // Radius of earth in KM
  const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
  const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon/2) * Math.sin(dLon/2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  const d = R * c
  return d * 1000
}

/**
 * Data formating for more readable
 * @param data {string | number}
 * @param decimals {number}
 * @param user {object}
 * @return {string}
 */
export function formatting(data, decimals = 2, user = null) {
  if (!data) { return '' }
  const value = parseFloat(data).toFixed(decimals).replace(/0+$/, '').replace(/\.$/, '')
  if (user) {
    const setting = getCurrentSetting('delimiter', user)
    return setting.convert(value)
  }
  return value
}
