import React, { Component } from 'react'
import { directions } from '../../constants/depths'
import PropTypes from 'prop-types'

class DepthViewRotation extends Component {
  state = { left: 0, right: 4  }
  static propTypes = {
    onDirectionChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { left, right } = this.state
    this.props.onDirectionChange({
      left: {...directions[left]},
      right: {...directions[right]}
    })
  }

  onDirectionClick(step) {
    let left = this.state.left
    if (left === directions.length - 1 && step > 0) {
      left = 0
    } else if (left === 0 && step < 0) {
      left = directions.length + step
    } else {
      left = left + step
    }

    let right = directions.length / 2 + left
    if (right >= directions.length) {
      right = right - directions.length
    }

    this.setState({ left, right })
    this.props.onDirectionChange({
      left: {...directions[left]},
      right: {...directions[right]}
    })
  }

  render() {
    const { left, right } = this.state
    return (
      <div className="depth-view__rotation">
        <button
          onClick={() => this.onDirectionClick(1)}
          className="depth-view__rotate-btn depth-view__rotate-btn--left"
        >{directions[left] ? directions[left].label : null}</button>
        <button
          onClick={() => this.onDirectionClick(-1)}
          className="depth-view__rotate-btn depth-view__rotate-btn--right"
        >{directions[right] ? directions[right].label : null}</button>
      </div>
    )
  }
}

export default DepthViewRotation
