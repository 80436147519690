import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LayersListItem from '../LayersList/LayersListItem/LayersListItem'

class DepthViewLayersList extends Component {
  static propTypes = {
    layersList: PropTypes.array.isRequired,

    switchesLayerInActive: PropTypes.func.isRequired,
    showLayerLoading: PropTypes.func.isRequired,
    getDepthLayerData: PropTypes.func.isRequired,
    indexOfActiveTimestamp: PropTypes.number.isRequired
  }

  componentDidMount() {
    const { layersList } = this.props
    const activeLayer = layersList.find(({ active }) => active)
    if (!activeLayer && layersList && layersList.length) {
      this.showLayer(layersList[0].name)
    } else if (activeLayer && activeLayer.length) {
      this.showLayer(activeLayer.name)
    }
  }

  showLayer = (name) => {
    const { getDepthLayerData, showLayerLoading, layersList, switchesLayerInActive, indexOfActiveTimestamp } = this.props
    const currentLayer = layersList.find(({ name: layerName }) => name === layerName)

    if (currentLayer) {
      layersList.forEach(({ type, depth, name: layerName }) => {
        if (type === currentLayer.type && layerName !== name && depth) {
          switchesLayerInActive(layerName)
        }
      })
    }

    showLayerLoading(name)
    getDepthLayerData(indexOfActiveTimestamp)
  }

  render() {
    const { layersList, switchesLayerInActive } = this.props
    return (
      <div className="depth-view__layers-list">
        {layersList && layersList.length ? layersList.map(layer => (
          <LayersListItem
            key={`${layer.name}-depth`}
            hideLayer={switchesLayerInActive}
            showLayer={this.showLayer}
            {...layer}
          />
        )) : null}
      </div>
    )
  }
}

export default DepthViewLayersList
