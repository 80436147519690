import {
  SEND_SUPPORT_MESSAGE_REQUESTED,
  SEND_SUPPORT_MESSAGE_SUCCEEDED,
  SEND_SUPPORT_MESSAGE_FAILED,
} from '../types/support.types'

export const sendSupportMessageRequested = form => ({ type: SEND_SUPPORT_MESSAGE_REQUESTED, payload: form })
export const sendSupportMessageSucceeded = data => ({ type: SEND_SUPPORT_MESSAGE_SUCCEEDED, payload: data })
export const sendSupportMessageFailed = error => ({ type: SEND_SUPPORT_MESSAGE_FAILED, payload: error })

