import { DomUtil } from 'leaflet'
import PropTypes from 'prop-types'
import { MapLayer, withLeaflet } from 'react-leaflet'
import { safelyRemoveLayer, createCanvsaLayer, animateZoom, getLayerData } from '../../helpers/layersUtils'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'

class FishMapOverlay extends MapLayer {
  /* Data for drawing */
  _data = []
  _fishIcon
  _zoom = DEFAULT_VIEWPORT.zoom

  /* Data for set up drawing */
  _radius = 0
  _w = 0
  _h = 0

  /* Drawing area restrictions */
  _maxValue = 6
  _nx = 401
  _ny = 402

  static propTypes = {
    fishMapLayer: PropTypes.object.isRequired,
    layerName: PropTypes.string.isRequired,
    indexOfActiveTimestamp: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  }

  componentDidMount() {
    this.initComponent()
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    const { layerName, indexOfActiveTimestamp } = this.props
    if (prevProps.layerName !== layerName) {
      this.initComponent()
    } else if (prevProps.indexOfActiveTimestamp !== indexOfActiveTimestamp) {
      this.initialize()
      this.reset()
    }
  }

  componentWillUnmount() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    safelyRemoveLayer(leafletMap, this._el)
  }

  initComponent() {
    if (!this.leafletElement || !this._el) {
      const { el, _CanvasLayer } = createCanvsaLayer(this, 'fish-layer')
      this._el = el
      this.leafletElement = new _CanvasLayer()
    }
    const { min, max } = this.props

    this._maxValue = max - min

    this.initialize()
    this.attachEvents()

    this.reset()
  }

  /**
   * Initialize component data and restrictions
   */
  initialize() {
    const { fishMapLayer } = this.props
    this.layer = getLayerData(this._zoom, fishMapLayer)
    if (!this.layer) {
      return
    }
    const { bounds, data, zoom, nx, ny } = this.layer

    if (!data || !data.length || !bounds) {
      return
    }
    const {
      leaflet: { map: leafletMap }
    } = this.props
    this.layerData = data
    const pointerDensity = zoom / 10

    const { x: x1, y: y1 } = leafletMap.latLngToContainerPoint([bounds[1][0], bounds[1][1]])
    const { x: x2, y: y2 } = leafletMap.latLngToContainerPoint([bounds[1][0] - pointerDensity, bounds[1][1] + pointerDensity])

    this._w = Math.abs(x1 - x2) / 2
    this._h = Math.abs(y1 - y2) * 1.125 / 2
    this._ny = ny
    this._nx = nx
  }

  /**
   * Adds events for calling reDraw method
   */
  attachEvents() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.on('moveend', () => this.reset())
    leafletMap.on('zoomanim', (event) => animateZoom(event, leafletMap, this._el), this)
  }

  /**
   * Resets canvas data and starts reDraw function for update layer view
   */
  reset() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    const zoom = leafletMap.getZoom()
    if (this._zoom !== zoom) {
      this._zoom = zoom
      this.initialize()
    }

    const topLeft = leafletMap.containerPointToLayerPoint([0, 0])
    DomUtil.setPosition(this._el, topLeft)

    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    this._el.width = mapWidth
    this._el.height = mapHeight

    if (!this.layer || !this.layerData) {
      return
    }

    this.draw()
  }

  /**
   * Draws a fish map from prepared data
   */
  draw() {
    const {
      leaflet: { map: leafletMap }
    } = this.props
    const bounds = leafletMap.getBounds()
    const context = this._el.getContext('2d')

    context.clearRect(0, 0, this._el.width, this._el.height)
    this._createFishIcon()

    this.layerData.forEach(({ lat, lng, value }) => {
      if (bounds.contains([lat, lng])) {
        const { x, y } = leafletMap.latLngToContainerPoint([lat, lng])
        const opacity = Math.min(Math.max(Math.abs(value / this._maxValue), 0.1), 1)

        this.drawPoint(context, { x, y, opacity })
      }
    })
  }

  drawPoint(context, point) {
    context.globalAlpha = point.opacity
    context.drawImage(this._fishIcon, point.x - this._w, point.y - this._h)
  }

  /**
   * Draws a fish for point
   * From remote canvas
   * @private
   */
  _createFishIcon() {
    if (!this._fishIcon) {
      this._fishIcon = this._createCanvas()
    }
    const context = this._fishIcon.getContext('2d')

    this._fishIcon.width = this._w * 4
    this._fishIcon.height = this._h * 2
    context.clearRect(0, 0, this._fishIcon.width, this._fishIcon.height)

    const image = document.getElementById('fish-image')
    context.drawImage(image, this._w, 0, this._w * 2, this._h)
    context.drawImage(image, this._w * 2, this._h, this._w * 2, this._h)
  }
}

FishMapOverlay.prototype.shouldComponentUpdate = () => (true)
FishMapOverlay.prototype.createLeafletElement = () => (null)
FishMapOverlay.prototype.render = () => (null)
FishMapOverlay.prototype._createCanvas = () => document.createElement('canvas')
export default withLeaflet(FishMapOverlay)
