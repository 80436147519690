import JSZip from 'jszip'
import saveAs from 'file-saver'
import { getImage } from './fetch'

/**
 * DownLoading all snapshots by one Zip file
 * @param snapshots
 */
export const downloadAllSnapshots = (snapshots = []) => {
  const zip = new JSZip()
  const zipFilename = 'Snapshots.zip'
  let count = 0

  snapshots.length > 0 &&
    snapshots.forEach(({ image, name }) => {
      const fileExtension = image.split('.').pop()
      // loading a file and add it in a zip file
      getImage(image).then(blob => {
        const imgBase64 = blob.split('base64,')[1]
        zip.file(`${name}.${fileExtension}`, imgBase64, { base64: true })
        count++
        if (count === snapshots.length) {
          zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, zipFilename))
        }
      })
    })
}
