import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { LayersContext } from '../../constants/layersContext'
import { uniqueId } from '../../helpers/lib'
import * as layersActions from '../../store/actions/layers.actions'
import { types } from '../../constants/layers'
import LayersListItem from './LayersListItem/LayersListItem'
import PropTypes from 'prop-types'
import SelectFishLayer from './SelectFishLayer/SelectFishLayer'

class LayersList extends PureComponent {
  layersUniqueId = uniqueId()
  static contextType = LayersContext
  static propTypes = {
    authorized: PropTypes.bool,
    layersList: PropTypes.array,
    switchesLayerInActive: PropTypes.func.isRequired,
  }

  render() {
    const { layersList, switchesLayerInActive, authorized } = this.props
    const { showLayer } = this.context
    if (!layersList || !layersList.length) {
      return null
    }

    const fishLayersData = layersList.filter(({ type, depth }) => type === types.fish && !depth)
    const fishLayers = [
      { value: 'default', label: 'Select fish...', type: types.default },
      ...fishLayersData.map(({ name, title, ...data }) => ({ value: name, label: title, name, ...data}))
    ]

    return (
      <div className="layers-list">
        <h4 className="layers-list__title">Layers List</h4>
        {fishLayers && fishLayers.length && <SelectFishLayer
          fishLayers={fishLayers}
          switchesLayerInActive={switchesLayerInActive}
        />}
        {layersList && layersList.length && layersList
          .filter((layer) => (layer.public || authorized) && layer.type !== types.fish && !layer.depth)
          .map(layer => (
            <LayersListItem
              key={`${this.layersUniqueId}${layer.name}${layer.type}`}
              hideLayer={switchesLayerInActive}
              showLayer={showLayer}
              {...layer}
            />
          ))}
      </div>
    )
  }
}

const mapStateToProps = ({
  authStore: { authorized },
  layersStore: { layersList }
}) => ({ layersList, authorized })
const mapDispatchToProps = (dispatch) => ({
  switchesLayerInActive: (layerName) => dispatch(layersActions.switchesLayerInActive(layerName)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LayersList)
