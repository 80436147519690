import React from 'react'
import PropTypes from 'prop-types'
import FreeScrollBar from 'react-free-scrollbar'

const ScrollBar = ({children, autoHide = false}) => {
  return (
    <FreeScrollBar
      autohide={autoHide}
      className="scrollbar"
      style={{height: '100%', width: '100%'}}
    >
      {children}
    </FreeScrollBar>
  )
}

ScrollBar.propTypes = {
  children: PropTypes.node.isRequired,
  autoHide: PropTypes.bool
}

export default ScrollBar