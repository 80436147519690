import { Browser, DomUtil } from 'leaflet'
import React from 'react'
import { MapLayer, withLeaflet } from 'react-leaflet'
import { LayersContext } from '../../constants/layersContext'
import { uniqueId } from '../../helpers/lib'
import { createCanvsaLayer, safelyRemoveLayer } from '../../helpers/layersUtils'
import PropTypes from 'prop-types'
import WaypointMarker from '../WaypointMarker/WaypointMarker'

class WaypointsOverlay extends MapLayer {
  iconUniqueId = uniqueId()
  static contextType = LayersContext
  static propTypes = {
    waypoints: PropTypes.array.isRequired,
    defaultPosition: PropTypes.object.isRequired,
    updateWaypointByIndex: PropTypes.func.isRequired,
    viewWaypointData: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { el, _CanvasLayer } = createCanvsaLayer(this, 'waypoints-layer')
    this._el = el
    this.leafletElement = new _CanvasLayer()
    super.componentDidMount()
    this.attachEvents()
    this.reset()
  }

  componentWillUnmount() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    safelyRemoveLayer(leafletMap, this._el)
  }

  componentDidUpdate() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.invalidateSize()
    this.reset()
  }

  /**
   * Adds events for calling reDraw method
   */
  attachEvents() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    leafletMap.on('moveend', () => this.reset())
    leafletMap.on('zoomanim', this.animateZoom, this)
  }

  /**
   * Animate zoom if we can do it
   * @param event: LeafletZoomEvent
   */
  animateZoom(event) {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    if (!leafletMap.options.zoomAnimation || !Browser.any3d) {
      return
    }

    const scale = leafletMap.getZoomScale(event.zoom)
    const offset = leafletMap._getCenterOffset(event.center)._multiplyBy(-scale).subtract(leafletMap._getMapPanePos())

    if (DomUtil.setTransform) {
      DomUtil.setTransform(this._el, offset, scale)
    } else {
      this._el.style[DomUtil.TRANSFORM] = `${DomUtil.getTranslateString(offset)} scale(${scale})`
    }
  }

  reset() {
    const {
      leaflet: { map: leafletMap }
    } = this.props

    const topLeft = leafletMap.containerPointToLayerPoint([0, 0])
    DomUtil.setPosition(this._el, topLeft)

    const context = this._el.getContext('2d')
    context.clearRect(0, 0, this._el.width, this._el.height)

    const { x: mapWidth, y: mapHeight } = leafletMap.getSize()
    this._el.width = mapWidth
    this._el.height = mapHeight

    this.draw()
  }

  draw(event, index) {
    const latlng = event ? event.latlng : false
    const { waypoints, leaflet: { map: leafletMap } } = this.props
    if (!waypoints.length) {
      return
    }
    const context = this._el.getContext('2d')
    context.clearRect(0, 0, this._el.width, this._el.height)
    context.strokeStyle = 'rgb(255, 255, 255)'
    context.beginPath()

    if ((index || index === 0) && latlng && index === 0) {
      const { x, y } = leafletMap.latLngToContainerPoint(latlng)
      context.moveTo(x, y)
    } else {
      const { x, y } = leafletMap.latLngToContainerPoint([waypoints[0].latitude, waypoints[0].longitude])
      context.moveTo(x, y)
    }

    for (let i = 1; i < waypoints.length; i++) {
      if ((index || index === 0) && latlng && index === i) {
        const { x, y } = leafletMap.latLngToContainerPoint(latlng)
        context.lineTo(x, y)
      } else {
        const { x, y } = leafletMap.latLngToContainerPoint([waypoints[i].latitude, waypoints[i].longitude])
        context.lineTo(x, y)
      }
    }
    context.stroke()
  }

  onDragEnd(event, index) {
    const { updateWaypointByIndex, viewWaypointData } = this.props
    const { playTimeline, indexOfActiveTimestamp, onPauseClick } = this.context
    const { target: { _latlng: { lat, lng } } } = event
    if (playTimeline) { onPauseClick() }
    updateWaypointByIndex(lat, lng, index)
    viewWaypointData(lat, lng, index, indexOfActiveTimestamp)
  }

  render() {
    const { waypoints, defaultPosition } = this.props
    if (!waypoints.length) {
      return null
    }

    return waypoints.map(({ latitude, longitude }, index) =>  (
      <WaypointMarker
        key={`${this.iconUniqueId}${index}`}
        position={latitude && longitude ? [latitude, longitude] : defaultPosition}
        onDrag={event => this.draw(event, index)}
        onDragEnd={event => this.onDragEnd(event, index)}
        index={index}
      />
    ))
  }
}

WaypointsOverlay.prototype.shouldComponentUpdate = () => (true)
WaypointsOverlay.prototype.createLeafletElement = () => (null)
export default withLeaflet(WaypointsOverlay)
