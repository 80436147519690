import React from 'react'
import { Link } from 'react-router-dom'
import { mapIcons } from '../../constants/images'

const AuthLogo = () => (
  <div className="auth-logo">
    <img src={mapIcons.authLogo} alt="#" />
    <div className="auth-logo-about">
      <span>
        Creating an account allows you to customize the portal content, save your settings,
        and keep you alerted to updates and new features.
      </span>
      <Link className="btn btn-primary btn-xm" to="/demo">
        Try it now
      </Link>
    </div>
  </div>
)

export default AuthLogo
