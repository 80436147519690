import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({ message, classes = '' }) => {
  return (
    <div className={`input-feedback-wrapper ${classes}`}>
      <div className="input-feedback">{message}</div>
    </div>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  classes: PropTypes.string,
}

export default ErrorMessage
