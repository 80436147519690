import React, { Component } from 'react'
import { Map, TileLayer } from 'react-leaflet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WaypointsOverlay from '../../components/MapLayers/WaypointsOverlay'
import { LayersContext } from '../../constants/layersContext'
import { DEFAULT_VIEWPORT } from '../../constants/leaflet.settings'
import { types } from '../../constants/layers'
import HeatMapOverlay from '../../components/MapLayers/HeatMapOverlay'
import FishMapOverlay from '../../components/MapLayers/FishMapOverlay'
import VectorMapOverlay from '../../components/MapLayers/VectorMapOverlay'
import ContourMapOverlay from '../../components/MapLayers/ContourMapOverlay'
import * as waypointsActions from '../../store/actions/waypoints.actions'

class LeafletMap extends Component {
  static contextType = LayersContext
  static propTypes = {
    zoom: PropTypes.number.isRequired,
    layersList: PropTypes.array,
    authorized: PropTypes.bool,
    waypoints: PropTypes.array,
    addWaypoint: PropTypes.func.isRequired,
    updateWaypointByIndex: PropTypes.func.isRequired,
    viewWaypointData: PropTypes.func.isRequired,
    onZoomChange: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { ...DEFAULT_VIEWPORT, waypoints: [] }
  }

  onMapClick = (event) => {
    const { waypoints, addWaypoint, authorized, viewWaypointData } = this.props
    const { indexOfActiveTimestamp } = this.context
    if ((waypoints && waypoints.length < 5 && authorized) || (waypoints && waypoints.length === 0 && !authorized)) {
      const { latlng: { lat, lng } } = event
      addWaypoint(lat, lng)
      viewWaypointData(lat, lng, waypoints.length, indexOfActiveTimestamp)
    }
  }

  onViewportChange = ({ zoom }) => {
    const { playTimeline, onPauseClick } = this.context
    const { zoom: prevZoom, onZoomChange } = this.props

    if (playTimeline) { onPauseClick() }
    if (prevZoom !== zoom) { onZoomChange(zoom) }
  }

  getActiveMapLayer(targetType) {
    const { layersList } = this.props
    if (!layersList || !layersList.length) { return {} }
    const layer = layersList.find(({ name, active, type, depth }) => !depth && active && name && type === targetType)
    return layer ? layer : {}
  }

  render() {
    const {
      updateWaypointByIndex,
      viewWaypointData,
      waypoints,
      authorized,
      zoom,
      user
    } = this.props
    const { indexOfActiveTimestamp, layers } = this.context
    const { center } = this.state

    const {
      min: heatMapMin,
      max: heatMapMax,
      active: heatMapActive,
      name: heatMapName,
      gradient,
    } = this.getActiveMapLayer(types.gradient)
    const heatMapLayer = layers.find(({type}) => type === types.gradient)

    const {
      min: fishMapMin,
      max: fishMapMax,
      active: fishMapActive,
      name: fishMapName
    } = this.getActiveMapLayer(types.fish)
    const fishMapLayer = layers.find(({type}) => type === types.fish)

    const {
      min: contourMapMin,
      max: contourMapMax,
      active: contourMapActive,
      name: contourMapName
    } = this.getActiveMapLayer(types.contour)
    const contourMapLayer = layers.find(({type}) => type === types.contour)

    const { active: vectorMapActive, name: vectorMapName } = this.getActiveMapLayer(types.vector)
    const vectorMapLayer = layers.find(({type}) => type === types.vector)

    return (
      <Map
        zoom={zoom}
        zoomControl={false}
        onViewportChange={this.onViewportChange}
        onDblClick={this.onMapClick}
        {...this.state}
      >
        <TileLayer
          attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
        {waypoints && waypoints.length && <WaypointsOverlay
          waypoints={waypoints.filter(({ hide = false }) => !hide)}
          defaultPosition={center}
          authorized={authorized}
          updateWaypointByIndex={updateWaypointByIndex}
          viewWaypointData={viewWaypointData}
        />}
        {fishMapMin && fishMapMax && fishMapName && fishMapActive &&
        fishMapLayer && fishMapLayer.data && fishMapLayer.data.data.length && <FishMapOverlay
          min={parseFloat(fishMapMin)}
          max={parseFloat(fishMapMax)}
          layerName={fishMapName}
          fishMapLayer={fishMapLayer.data}
          indexOfActiveTimestamp={indexOfActiveTimestamp}
        />}
        {contourMapMin && contourMapMax && contourMapActive && contourMapName &&
        contourMapLayer && contourMapLayer.data && contourMapLayer.data.data.length && <ContourMapOverlay
          layerName={contourMapName}
          contourMapLayer={contourMapLayer.data}
          indexOfActiveTimestamp={indexOfActiveTimestamp}
          user={user}
        />}
        {vectorMapActive && vectorMapName &&
        vectorMapLayer && vectorMapLayer.data && vectorMapLayer.data.data &&
        vectorMapLayer.data.data.length && <VectorMapOverlay
          layerName={vectorMapName}
          vectorMapLayer={vectorMapLayer.data}
          indexOfActiveTimestamp={indexOfActiveTimestamp}
        />}
        {heatMapMin && heatMapMax && heatMapName && heatMapActive &&
        heatMapLayer && heatMapLayer.data && heatMapLayer.data.data.length && <HeatMapOverlay
          gradient={gradient}
          min={parseFloat(heatMapMin)}
          max={parseFloat(heatMapMax)}
          layerName={heatMapName}
          heatMapLayer={heatMapLayer.data}
          indexOfActiveTimestamp={indexOfActiveTimestamp}
        />}
      </Map>
    )
  }
}

const mapStateToProps = ({
  authStore: { authorized },
  layersStore: { layersList },
  waypointsStore: { waypoints },
  userStore: { user }
}) => ({ layersList, waypoints: waypoints.filter(({ hide = false }) => !hide), authorized, user })
const mapDispatchToProps = (dispatch) => ({
  addWaypoint: (latitude, longitude) => dispatch(waypointsActions.addWaypoint(latitude, longitude)),
  updateWaypointByIndex: (latitude, longitude, index, indexOfActiveTimestamp) => dispatch(waypointsActions.updateWaypointByIndex(latitude, longitude, index, indexOfActiveTimestamp)),
  viewWaypointData: (latitude, longitude, index, indexOfActiveTimestamp) => dispatch(waypointsActions.viewWaypointData(latitude, longitude, index, indexOfActiveTimestamp)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LeafletMap)
