import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { authorizedMenu, unauthorizedMenu } from '../../constants/menu'
import { mapIcons } from '../../constants/images'
import { uniqueId } from '../../helpers/lib'

import * as authActions from '../../store/actions/auth.actions'

import Avatar from './Avatar/Avatar'

class Sidebar extends PureComponent {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    authorized: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
  }

  render() {
    return (
      <div className="sidebar-wrapper">
        <div className="sidebar-menu-container">
          {this.renderMenu()}
          <div
            className="sidebar-bottom-logo"
            style={{background: `url(${mapIcons.sidebar.ocean_now_icon}) no-repeat`, backgroundSize: 'cover'}}
          />
        </div>
      </div>
    )
  }

  renderMenu = () => {
    const { location: { pathname }, authorized } = this.props
    const menu = authorized ? authorizedMenu : unauthorizedMenu
    return menu.map(item => this.renderMenuItem(item, pathname))
  }

  renderMenuItem = ({ icon, title, path }, pathname) => {
    const { authorized, user } = this.props
    const newPath = path.slice(1)
    const newPathName = pathname.slice(1)
    const isProfileRoute = title === 'Forgot'
    const checkCurrentPath = pathname === path || (newPathName.includes(newPath) && newPath !== '')

    let sidebarMenuIcon = <img alt="#" className="sidebar-menu-icon" src={mapIcons.sidebar[icon]} />
    if (icon && authorized && isProfileRoute) {
      sidebarMenuIcon = <Avatar user={user} icon={icon} />
    }

    let linkTo = newPath === newPathName || (newPathName.includes('/') && newPath === 'profile') ? '/' : path

    if(!authorized && newPath === newPathName) {
      linkTo = '/demo'
    }

    return (
      <Link
        to={linkTo}
        className={`sidebar-menu-item ${checkCurrentPath && 'active'}`}
        key={uniqueId()}
      >
        {sidebarMenuIcon}
        <span className="sidebar-menu-item--without-icon">{isProfileRoute && authorized ? user.name : title}</span>
      </Link>
    )
  }
}

const mapStateToProps = ({authStore: { authorized }, userStore: { user }}) => ({ authorized, user })

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(authActions.logoutUser()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
