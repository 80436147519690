import immutablePersistenceTransfrom from '../transforms/ImmutablePersistenceTransform'
import storage from 'redux-persist/es/storage'
import { persistStore } from 'redux-persist'

export const REDUX_PERSIST = {
  active: true,
  reducerVersion: 19,
  storeConfig: {
    key: 'root',
    storage,
    blacklist: ['appStore', 'layersStore', 'depthStore', 'waypointsStore'],
    transforms: [immutablePersistenceTransfrom],
  },
}

export const checkVersion = (store) => {
  let persistor
  const tenMin = new Date()
  const versionCheck = REDUX_PERSIST.reducerVersion === parseInt(localStorage.getItem('reducerVersion') || '', 10)
  tenMin.setTime(tenMin.getTime() + 600000 * (process.env.NODE_ENV !== 'production' ? 100 : 1))

  if (versionCheck) {
    persistor = persistStore(store)
  } else {
    persistor = persistStore(store)
    persistor.purge()
    localStorage.removeItem('token')
    localStorage.setItem('reducerVersion', REDUX_PERSIST.reducerVersion.toString())
  }

  localStorage.setItem('storeExpiration', tenMin.getTime().toString())
  return persistor
}
