import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import createSagaMiddleWare from 'redux-saga'
import { persistReducer } from 'redux-persist'
import { REDUX_PERSIST as PersistConfig, checkVersion } from './ReduxPersist'
import Config from './DebugConfig'
import rootSaga from '../store/sagas'
import reducers from '../store/reducers'

const configureStore = (rootReducer, rootSaga) => {
  /* ------------ Redux Configuration ------------ */
  const sagaMiddleware = createSagaMiddleWare()
  const middleware = [sagaMiddleware]
  const enhancers = []

  /* ------------ Saga Middleware ------------ */

  let composeEnhancers = compose
  if (Config.reduxLogging) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  enhancers.push(applyMiddleware(...middleware))

  /* ------------ Redux Perist @ Local Storage ------------ */

  const reducer = persistReducer(PersistConfig.storeConfig, rootReducer)

  /* ------------ Create Store and Persistor ------------ */

  const store = createStore(reducer, composeEnhancers(...enhancers))

  const persistor = checkVersion(store)

  // This is where we will run the ol' Sagas
  sagaMiddleware.run(rootSaga)

  return { persistor, store }
}

// It will be:
export default () => configureStore(combineReducers(reducers), rootSaga)
