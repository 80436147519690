import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUrlValue } from '../../helpers/lib'

import * as authActions from '../../store/actions/auth.actions'

import Login from '../../components/Login/Login'
import SignUp from '../../components/SignUp/SignUp'
import SuccessForm from '../../components/SuccessForm/SuccessForm'
import AuthLogo from '../../components/AuthLogo/AuthLogo'
import Forgot from '../../components/Forgot/Forgot'
import ResetPassword from '../../components/ResetPassword/ResetPassword'

class Authorization extends Component {
  static propTypes = {
    onLoginUser: PropTypes.func.isRequired,
    onClearError: PropTypes.func.isRequired,
    onSetLoginView: PropTypes.func.isRequired,
    onSignUpUser: PropTypes.func.isRequired,
    onSendRegisterToken: PropTypes.func.isRequired,
    onSetChangePasswordView: PropTypes.func.isRequired,
    onSendResetPasswordToken: PropTypes.func.isRequired,
    onSubmitForgot: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    errorText: PropTypes.string.isRequired,
    successText: PropTypes.string.isRequired,
    resetCredentials: PropTypes.object,
    isPasswordChanged: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isCheckEmail: PropTypes.bool,
    isEmailSent: PropTypes.bool,
    history: PropTypes.object,
    user: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.state = {
      isRegister: false,
      isForgot: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { isCheckEmail, isEmailSent } = this.props
    const { isRegister, isForgot } = this.state
    if (!prevProps.isCheckEmail && isCheckEmail && isRegister) {
      this.setState({ isRegister: false })
    }
    if (prevProps.isEmailSent && !isEmailSent && isForgot) {
      this.setState({ isForgot: false })
    }
  }

  componentDidMount() {
    const { errorText, onClearError, onSendRegisterToken, onSendResetPasswordToken, isEmailSent, successText, onSetChangePasswordView } = this.props
    const registerToken = getUrlValue('token=([^&]+)')
    const resetPasswordToken = getUrlValue('/api/auth/password/find/([^&]+)')

    if (errorText) onClearError()
    if (registerToken && registerToken.length > 0) onSendRegisterToken(registerToken)
    if (resetPasswordToken && resetPasswordToken.length > 0) onSendResetPasswordToken(resetPasswordToken)
    if (isEmailSent && successText.length > 0) onSetChangePasswordView()
  }

  render() {
    return (
      <Fragment>
        <div className="auth-splash" />
        <div className="auth">
          <AuthLogo />
          {this.renderAppropriateForm()}
        </div>
      </Fragment>
    )
  }

  renderAppropriateForm = () => {
    const { isCheckEmail, onSetLoginView, isEmailVerified, successText, isEmailSent, isPasswordChanged, resetCredentials } = this.props
    const { isRegister, isForgot } = this.state

    if (isRegister) {
      return this.renderSignUp()
    } else if (resetCredentials) {
      return this.renderPasswordChange()
    } else if (isCheckEmail || isEmailVerified || (isEmailSent && successText.length > 0) || isPasswordChanged) {
      return (
        <SuccessForm
          onLoginPage={() => onSetLoginView()}
          isPasswordChanged={isPasswordChanged}
          isEmailVerified={isEmailVerified}
          isEmailSent={isEmailSent}
          successText={successText}
        />
      )
    }  else if (isForgot) {
      return this.renderForgot()
    } else {
      return this.renderLogin()
    }
  }

  renderSignUp = () => {
    const { errorText, onSignUpUser, onClearError } = this.props
    return (
      <SignUp
        errorText={errorText}
        handlers={{
          onSignUpUser: credentials => onSignUpUser(credentials),
          onCancel: () => {
            this.setState({ isRegister: false })
            onClearError()
          },
        }}
      />
    )
  }

  renderLogin = () => {
    const { errorText, onLoginUser, onClearError } = this.props
    return (
      <Login
        errorText={errorText}
        handlers={{
          onLogin: credentials => onLoginUser(credentials),
          onRegister: () => {
            this.setState({ isRegister: true })
            onClearError()
          },
          onForgot: () => {
            this.setState({ isForgot: true })
            onClearError()
          },
        }}
      />
    )
  }

  renderForgot = () => {
    const { errorText, onSubmitForgot, onClearError } = this.props
    return (
      <Forgot
        errorText={errorText}
        handlers={{
          onSubmitForgot: credentials => onSubmitForgot(credentials),
          onCancel: () => {
            this.setState({ isForgot: false })
            onClearError()
          },
        }}
      />
    )
  }

  renderPasswordChange = () => {
    const { errorText, onClearError, onSetLoginView, onPasswordChange, resetCredentials } = this.props
    return (
      <ResetPassword
        errorText={errorText}
        resetCredentials={resetCredentials}
        handlers={{
          onPasswordChange: credentials => onPasswordChange(credentials),
          onCancel: () => {
            onSetLoginView()
            onClearError()
          },
        }}
      />
    )
  }
}

const mapStateToProps = ({ authStore, userStore }) => {
  const { user } = userStore
  const { errorText, successText, isCheckEmail, isEmailVerified, isEmailSent, resetCredentials, isPasswordChanged } = authStore
  return { user, errorText, successText, isCheckEmail, isEmailVerified, isEmailSent, resetCredentials, isPasswordChanged }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginUser: credentials => dispatch(authActions.loginUser(credentials)),
    onClearError: () => dispatch(authActions.clearError()),
    onSetLoginView: () => dispatch(authActions.setLoginView()),
    onSetChangePasswordView: () => dispatch(authActions.setChangePasswordView()),
    onSignUpUser: credentials => dispatch(authActions.signUpUser(credentials)),
    onSendRegisterToken: token => dispatch(authActions.confirmUserSignUp(token)),
    onSubmitForgot: credentials => dispatch(authActions.resetPassword(credentials)),
    onSendResetPasswordToken: token => dispatch(authActions.sendResetPasswordToken(token)),
    onPasswordChange: credentials => dispatch(authActions.passwordChangeAfterReset(credentials)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorization))
